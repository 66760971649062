import React from 'react';

class MarkerKey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
    };
  }

  handleValueToggle(e, selectedId) {
    const {
      statusIds,
      doToggleMulti,
    } = this.props;

    let newSelectedValues = statusIds;

    if (e.target.checked) {
      newSelectedValues = [...newSelectedValues, selectedId];
    } else {
      const position = newSelectedValues.indexOf(selectedId);
      if (position > -1) {
        newSelectedValues.splice(position, 1);
      }
    }

    doToggleMulti(newSelectedValues, 'statusIds');
  }

  render() {
    const {
      mode,
      statuses,
      statusIds,
      displayOpen,
      displayEquity,
      displayPriorityAreas,
      displayCompanyTerritory,
      driveThrough,
      driveThroughEnabled,
      canViewPriorityAreas,
      doToggle,
    } = this.props;

    const {
      isExpanded,
    } = this.state;

    return (

      <div className="key-wrapper">
        <div className="key-header">
          <div>
            <button
              type="button"
              className={isExpanded ? 'close-button' : 'open-button'}
              onClick={() => this.setState(
                { isExpanded: !isExpanded },
              )}
            >
              +
            </button>
          </div>
        </div>
        <div className={`key ${isExpanded ? '' : 'hideKey'}`}>
          <h2>
            Key
          </h2>
          <div className="markers">
            {mode === 'list'
              ? (
                <div className="flagName">
                  { statuses.map((c) => (
                    <label className="wrapper"
                      key={c.key}
                      value={c.key}>
                      <div className="toggle-wrapper">
                        <input
                          type="checkbox"
                          className="toggle"
                          checked={statusIds.indexOf(c.key) > -1}
                          onChange={(e) => this.handleValueToggle(e, c.key)}
                        />
                        <div className="toggle-icon" />
                      </div>

                      <div className="flag">
                        <img alt="Map marker"
                          src={`/assets/map-icons/marker${c.key}.png`} />
                      </div>
                      <div className="label">
                        {c.value}
                      </div>
                    </label>
                  ))}
                </div>
              ) : null }
            <div className={`markers-lower ${mode === 'list' ? '' : 'detail-markers'}`}>
              <div className="markers-info">
                {mode === 'list'
                  ? (
                    <label className="wrapper">

                      <div className="toggle-wrapper">
                        <input
                          type="checkbox"
                          className="toggle"
                          onChange={(e) => doToggle({ ...e, preventDefault: () => null, target: { value: (displayOpen ? 'false' : 'true') } }, 'displayOpen')}
                          checked={displayOpen}
                        />
                        <div className="toggle-icon" />
                      </div>

                      <div className="flag">
                        <img alt="Map marker"
                          src="/assets/map-icons/marker8.png" />
                      </div>
                      <div className="label">
                        All Active Retailers
                      </div>
                    </label>
                  )
                  : null}
              
              </div>
              <div className="markers-info">

                {mode === 'detail' ? (
                  <label className="wrapper">
                    <div className="toggle-wrapper">
                      <input
                        type="checkbox"
                        className="toggle"
                        onChange={(e) => doToggle({ ...e, preventDefault: () => null, target: { value: (displayCompanyTerritory ? 'false' : 'true') } }, 'displayCompanyTerritory')}
                        checked={displayCompanyTerritory}
                      />
                      <div className="toggle-icon" />
                    </div>

                    <div className="flag"
                      id="licenseeTerritories" />
                    <div className="label">
                      Retailer Territories
                    </div>
                  </label>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MarkerKey;
