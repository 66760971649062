import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../actions/navigationActions';
import DeleteModal from '../../shared/DeleteModal';
import { siteIDCompanyActions } from '../../../actions/siteIDCompanyActions';

class EditCompanies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: 0,

      name: '',
      phone: '',
      website: '',
      licenceTypeId: undefined,
      numberOfEmployees: '',
      street1: '',
      street2: '',
      street3: '',
      city: '',
      stateOrProvince: '',
      zipOrPostalCode: '',
      countryOrRegion: '',
      description: '',
      ownerId: undefined,
      primaryContactId: undefined,
      secondaryContactId: undefined,
      enableDriveThruPlan: false,
      countyIds: [],

      countyList: [],
      licenceTypeList: [],
      userList: [],

      detailsExpanded: true,
      countiesExpanded: false,

      countyFilter: '',

      showDeleteModal: false,

      showValidation: false,
    };

    this.handleSaveCompany = this.handleSaveCompany.bind(this);
    this.handleDeleteCompany = this.handleDeleteCompany.bind(this);

    this.handleTextInputUpdate = this.handleTextInputUpdate.bind(this);
    this.handleCheckInputUpdate = this.handleCheckInputUpdate.bind(this);
    this.handleCheckInputListUpdate = this.handleCheckInputListUpdate.bind(
      this,
    );
    this.handleCheckInputListUpdateMultiple = this.handleCheckInputListUpdateMultiple.bind(
      this,
    );

    this.handleToggleExpander = this.handleToggleExpander.bind(this);

    this.handleDeleteModalShow = this.handleDeleteModalShow.bind(this);
    this.handleDeleteModalHide = this.handleDeleteModalHide.bind(this);
  }

  componentDidMount() {
    let companyId = this.props.match.params.companyId;
    if (parseInt(companyId)) {
      this.props.getCompany(companyId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { pathname } = this.props.location;
    if (this.props.companyLoaded && !prevProps.companyLoaded) {
      this.setState({
        companyId: this.props.companyDetails.company.companyId,
        name: this.props.companyDetails.company.name,
        phone: this.props.companyDetails.company.phone,
        website: this.props.companyDetails.company.website,
        licenceTypeId: this.props.companyDetails.company.licenceTypeId,
        numberOfEmployees: this.props.companyDetails.company.numberOfEmployees,
        street1: this.props.companyDetails.company.street1,
        street2: this.props.companyDetails.company.street2,
        street3: this.props.companyDetails.company.street3,
        city: this.props.companyDetails.company.city,
        stateOrProvince: this.props.companyDetails.company.stateOrProvince,
        zipOrPostalCode: this.props.companyDetails.company.zipOrPostalCode,
        countryOrRegion: this.props.companyDetails.company.countryOrRegion,
        description: this.props.companyDetails.company.description,
        ownerId: this.props.companyDetails.company.ownerId,
        primaryContactId: this.props.companyDetails.company.primaryContactId,
        secondaryContactId: this.props.companyDetails.company.secondaryContactId,
        enableDriveThruPlan: this.props.companyDetails.company.enableDriveThruPlan,
        countyIds: this.props.companyDetails.company.countyIds,

        countyList: this.props.companyDetails.countyList,
        licenceTypeList: this.props.companyDetails.licenceTypeList,
        userList: this.props.companyDetails.userList,
      });
    } else if (this.props.companySaved && !prevProps.companySaved) {
      if (
        (this.props.companyDetails.company.companyId &&
          !prevProps.companyDetails.company) ||
        this.props.companyDetails.company.companyId !==
        prevProps.companyDetails.company.companyId
      ) {
        //New user saved for the first time.
        this.props.replace(
          `${pathname}${this.props.companyDetails.company.companyId}`,
        );
        this.props.getCompany(this.props.companyDetails.company.companyId);
      } else {
        this.props.goBack();
      }
    } else if (this.props.companyDeleted && !prevProps.companyDeleted) {
      this.props.goBack();
    }
  }

  handleSaveCompany(e) {
    let { saveCompany } = this.props;
    let {
      companyId,
      name,
      phone,
      website,
      licenceTypeId,
      numberOfEmployees,
      street1,
      street2,
      street3,
      city,
      stateOrProvince,
      zipOrPostalCode,
      countryOrRegion,
      description,
      ownerId,
      primaryContactId,
      secondaryContactId,
      enableDriveThruPlan,
      countyIds,
    } = this.state;

    if (!name) {
      this.setState({ showValidation: true });
    } else {
      saveCompany({
        companyId,
        name,
        phone,
        website,
        licenceTypeId,
        numberOfEmployees,
        street1,
        street2,
        street3,
        city,
        stateOrProvince,
        zipOrPostalCode,
        countryOrRegion,
        description,
        ownerId,
        primaryContactId,
        secondaryContactId,
        enableDriveThruPlan,
        countyIds,
      });
    }

    e.preventDefault();
  }

  handleDeleteModalShow() {
    this.setState({ showDeleteModal: true });
  }

  handleDeleteModalHide() {
    this.setState({ showDeleteModal: false });
  }

  handleToggleExpander(expanderName) {
    let state = this.state;
    this.setState({ [expanderName]: !state[expanderName] });
  }

  handleDeleteCompany() {
    this.props.deleteCompany(this.state.companyId);
    this.handleDeleteModalHide();
  }

  handleTextInputUpdate(e, fieldName) {
    let update = {};

    update[fieldName] = e.target.value;

    this.setState(update);
  }

  handleCheckInputUpdate(e, fieldName) {
    let update = {};

    update[fieldName] = true && e.target.checked;

    this.setState(update);
  }

  handleCheckInputListUpdate(e, name, id) {
    let ids = this.state[name];

    if (e.target.checked) {
      if (ids.indexOf(id) === -1) {
        ids.push(id);
      }
    } else {
      let pos = ids.indexOf(id);
      if (pos !== -1) {
        ids.splice(pos, 1);
      }
    }

    this.setState({
      [name]: ids,
    });
  }

  handleCheckInputListUpdateMultiple(e) {
    let { storeIds, stores, storeFilter } = this.state;

    let listValues = stores
      .filter(
        s =>
          s.storeName.toLowerCase().indexOf(storeFilter.toLowerCase()) > -1 ||
          s.storeNumber.toLowerCase().indexOf(storeFilter.toLowerCase()) > -1,
      )
      .map(s => s.storeId);

    this.setState({ storeIds: [...storeIds, ...listValues] });
  }

  getCompanyPanel() {
    let {
      detailsExpanded,
      name,
      phone,
      website,
      licenceTypeId,
      numberOfEmployees,
      street1,
      street2,
      street3,
      city,
      stateOrProvince,
      zipOrPostalCode,
      countryOrRegion,
      description,
      ownerId,
      enableDriveThruPlan,
      primaryContactId,
      secondaryContactId,

      licenceTypeList,
      userList,

      showValidation,
    } = this.state;

    return (
      <div className="group-details accordion">
        <div
          className="accordion-header"
          onClick={() => this.handleToggleExpander('detailsExpanded')}
        >
          <h3>Company Details</h3>
          <div
            className={`icon ${
              detailsExpanded ? 'icon-expand' : 'icon-collapse'
            }`}
          ></div>
        </div>
        <div
          className={`accordion-body ${
            detailsExpanded ? '' : 'collapsed'
          } horizontal-flex user-fields`}
        >
          <div className="user-field-block">

              <label>
                Name
                <input
                  placeholder="..."
                  required
                  className={showValidation && !name ? 'invalid' : ''}
                  onChange={e => this.handleTextInputUpdate(e, 'name')}
                  value={name}
                />
                </label>
          </div>
          {this.state.companyId > 0 && (
            <React.Fragment>
              {/*start of Jessica's work*/}
              <div>
                <div className="user-field-block">
                  <label>
                    Phone
                    <input
                      placeholder="..."
                      onChange={e => this.handleTextInputUpdate(e, 'phone')}
                      value={phone}
                    />
                  </label>
                  <label>
                    Website
                    <input
                      placeholder="..."
                      onChange={e => this.handleTextInputUpdate(e, 'website')}
                      value={website}
                    />
                  </label>
                  <label>
                    Business Type
                    <select
                      onChange={e =>
                        this.handleTextInputUpdate(e, 'licenceTypeId')
                      }
                      value={licenceTypeId}
                    >
                      <option value="">Please Select</option>
                      {licenceTypeList.map(l => (
                        <option key={l.key}
                          value={l.key}>
                          {l.value}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label>
                    Number of Employees
                    <input
                      placeholder="..."
                      type="number"
                      onChange={e =>
                        this.handleTextInputUpdate(e, 'numberOfEmployees')
                      }
                      value={numberOfEmployees}
                    />
                  </label>
                  <label>
                    Drive-Thru Market Plan Enabled
                    <div className="toggle-wrapper">
                      <input
                        type="checkbox"
                        className="toggle"
                        onChange={(e) => this.handleCheckInputUpdate(e, 'enableDriveThruPlan')}
                        checked={enableDriveThruPlan}
                      />
                      <div className="toggle-icon" />
                    </div>
                  </label>
                </div>
                <h3>Address</h3>
                <div className="user-field-block">
                  <label>
                    Street 1
                    <input
                      placeholder="..."
                      onChange={e => this.handleTextInputUpdate(e, 'street1')}
                      value={street1}
                    />
                  </label>
                  <label>
                    Street 2
                    <input
                      placeholder="..."
                      onChange={e => this.handleTextInputUpdate(e, 'street2')}
                      value={street2}
                    />
                  </label>
                  <label>
                    Street 3
                    <input
                      placeholder="..."
                      onChange={e => this.handleTextInputUpdate(e, 'street3')}
                      value={street3}
                    />
                  </label>
                  <label>
                    City
                    <input
                      placeholder="..."
                      onChange={e => this.handleTextInputUpdate(e, 'city')}
                      value={city}
                    />
                  </label>
                  <label>
                    State Or Province
                    <input
                      placeholder="..."
                      onChange={e =>
                        this.handleTextInputUpdate(e, 'stateOrProvince')
                      }
                      value={stateOrProvince}
                    />
                  </label>
                  <label>
                    Zip Or Postal Code
                    <input
                      placeholder="..."
                      onChange={e =>
                        this.handleTextInputUpdate(e, 'zipOrPostalCode')
                      }
                      value={zipOrPostalCode}
                    />
                  </label>
                  <label>
                    Country Or Region
                    <input
                      placeholder="..."
                      onChange={e =>
                        this.handleTextInputUpdate(e, 'countryOrRegion')
                      }
                      value={countryOrRegion}
                    />
                  </label>
                </div>
                <h3>Company Notes</h3>
                <div className="user-field-block">
                  <label className="personal-notes">
                    &nbsp;
                    <textarea
                      rows="4"
                      cols="50"
                      placeholder="Company Notes"
                      onChange={e =>
                        this.handleTextInputUpdate(e, 'description')
                      }
                      value={description}
                    ></textarea>
                  </label>
                </div>
                <h3>Contact Details</h3>
                <div className="user-field-block">
                  {' '}
                  <label>
                    Owner
                    <select
                      onChange={e => this.handleTextInputUpdate(e, 'ownerId')}
                      value={ownerId}
                    >
                      <option value="">Please Select</option>
                      {userList.map(l => (
                        <option key={l.key}
                          value={l.key}>
                          {l.value}
                        </option>
                      ))}
                    </select>
                  </label>{' '}
                  <label>
                    Primary Contact
                    <select
                      onChange={e =>
                        this.handleTextInputUpdate(e, 'primaryContactId')
                      }
                      value={primaryContactId}
                    >
                      <option value="">Please Select</option>
                      {userList.map(l => (
                        <option key={l.key}
                          value={l.key}>
                          {l.value}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label>
                    Secondary Contact
                    <select
                      onChange={e =>
                        this.handleTextInputUpdate(e, 'secondaryContactId')
                      }
                      value={secondaryContactId}
                    >
                      <option value="">Please Select</option>
                      {userList.map(l => (
                        <option key={l.key}
                          value={l.key}>
                          {l.value}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }

  getCountiesPanel() {
    let { countyList, countyIds, countiesExpanded, countyFilter } = this.state;

    let filteredCompanies = countyList.filter(
      s => s.value.toLowerCase().indexOf(countyFilter.toLowerCase()) > -1,
    );

    let selectedCompanies = countyList.filter(
      g => countyIds.indexOf(g.key) >= 0,
    );

    return (
      <div className="group-users accordion">
        <div
          className="accordion-header"
          onClick={() => this.handleToggleExpander('countiesExpanded')}
        >
          <h3>Territory Configuration</h3>
          <div
            className={`icon ${
              countiesExpanded ? 'icon-expand' : 'icon-collapse'
            }`}
          ></div>
        </div>
        <div
          className={`accordion-body ${countiesExpanded ? '' : 'collapsed'}`}
        >
          <div className="horizontal-flex-even">
            <div>
              <h3>
                All Territories (Filtering {filteredCompanies.length} of{' '}
                {countyList.length})
              </h3>
              <div className="floating-header">
                <input
                  placeholder="Filter..."
                  value={countyFilter}
                  onChange={e => this.handleTextInputUpdate(e, 'countyFilter')}
                />
              </div>
              {filteredCompanies.length > 50 && <h4>Too many results</h4>}
              {filteredCompanies.length <= 50 &&
                filteredCompanies.map(g => (
                  <label key={g.key}
                    className="wrapping">
                    <div className="toggle-wrapper">
                      <input
                        type="checkbox"
                        className="toggle"
                        checked={countyIds.indexOf(g.key) >= 0}
                        onChange={e =>
                          this.handleCheckInputListUpdate(e, 'countyIds', g.key)
                        }
                      />
                      <div className="toggle-icon"></div>
                    </div>
                    <span>{g.value}</span>
                  </label>
                ))}
            </div>
            <div>
              <h3>Selected Territories ({selectedCompanies.length})</h3>
              {selectedCompanies.map(g => (
                <label key={g.key}
                  className="wrapping">
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      checked={true}
                      onChange={e =>
                        this.handleCheckInputListUpdate(
                          e,
                          'countyIds',
                          g.key,
                        )
                      }
                    />
                    <div className="toggle-icon"></div>
                  </div>
                  <span>{g.value}</span>
                </label>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { showDeleteModal } = this.state;
    const {
      companyDeleting,
      companyDeleted,
      companySaving,
      companySaved,
      errorMessage,
    } = this.props;
    return (
      <div className="edit-user-page page">
        <div className="floating-panel">
          <form noValidate
            onSubmit={e => this.handleSaveCompany(e)}>
            <div className="floating-header">
              <div className="header-title">
                <div className="icon icon-store"></div>
                {this.state.companyId ? (
                  <h2>Edit Company</h2>
                ) : (
                  <h2>Create Company</h2>
                )}
              </div>
            </div>

            {this.getCompanyPanel()}

            {this.state.companyId > 0 &&
              this.state.countyList &&
              this.state.countyList.length > 0 &&
              this.getCountiesPanel()}

            <p>{errorMessage}</p>
            <div className="button-container">
              <button className="action"
                disabled={companySaving}>
                {companySaving
                  ? 'Saving...'
                  : companySaved && !this.state.companyId
                    ? 'Saved'
                    : 'Save'}
              </button>
              {this.state.companyId > 0 && (
                <button
                  type="button"
                  className="action"
                  onClick={() => this.handleDeleteModalShow()}
                  disabled={companyDeleting}
                >
                  {companyDeleting
                    ? 'Deleting...'
                    : companyDeleted
                      ? 'Deleted'
                      : 'Delete'}
                </button>
              )}
            </div>
          </form>
          {showDeleteModal && (
            <DeleteModal
              cancel={this.handleDeleteModalHide}
              continue={this.handleDeleteCompany}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let { currentCompany } = state.siteIDCompanies;
  return {
    companyLoading: currentCompany.isFetching,
    companyLoaded: currentCompany.isLoaded,
    companyDetails: currentCompany.companyDetails,
    companySaving: currentCompany.isSaving,
    companySaved: currentCompany.isSaved,
    companyDeleting: currentCompany.isDeleted,
    companyDeleted: currentCompany.isDeleted,
    errorMessage: currentCompany.errorMessage,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCompany: companyId =>
      dispatch(siteIDCompanyActions.requestCompany(companyId)),
    saveCompany: company => dispatch(siteIDCompanyActions.saveCompany(company)),
    deleteCompany: companyId =>
      dispatch(siteIDCompanyActions.deleteCompany(companyId)),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCompanies);
