/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import DhlHandlingTab from './DhlHandlingTab';
import DsvHandlingTab from './DsvHandlingTab';
import ExpeditorsHandlingTab from './ExpeditorsHandlingTab';
import NissinHandlingTab from './NissinHandlingTab';
import OtherHandlingTab from './OtherHandlingTab';
import TabDocuments from './TabDocuments';
import YchHandlingTab from './YchHandlingTab';
import BrfAudit from '../BrfAudit/BrfAudit';

function BookingRequestForm(props) {
  const [formDetail, setFormDetail] = useState({ 
  });
  const [isCreate, setIsCreate] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [_3pls, set3pls] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isCompleting, setIsCompleting] = useState(false);
  const [currentTab, setCurrentTab] = useState('cargo');
  const [shipModes, setShipModes] = useState({});
  const [notifyParties, setNotifyParties] = useState([]);
  const [notifyPartyId, setNotifyPartyId] = useState(0);
  const [notifyPartyDetailLoaded, setNotifyPartyDetailLoaded] = useState(false);
  const [freightTerms, setFreightTerms] = useState(null);
  const [totalFreightCost, setTotalFreightCost] = useState(0.00);
  const [totalHandling, setTotalHandling] = useState(0.00);
  const [notifyOther, setNotifyOther] = useState(false);
  const [page]  = useState(1);
  const [data, setData] = useState([]);
    
  const convertToQuerystring = (obj) => {
    const qs = Object.keys(obj)
      .map(key => `${key}=${obj[key]}`)
      .join('&');

    return qs.length === 0
      ? qs
      : `?${qs}`;
  };

  const downloadForm = () => {
    async function loadFormData(){
      const response = await fetch(`/api/omorderbookingrequest/v1/ExportBookingRequestForm${convertToQuerystring({ formId : props.match.params.id })}`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });

      if (response.status == 200){
        const data = await response.blob();

        let filename = response.headers.get('content-disposition');
        let nameIndex = filename.indexOf('filename="');

        if (nameIndex > -1) {
          filename = filename.slice(nameIndex + 10);
          nameIndex = filename.indexOf('"');

          if (nameIndex > -1) {
            filename = filename.slice(0, nameIndex);
          }
        } else {
          nameIndex = filename.indexOf('filename=');
          if (nameIndex > -1) {
            filename = filename.slice(nameIndex + 9);

            nameIndex = filename.indexOf(';');

            if (nameIndex > -1) {
              filename = filename.slice(0, nameIndex);
            }
          }
        }

        const a = document.createElement('a');
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = filename;
        a.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }, 0);
      }
    }
    loadFormData();
  };

  useEffect(() => {
    (async () => {
      //is create
      if (window.location.toString().toLocaleLowerCase().indexOf('create') > -1) {
        setIsCreate(true);

        fetchIsCreateData();
      }
      else {
        fetchForm();
        loadAudit();
      }
    })();
  }, []);

  const calculateTotalFreightCost = () => {
    let calculation = 0;
    calculation = (Number(formDetail.oceanFreightCost) + Number(formDetail.airFreightCost) + Number(formDetail.bafOceanCost) + Number(formDetail.fscAirCost) + Number(formDetail.issAirCost));

    setTotalFreightCost(calculation > 0 ? calculation.toFixed(2) : 0);
  };

  useEffect(() => {
    (async () => {

      if(!notifyPartyId && notifyParties){
        var newId = notifyParties.find(n => n.defaultOption === true)?.key;

        if(newId){
          setNotifyPartyId(newId);
        }
        
        return;
      }

      const response = await fetch(`/api/OmOrderNotifyParty/v1/GetNotifyPartyDetail${convertToQuerystring({ id: notifyPartyId })}`);

      var data = await response.json();
      if (response.status == 200){
        setNotifyPartyDetailLoaded(true);

        setFormDetail({
          ...formDetail,
          notifyPartyId: notifyPartyId,
          notifyPartyOverrideCompanyName: data.companyName,
          notifyPartyOverrideIaddress:data.notifyPartyIAddress,
          notifyPartyOverrideItelephone:data.notifyPartyITelephone,
          notifyPartyOverrideImobile:data.notifyPartyIMobile,
          notifyPartyOverrideIfax:data.notifyPartyIFax,
          notifyPartyOverrideIemail:data.notifyPartyIEmail,
          notifyPartyOverrideIattention:data.notifyPartyIAttention,
          notifyPartyOverrideIi:data.notifyPartyII,
        });
      }
    })();
  }, [notifyPartyId]);

  const fetchForm = () => {
    async function loadFormData(){
      const response = await fetch(`/api/omorderbookingrequest/v1/GetBookingRequestFormDetail${convertToQuerystring({ formId : props.match.params.id })}`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });

      if (response.status == 200){
        const data = await response.json();

        //format decimals on load to two fixed places.
        const rateCardObj = {};
        for (const [key, value] of Object.entries(data.rateCardModel)) {
          if (value != null){
            rateCardObj[key] = value?.toFixed(2);
          }
          else{
            rateCardObj[key] = '0.00';
          }
        }
        
        setFormDetail({
          ...data.formDetail,
          ...rateCardObj,
          eta: data.formDetail.eta?.split('T')[0],
          etd: data.formDetail.etd?.split('T')[0],
        });
        setFreightTerms(data.freightTerms);
        setShipModes(data.shipModes);
        setNotifyParties(data.notifyParties);
        setNotifyOther((data.formDetail && data.formDetail.notifyPartyOther) || false);
        setNotifyPartyId(data.formDetail && data.formDetail.notifyPartyId);
        setIsLoaded(true);
      }
    }
    loadFormData();
  };

  useEffect(() => {
    calculateTotalFreightCost();
  }, [isLoaded, formDetail]);

  const isNissin = formDetail._3pl?.toLowerCase() == 'nissin';
  const isDhl = formDetail._3pl?.toLowerCase() == 'dhl';
  const isYchFrdc = formDetail._3pl?.toLowerCase() == 'ych-frdc';
  const isDsv = formDetail._3pl?.toLowerCase() == 'dsv';
  const isYch = formDetail._3pl?.toLowerCase() == 'ych';
  const isExpeditors = formDetail._3pl?.toLowerCase() == 'expeditors';
  const isOther = formDetail._3pl?.toLowerCase() == 'other';

  const fetchIsCreateData = () => {
    async function loadFormData(){
      const response = await fetch('/api/omorderbookingrequest/v1/GetCreateFormDetail', {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });

      setErrorMessage(response.errorMessage);

      if (response.status == 200){
        const data = await response.json();
        set3pls(data._3pls);
        const newFormDetail = {
          ...formDetail, 
          isCreate: true,
        };
        setFormDetail(newFormDetail);
      }
    }
    loadFormData();
  };

  const handleSetTotalHandling = (value) => {
    setTotalHandling(value);
  };

  const handleTextInputUpdate = (e, fieldName) => {
    const inputType = e.target.type;
    let inputValue = e.target.value;

    if (inputType === 'number' && !isNumericStringInputPositiveValue(inputValue)) {
      inputValue = '0';
    }
    
    setFormDetail({ ...formDetail, [fieldName] : inputValue });
  };

  const loadAudit = async() => {
    const response = await fetch(`/api/omorderbookingrequest/v1/GetBrfAuditTrail?brfId=${props.match.params.id}&page=${page}&pageSize=25`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    });

    if (response.status == 200){
      const stuff = await response.json();
      setData(stuff.data);
    }
  };

  const isNumericStringInputPositiveValue = (ElementValue) => {
    const numericValue = Number(ElementValue);
    return !isNaN(numericValue) && numericValue >= 0;
  };

  const handleCheckInputUpdate = (e, fieldName) => {
    setFormDetail({ ...formDetail, [fieldName] : e.target.checked });
  };

  const handleChangeTab = (tab) => {
    setCurrentTab(tab);
  };
  
  const saveForm = (complete) => {
    if (isCreate && formDetail._3pl == ''){
      setErrorMessage('Please select a 3pl.');
    }
    else{
      loadFormData(complete);
    }
    async function loadFormData(complete){
      complete? setIsCompleting(true) : setIsSaving(true);
      const response = await fetch('/api/omorderbookingrequest/v1/SaveBookingRequestForm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          ...formDetail,
          totalFreight: totalFreightCost,
          totalHandling,
          notifyPartyId,
          complete,
          notifyPartyOther: notifyOther == true ? true : false,
        }),
      });

      const data = await response.json();
  
      if (response.status == 400){
        setErrorMessage(data);
      }

      if (response.status == 200){
        complete? setIsCompleted(true) : setIsSaved(true);

        setTimeout(() => {
          complete? setIsCompleted(false) : setIsSaved(false);
        }, 3000);

        fetchForm();
        loadAudit();

        if (isCreate && data != null){
          window.location.replace(`/module/315/BRF-Edit/${data}`);
        }
      }
      fetchForm();
      complete? setIsCompleting(false) : setIsSaving(false);
    }
  };

  return (
    <div id="bookingRequestForm">
      <div  className="edit-feedback-page page">
        <div className="floating-panel">
          <div className="floating-header">
            <div className="header-title">
              <div className="icon icon-form"></div>
              <h2>{isCreate ? 'Create Booking Request Form' : 'Edit Booking Request Form'}</h2>
            </div>
            <div
              style={{ display:'flex' }}>
              {!isCreate &&
              <div className="group-details accordion download-container summary"
                style={{ marginRight: '25px' } }
              >
                <div className="accordion-body horizontal-flex order-fields">
                  <div>
                    <button
                      className="action export"
                      type="button"
                      title="Export"
                      onClick={() => downloadForm()}
                    >
                      <div
                        className="icon icon-download-light"
                        alt="Export"
                      />
                    </button>
                  </div>
                </div>
              </div>
              }
              <div className="group-details accordion download-container summary"
                style={{ marginRight: '18px' } }
              >
                <div className="accordion-body horizontal-flex order-fields">
                  <div>
                    <button
                      type="button"
                      className="action"
                      disabled={isCreate ? (!formDetail.trackingNumber && !formDetail.starbucksOrderNumber) : false}
                      onClick={() => saveForm(false)}>
                      {isSaving ? 'Saving...' : isSaved ? 'Saved' : 'Save'}
                    </button>
                  </div>
                  {isCreate ? null :
                    <div>
                      <button
                        type="button"
                        className="action"
                        onClick={() => saveForm(true)}>
                        {isCompleting ? 'Completing...' : isCompleted ? 'Completed' : 'Complete'}
                      </button>
                    </div>}
                </div>
              </div>
            </div>
          </div>
        </div>

        <form className="form-container"
          noValidate
          onSubmit={e => false}>
          <div className="form-header">
            <h3>Order Detail</h3>
          </div>
          <div className="form-body">
            {isCreate &&
            <>
              <div>Start by entering Customer Order Number and/or the Sales Order Number linked to the order.</div>
            </>
            }
            {!isCreate &&
            <>
              <label>
                  Status
                <input
                  className='uneditable'
                  disabled
                  value={formDetail.completed ? 'Completed' : 'Pending'}
                />
              </label>
              <label>
                  Shipper
                <input
                  className='uneditable'
                  disabled
                  value={'Your corporate address'}
                />
              </label>
              <label>
               3PL
                <input
                  className='uneditable'
                  disabled
                  value={formDetail._3pl ?? 'N/A'}
                />
              </label>
              <label>
               Consignee
                <input
                  className='uneditable'
                  disabled
                  value={formDetail.customerName ?? 'N/A'}
                />
              </label>
              <label>
                  Sample Order{' '}
                <div className="toggle-wrapper">
                  <input
                    type="checkbox"
                    className="toggle"
                    checked={formDetail.sampleOrder}
                    onChange={(e) => handleCheckInputUpdate(e, 'sampleOrder')}
                  />
                  <div className="toggle-icon"></div>
                </div>
              </label>
            </>
            }
          </div>
          {!isCreate &&
          <div className='form-body'>
            {formDetail.sampleOrder &&
            <label>
                  Notify Other{' '}
              <div className="toggle-wrapper">
                <input
                  type="checkbox"
                  className="toggle"
                  checked={notifyOther ?? false}
                  onChange={(e) => setNotifyOther(e.target.checked)}
                />
                <div className="toggle-icon"></div>
              </div>
            </label>
            }
            <label>
                  Notify Party{' '}
              <select
                value={notifyPartyId}
                onChange={e => setNotifyPartyId(e.target.value)}
              >
                <option value=''>Select a party...</option>
                {notifyParties && notifyParties.length && notifyParties.map(m => 
                  <option key={m.key}
                    value={m.key}>{m.value}</option>,
                )
                }
              </select>
            </label>
            {(notifyPartyDetailLoaded || notifyOther) && ( 
              <>  
                <label>
                Notify Party
                Company Name
                  {' '}
                  <input
                    disabled={!notifyOther}
                    onChange={e => handleTextInputUpdate(e, 'notifyPartyOverrideCompanyName')}
                    value={formDetail.notifyPartyOverrideCompanyName}
                  />
                </label> 
                <label>
                 Notify Party I
                  Address
                  {' '}
                  <input
                    disabled={!notifyOther}
                    onChange={e => handleTextInputUpdate(e, 'notifyPartyOverrideIaddress')}
                    value={formDetail.notifyPartyOverrideIaddress}
                  />
                </label> 
                <label>
                 Notify Party I
                Telephone
                  {' '}
                  <input
                    disabled={!notifyOther}
                    onChange={e => handleTextInputUpdate(e, 'notifyPartyOverrideItelephone')}
                    value={formDetail.notifyPartyOverrideItelephone}
                  />
                </label> 
                <label>
                 Notify Party I
                  Mobile
                  {' '}
                  <input
                    disabled={!notifyOther}
                    onChange={e => handleTextInputUpdate(e, 'notifyPartyOverrideImobile')}
                    value={formDetail.notifyPartyOverrideImobile}
                  />
                </label> 
                <label>
                 Notify Party I
                 Fax
                  {' '}
                  <input
                    disabled={!notifyOther}
                    onChange={e => handleTextInputUpdate(e, 'notifyPartyOverrideIfax')}
                    value={formDetail.notifyPartyOverrideIfax}
                  />
                </label> 
                <label>
                 Notify Party I
                  Email
                  {' '}
                  <input
                    disabled={!notifyOther}
                    onChange={e => handleTextInputUpdate(e, 'notifyPartyOverrideIemail')}
                    value={formDetail.notifyPartyOverrideIemail}
                  />
                </label> 
                <label>
                 Notify Party I
                  Attention
                  {' '}
                  <input
                    disabled={!notifyOther}
                    onChange={e => handleTextInputUpdate(e, 'notifyPartyOverrideIattention')}
                    value={formDetail.notifyPartyOverrideIattention}
                  />
                </label> 
                <label>
                 Notify Party II
                  {' '}
                  <input
                    disabled={!notifyOther}
                    onChange={e => handleTextInputUpdate(e, 'notifyPartyOverrideIi')}
                    value={formDetail.notifyPartyOverrideIi}
                  />
                </label>  
              </>
            )}
          </div>
          }
          <div className='form-body'>
            {isCreate &&
            <label>
                   3PL *
              <select
                value={formDetail._3pl}
                onChange={e => handleTextInputUpdate(e, '_3pl')}
              >
                <option value=''></option>
                {_3pls && _3pls.length && _3pls.map(m => 
                  <option key={m.key}
                    value={m.value}>{m.value}</option>,
                )
                }
              </select>
            </label>
            }
            <label>
                 Customer Reference Number
              <input
                className={`${!isCreate ? 'uneditable' : ''}`}
                disabled={!isCreate}
                onChange={e => handleTextInputUpdate(e, 'trackingNumber')}
                value={formDetail.trackingNumber ?? 'N/A'}
              />
            </label>
            <label>
                Sales Order Number
              <input
                className={`${!isCreate ? 'uneditable' : ''}`}
                disabled={!isCreate}
                onChange={e => handleTextInputUpdate(e, 'starbucksOrderNumber')}
                value={formDetail.starbucksOrderNumber ?? 'N/A'}
              />
            </label>
            <div className='form-body'>
              <div style={{ color: 'red' }}>{errorMessage}</div>
            </div>
            {!isCreate &&
            <>
              <label>
                 Delivery ID Number
                <input
                  className='uneditable'
                  disabled
                  value={formDetail.deliveryId ?? 'N/A'} 
                />
              </label>
              <label>
                  Supplier PO/Release No
                <input
                  className='uneditable'
                  disabled
                  value={formDetail.supplierReleaseNo ?? 'N/A'}
                />
              </label>
              <label>
                 Ready To Ship Date
                <input
                  className='uneditable'
                  disabled
                  value={(formDetail.rtsDate && new Date(formDetail.rtsDate).toLocaleDateString()) ?? 'N/A'}
                />
              </label>
              <label>
                Request ETA (Promise Date)
                <input
                  className='uneditable'
                  disabled
                  value={(formDetail.promiseDate && new Date(formDetail.promiseDate).toLocaleDateString()) ?? 'N/A'}
                />
              </label>
              <label>
                Carrier
                <input
                  onChange={e => handleTextInputUpdate(e, 'carrier')}
                  type="text"
                  value={formDetail.carrier}
                />
              </label>
              <label>
                Port Of Loading
                <input
                  onChange={e => handleTextInputUpdate(e, 'portOfLoading')}
                  type="text"
                  value={formDetail.portOfLoading}
                />
              </label>
              <label>
                Port Of Discharge
                <input
                  onChange={e => handleTextInputUpdate(e, 'portOfDischarge')}
                  type="text"
                  value={formDetail.portOfDischarge}
                />
              </label>
              <label>
                Place Of Delivery
                <input
                  onChange={e => handleTextInputUpdate(e, 'placeOfDelivery')}
                  type="text"
                  value={formDetail.placeOfDelivery}
                />
              </label>
              <label>
                BL Number
                <input
                  onChange={e => handleTextInputUpdate(e, 'blnumber')}
                  type="text"
                  value={formDetail.blnumber}
                />
              </label>
              <label>
                ETD
                <input
                  onChange={e => handleTextInputUpdate(e, 'etd')}
                  type="date"
                  value={formDetail.etd}
                />
              </label>
              <label>
                ETA
                <input
                  onChange={e => handleTextInputUpdate(e, 'eta')}
                  type="date"
                  value={formDetail.eta}
                />
              </label>
              <label>
                Feeder Vessel Name
                <input
                  onChange={e => handleTextInputUpdate(e, 'feederVesselName')}
                  type="text"
                  value={formDetail.feederVesselName}
                />
              </label>
              <label>
                Feeder Voyage Number
                <input
                  onChange={e => handleTextInputUpdate(e, 'feederVoyageNumber')}
                  type="text"
                  value={formDetail.feederVoyageNumber}
                />
              </label>
              <label>
                Mother Vessel Name
                <input
                  onChange={e => handleTextInputUpdate(e, 'motherVesselName')}
                  type="text"
                  value={formDetail.motherVesselName}
                />
              </label>
              <label>
                Mother Voyage Number
                <input
                  onChange={e => handleTextInputUpdate(e, 'motherVoyageNumber')}
                  type="text"
                  value={formDetail.motherVoyageNumber}
                />
              </label>
              <label>
                Supplier Document Charge
                <input
                  onChange={e => handleTextInputUpdate(e, 'supplierDocumentCharge')}
                  type="number"
                  value={formDetail.supplierDocumentCharge}
                />
              </label>
              <label>
                Freight Term
                <select
                  value={formDetail.freightTermId}
                  onChange={e => handleTextInputUpdate(e, 'freightTermId')}
                >
                  <option value=''>Select a freight term...</option>
                  {freightTerms && freightTerms.length && freightTerms.map(m => 
                    <option key={m.key}
                      value={m.key}>{m.value}</option>,
                  )
                  }
                </select>
              </label>
              <label>
                    Container Numbers (comma seperated)
                <input
                  onChange={e => handleTextInputUpdate(e, 'containerNumbers')}
                  type="text"
                  value={formDetail.containerNumbers}
                />
              </label>
              <label>
                    Shipment Consolidated
                <div className="toggle-wrapper">
                  <input
                    type="checkbox"
                    className="toggle"
                    checked={formDetail.shipmentsConsolidated} 
                    onChange={e => handleCheckInputUpdate(e, 'shipmentsConsolidated')}

                  />
                  <div className="toggle-icon"></div>
                </div>
              </label>
              {formDetail.shipmentsConsolidated &&
                <label>
                         Shipment Consolidated Comments
                  <textarea
                    value={formDetail.shipmentsConsolidatedComments}
                    onChange={e => handleTextInputUpdate(e, 'shipmentsConsolidatedComments')}
                    autoComplete="off"
                  />
                </label>
              }
            </>
            }
          </div>
        </form>
        
        {isLoaded && !isCreate &&
        <>
          <div className="tab-container">
            <div
              className={`tab-button ${
                currentTab === 'cargo' ? 'selected' : ''
              }`}
              onClick={() => handleChangeTab('cargo')}>
              Cargo
              <br />
              Detail
            </div>
            <div
              className={`tab-button ${
                currentTab === 'freight' ? 'selected' : ''
              }`}
              onClick={() => handleChangeTab('freight')}>
              Freight
            </div>
            <div
              className={`tab-button ${
                currentTab === 'handling' ? 'selected' : ''
              }`}
              onClick={() => handleChangeTab('handling')}>
              Handling
            </div>
            <div
              className={`tab-button ${
                currentTab === 'documents' ? 'selected' : ''
              }`}
              onClick={() => handleChangeTab('documents')}>
              Documents
            </div>
          </div>
          <form className="form-container"
            noValidate
            style={{ display: currentTab === 'cargo' ? 'block' : 'none' }}
            onSubmit={e => false}>
            <div className="form-header">
              <h3>Cargo Detail</h3>
            </div>
            
            <div className="form-body">
              <label>
                  Gross Weight (Kg)
                <input
                  onChange={e => handleTextInputUpdate(e, 'grossWeight')}
                  placeholder='e.g: 6000'
                  type="number"
                  value={formDetail.grossWeight}
                />
              </label>
              <label>
                 Net Weight (Kg)
                <input
                  onChange={e => handleTextInputUpdate(e, 'netWeight')}
                  placeholder='e.g: 4000'
                  type="number"
                  value={formDetail.netWeight}
                />
              </label>
              <label>
                  CBM{' '}
                <input
                  onChange={e => handleTextInputUpdate(e, 'cbm')}
                  placeholder='e.g: 14'
                  type="number"
                  value={formDetail.cbm}
                />
              </label>
              <label>
                  Piece{' '}
                <input
                  onChange={e => handleTextInputUpdate(e, 'piece')}
                  placeholder='e.g: 20'
                  type="number"
                  value={formDetail.piece}
                />
              </label>
              <label>
                  Carton{' '}
                <input
                  onChange={e => handleTextInputUpdate(e, 'carton')}
                  placeholder='e.g: 60'
                  type="number"
                  value={formDetail.carton}
                />
              </label>
              <label>
                 Pallets
                <input
                  onChange={e => handleTextInputUpdate(e, 'pallet')}
                  type="number"
                  value={formDetail.pallet}
                />
              </label>
              <label>
                 Ship Mode
                <select
                  value={formDetail.shipModeId}
                  onChange={e => handleTextInputUpdate(e, 'shipModeId')}
                >
                  <option value=''>Select a ship mode...</option>
                  {shipModes && shipModes.length && shipModes.map(m => 
                    <option key={m.key}
                      value={m.key}>{m.value}</option>,
                  )
                  }
                </select>
              </label>
              <label>
                 Commodity
                <input
                  onChange={e => handleTextInputUpdate(e, 'commodity')}
                  placeholder='e.g: Merchandise'
                  type="text"
                  maxLength={60}
                  value={formDetail.commodity}
                />
              </label>
              <label>
                 Seal Number
                <input
                  onChange={e => handleTextInputUpdate(e, 'sealNumber')}
                  placeholder=''
                  type="text"
                  maxLength={200}
                  value={formDetail.sealNumber}
                />
              </label>
              <label>
                  Number of container/size: 20&apos;GP
                <input
                  onChange={e => handleTextInputUpdate(e, 'noContainer20Gp')}
                  type="number"
                  maxLength={5}
                  value={formDetail.noContainer20Gp} />
              </label><label>
                    Number of container/size: 40&apos;GP
                <input
                  onChange={e => handleTextInputUpdate(e, 'noContainer40Gp')}
                  type="number"
                  maxLength={5}
                  value={formDetail.noContainer40Gp} />
              </label><label>
                    Number of container/size: 40&apos;HC
                <input
                  onChange={e => handleTextInputUpdate(e, 'noContainer40Hc')}
                  type="number"
                  maxLength={5}
                  value={formDetail.noContainer40Hc} />
              </label><label>
                    Number of container/size: 20&apos;RF
                <input
                  onChange={e => handleTextInputUpdate(e, 'noContainer20Rf')}
                  type="number"
                  maxLength={5}
                  value={formDetail.noContainer20Rf} />
              </label><label>
                    Number of container/size: 40&apos;RF
                <input
                  onChange={e => handleTextInputUpdate(e, 'noContainer40Rf')}
                  type="number"
                  maxLength={5}
                  value={formDetail.noContainer40Rf} />
              </label>
            </div>
          </form>
          <form className="form-container"
            noValidate
            style={{ display: currentTab === 'freight' ? 'block' : 'none' }}
            onSubmit={e => false}>
            <div className="form-header">
              <h3>Freight</h3>
            </div>
            <div className="form-body">
              <label>
                  Total Freight Cost (USD)
                <input
                  disabled
                  type='number'
                  value={totalFreightCost}
                />
              </label>
            </div>
            <div className="form-body">
              <label>
                  Ocean Freight Cost (USD)
                <input
                  onChange={e => handleTextInputUpdate(e, 'oceanFreightCost')}
                  type='number'
                  value={formDetail.oceanFreightCost}
                />
              </label>
              <label>
                  Air Freight Cost (USD)
                <input
                  onChange={e => handleTextInputUpdate(e, 'airFreightCost')}
                  type='number'
                  value={formDetail.airFreightCost}
                />
              </label>
              <label>
                  BAF (Ocean) Cost (USD)
                <input
                  onChange={e => handleTextInputUpdate(e, 'bafOceanCost')}
                  type='number'
                  value={formDetail.bafOceanCost}
                />
              </label>
              <label>
                  FSC (Air) Cost (USD)
                <input
                  onChange={e => handleTextInputUpdate(e, 'fscAirCost')}
                  type='number'
                  value={formDetail.fscAirCost}
                />
              </label>
              <label>
                  ISS (Air) Cost (USD)
                <input
                  onChange={e => handleTextInputUpdate(e, 'issAirCost')}
                  type='number'
                  value={formDetail.issAirCost}
                />
              </label>
            </div>
          </form>
          <form className="form-container"
            noValidate
            style={{ display: currentTab === 'handling' ? 'block' : 'none' }}
            onSubmit={e => false}>
            <div className="form-header">
              <h3>Handling</h3>
            </div>
            {isNissin &&
            <NissinHandlingTab
              handleTextInputUpdate={handleTextInputUpdate}
              formDetail={formDetail}
              totalHandling={totalHandling}
              handleSetTotalHandling={(v) => handleSetTotalHandling(v)}
            />
            }
            {isDhl || isYchFrdc &&
            <DhlHandlingTab
              handleTextInputUpdate={handleTextInputUpdate}
              formDetail={formDetail}
              totalHandling={totalHandling}
              handleSetTotalHandling={(v) => handleSetTotalHandling(v)}
            />
            }
            {isDsv &&
            <DsvHandlingTab
              handleTextInputUpdate={handleTextInputUpdate}
              formDetail={formDetail}
              totalHandling={totalHandling}
              handleSetTotalHandling={(v) => handleSetTotalHandling(v)}
            />
            }
            {isExpeditors &&
            <ExpeditorsHandlingTab
              handleTextInputUpdate={handleTextInputUpdate}
              formDetail={formDetail}
              totalHandling={totalHandling}
              handleSetTotalHandling={(v) => handleSetTotalHandling(v)}
            />
            }
            {isYch &&
            <YchHandlingTab
              handleTextInputUpdate={handleTextInputUpdate}
              formDetail={formDetail}
              totalHandling={totalHandling}
              handleSetTotalHandling={(v) => handleSetTotalHandling(v)}
            />
            }
            {isOther &&
            <OtherHandlingTab
              handleTextInputUpdate={handleTextInputUpdate}
              formDetail={formDetail}
              totalHandling={totalHandling}
              handleSetTotalHandling={(v) => handleSetTotalHandling(v)}
            />
            }
          </form>
          <form className="form-container"
            noValidate
            style={{ display: currentTab === 'documents' ? 'block' : 'none' }}
            onSubmit={e => false}>
            <div className="form-header">
              <h3>Documents</h3>
            </div>
            <TabDocuments
              formId={props.match.params.id}
            />
          </form>
          {data && 
          <div style={{ marginTop: '20px' }}>
            <BrfAudit data={data} />
          </div>
          }
        </>
        }
      </div>
    </div>
  );
}

export default BookingRequestForm;
