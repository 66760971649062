import React, { useState, useEffect, useRef } from 'react';
import DebouncedInput from 'react-debounce-input';
import { connect } from 'react-redux';
import orderTrackerActions from '../../../actions/orderTrackerActions';
import './CreateOrder.scss';
import ProgressState from '../components/ProgressState/ProgressState';
import StatusDropdown from '../components/StatusDropdown/StatusDropdown';
import { calculateAge, calculateDaysDelayed, sanitiseNumberInput } from '../utils';

const CreateOrder = ({ searchCustomers, ...props }) => {
  // general state
  const [formSubmitted, setFormSubmitted] = useState(false);

  // search modal
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [storeNumberSearch, setStoreNumberSearch] = useState('');
  const [storeNameSearch, setStoreNameSearch] = useState('');

  // Form Fields
  const [storeNumber, setStoreNumber] = useState('');
  const [storeName, setStoreName] = useState('');
  const [bespokeDelay, setBespokeDelay] = useState('');
  const [orderNumber, setOrderNumber] = useState('');
  const [poNumber, setPoNumber] = useState('');
  const [trip, setTrip] = useState('');
  const [orderStatus, setOrderStatus] = useState('');
  const [createdDate, setCreatedDate] = useState('');
  const [comments, setComments] = useState('');
  const [orderProgress, setOrderProgress] = useState([
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ]);

  // Field refs
  const formRefContainer = useRef(null);

  const getProgressStage = () => {
    
    let progressStage = 0;

    for (let i = 6; i >= 0; i--) {
      if (orderProgress[i].length > 0) {
        progressStage = i + 1;
        break;
      }
    }

    return progressStage;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.saveOrder({
      comments,
      trip: trip.length ? trip : undefined,
      storeNumber,
      storeName,
      externalOrderNumber: orderNumber,
      externalPONumber: poNumber,
      createdAt: createdDate,
      progressId: getProgressStage() - 1,
      statusId: orderStatus,
      progressStages: orderProgress,
    });
  };

  const handleProgressUpdate = (stage, value) => {
    const progress = orderProgress.slice(0);
    progress[stage] = value.trim();
    setOrderProgress(progress);
  };

  const progressStage = getProgressStage();

  useEffect(
    () => {
      props.clearState();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const clearForm = (e) => {
    setFormSubmitted(false);
    setStoreNumber('');
    setStoreName('');
    setOrderNumber('');
    setPoNumber('');
    setTrip('');
    setOrderStatus('');
    setCreatedDate('');
    setComments('');
    setOrderProgress([
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    ]);

    props.clearState();

    // Defer the form reset until after render.
    // This allows the form to re-render with the cleared down values,
    // which then allows the validation to reset properly...
    setTimeout(
      () => {
        formRefContainer.current.reset();
      },
      0);
  };

  const handleOrderStatusChange = (e) => {
    const { value } = e.target;
    setOrderStatus(value);

    if (value === '5' && orderProgress[6] === '') {
      handleProgressUpdate(6, new Date().toISOString().split('T')[0]);
    }
  };

  const handleCustomerSelect = (customer) => {
    setStoreNumber(customer.storeNumber);
    setStoreName(customer.storeName);
    setBespokeDelay(customer.storeGroups.find(c => c.toLowerCase() === 'alshaya'));
    setShowSearchModal(false);
  };

  useEffect(
    () => {
      const params = {};

      if (storeNumberSearch) {
        params.customerNumber = storeNumberSearch;
      }

      if (storeNameSearch) {
        params.customerName = storeNameSearch;
      }

      searchCustomers(params);
    },
    [searchCustomers, storeNumberSearch, storeNameSearch],
  );

  return (
    <div className="page create-order-page">
      <div className="floating-panel">
        <form
          onSubmit={e => handleSubmit(e)}
          ref={formRefContainer}
          className={formSubmitted ? 'submitted' : ''}
        >
          <div className="floating-header">
            <div className="header-title">
              <h2>Add New</h2>
            </div>
          </div>
          <div className="create-order-container">
            <div className="group-details accordion">
              <div className="accordion-header">
                <h3>Order Details</h3>
              </div>
              <div className="accordion-body horizontal-flex order-fields">
                <div>
                  <label>
                    Customer Number
                    <input
                      autoFocus
                      placeholder="Customer number"
                      type="text"
                      pattern="^\d{0,15}$"
                      onKeyPress={sanitiseNumberInput}
                      required
                      disabled
                      readOnly
                      value={storeNumber}
                      autoComplete="off"
                    />
                  </label>
                </div>
                <div className="search-container">
                  <label>
                    Customer Address
                    <input
                      autoFocus
                      placeholder="Customer address"
                      type="text"
                      required
                      maxLength="128"
                      disabled
                      readOnly
                      value={storeName}
                      autoComplete="off"
                    />
                  </label>
                  <button
                    className="action"
                    type="button"
                    disabled={props.isLoading}
                    onClick={() => setShowSearchModal(true)}
                  >
                    Add
                  </button>
                </div>
                <div>
                  <label>
                    Orderly Order Number
                    <input
                      placeholder="Orderly Order Number"
                      type="text"
                      pattern="^\d{0,15}$"
                      onKeyPress={sanitiseNumberInput}
                      required
                      onChange={e => setOrderNumber(e.target.value)}
                      value={orderNumber}
                      autoComplete="off"
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Customer PO Number
                    <input
                      placeholder="Customer PO Number"
                      type="text"
                      required
                      maxLength="60"
                      onChange={e => setPoNumber(e.target.value)}
                      value={poNumber}
                      autoComplete="off"
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Trip
                    <input
                      placeholder="Trip"
                      type="text"
                      pattern="^\d{0,15}$"
                      onKeyPress={sanitiseNumberInput}
                      onChange={e => setTrip(e.target.value)}
                      value={trip}
                      autoComplete="off"
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="group-details accordion">
              <div className="accordion-header">
                <h3>Order Status</h3>
              </div>
              <div className="accordion-body horizontal-flex order-fields">
                <div>
                  <StatusDropdown
                    value={orderStatus}
                    onChange={handleOrderStatusChange}
                    required
                  />
                </div>
                <div>
                  <label>
                    Created Date
                    <input
                      placeholder="Created Date"
                      type="date"
                      required
                      onChange={e => setCreatedDate(e.target.value)}
                      value={createdDate}
                      autoComplete="off"
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Age
                    <input
                      placeholder="Age"
                      type="text"
                      value={calculateAge(createdDate)}
                      readOnly
                      disabled
                      autoComplete="off"
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Days Delayed
                    <input
                      placeholder="Days Delayed"
                      type="text"
                      value={calculateDaysDelayed(createdDate, orderProgress[5], bespokeDelay, orderProgress[6], parseInt(orderStatus, 10))}
                      readOnly
                      autoComplete="off"
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="group-details accordion progress-state-container">
              <div className="accordion-header">
                <h3>Progress Update</h3>
              </div>
              <div className="accordion-body horizontal-flex order-fields">
                <ProgressState progressStage={progressStage} 
                  numberOfBoxes={7} />
              </div>
              <div /> {/* This is just here for flex centering */}
            </div>
            <div className="group-details accordion">
              <div className="accordion-header">
                <h3>Comments</h3>
              </div>
              <div className="accordion-body horizontal-flex order-fields">
                <div className="comments-container">
                  <label className="comments">
                    Comments
                    <textarea
                      onChange={e => setComments(e.target.value)}
                      autoComplete="off"
                      value={comments}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="group-details accordion">
            <div className="accordion-header">
              <h3>Order Progress</h3>
            </div>
            <div className="accordion-body horizontal-flex order-fields progress-stages">
              <div className="progress-stage">
                <label>
                  1. Picking Process
                  <input
                    type="text"
                    maxLength={20}
                    onChange={e => handleProgressUpdate(0, e.target.value)}
                    value={orderProgress[0]}
                    required
                    autoComplete="off"
                  />
                </label>
              </div>
              <div className="progress-stage">
                <label>
                  2. CI Sent
                  <input
                    type="date"
                    onChange={e => handleProgressUpdate(1, e.target.value)}
                    value={orderProgress[1]}
                    autoComplete="off"
                  />
                </label>
              </div>
              <div className="progress-stage">
                <label>
                  3. HC Requested 4SA
                  <input
                    type="date"
                    onChange={e => handleProgressUpdate(2, e.target.value)}
                    value={orderProgress[2]}
                    autoComplete="off"
                  />
                </label>
              </div>
              <div className="progress-stage">
                <label>
                  4. Legalization
                  <input
                    type="date"
                    onChange={e => handleProgressUpdate(3, e.target.value)}
                    value={orderProgress[3]}
                    autoComplete="off"
                  />  
                </label>
              </div>
              <div className="progress-stage">
                <label>
                  5. Pickup Request Sent 6FF
                  <input
                    type="date"
                    onChange={e => handleProgressUpdate(4, e.target.value)}
                    value={orderProgress[4]}
                    autoComplete="off"
                  />
                </label>
              </div>
              <div className="progress-stage">
                <label>
                  6. Pickup Planned
                  <input
                    type="date"
                    onChange={e => handleProgressUpdate(5, e.target.value)}
                    value={orderProgress[5]}
                    autoComplete="off"
                  />
                </label>
              </div>
              <div className="progress-stage">
                <label>
                  7. Dispatched
                  <input
                    type="date"
                    onChange={e => handleProgressUpdate(6, e.target.value)}
                    value={orderProgress[6]}
                    autoComplete="off"
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="button-container">
            <button
              className="action"
              type="submit"
              disabled={props.isLoading || props.isLoaded}
              onClick={() => setFormSubmitted(true)}
            >
              {props.isLoading
                ? 'Saving...'
                : props.isLoaded
                  ? 'Saved'
                  : 'Save'}
            </button>
            <button
              className="action negative"
              type="button"
              disabled={props.isLoading}
              onClick={clearForm}
            >
              Clear
            </button>
            {props.isLoaded && 
              <p className="submit-result">
                Order saved successfully!    
              </p>
            }
            {props.error && 
              <p className="submit-result">
                {props.error}
              </p>
            }
          </div>
        </form>
      </div>
      {showSearchModal &&
        <div className="modal-blocker">
          <div className="modal">
            <div className="modal-menu">
              <button
                type="button"
                className="action"
                onClick={() => setShowSearchModal(false)}
              >
                  X
              </button>
            </div>
            <div className="modal-content">
              <div className="modal-title">
                <h2>Search Customer</h2>
              </div>
              <div className="modal-body">
                <div className="horizontal-flex">
                  <div>
                    <label>
                      Customer Number
                      <DebouncedInput
                        type="text"
                        pattern="^\d*$"
                        onKeyPress={sanitiseNumberInput}
                        minLength={0}
                        debounceTimeout={300}
                        onChange={(e) => setStoreNumberSearch(e.target.value)}
                        placeholder="Customer Number"
                      />
                    </label>
                  </div>
                  <div>
                    <label>
                      Customer name
                      <DebouncedInput
                        type="text"
                        minLength={0}
                        debounceTimeout={300}
                        onChange={(e) => setStoreNameSearch(e.target.value)}
                        placeholder="Customer Name"
                      />
                    </label>
                  </div>
                </div>
                <div className="table-body">
                  <table className="table-display-mode-3">
                    <thead>
                      <tr>
                        <th>Customer Number</th>
                        <th>Customer Name</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {props.customers.map(x => 
                        <tr key={x.storeNumber}>
                          <td>{x.storeNumber}</td>
                          <td>{x.storeName}</td>
                          <td>
                            <button
                              className="action"
                              type="button"
                              onClick={() => handleCustomerSelect(x)}
                            >
                              Select
                            </button>
                          </td>
                        </tr>,
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="modal-buttons">
                <button
                  type="button"
                  className="action negative"
                  onClick={() => setShowSearchModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.orderTracker.isLoading,
    isLoaded: state.orderTracker.isLoaded,
    error: state.orderTracker.error,
    customers: state.orderTracker.customers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearState: () => dispatch(orderTrackerActions.clearState()),
    saveOrder: (params) => dispatch(orderTrackerActions.saveOrder(params)),
    searchCustomers: (params) => dispatch(orderTrackerActions.searchCustomers(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrder);
