import React from "react";

class SaveCommentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      commentId: 0,
      notes: "",
      licenseeVisible: false,
      isFlag: false,
      licenseeInformationDueBy: undefined,

      showValidation: false
    };

    this.handleCheckInputUpdate = this.handleCheckInputUpdate.bind(this);
    this.handleTextInputUpdate = this.handleTextInputUpdate.bind(this);
  }

  componentDidMount() {
    const { comment } = this.props;

    if (comment) {
      this.setState({
        commentId: comment.commentId,
        notes: comment.notes,
        licenseeVisible: comment.licenseeVisible,
        isFlag: comment.isFlag
      });
    }
  }

  handleTextInputUpdate(e, fieldName) {
    this.setState({ [fieldName]: e.target.value });
  }

  handleCheckInputUpdate(e, fieldName) {
    this.setState({ [fieldName]: true && e.target.checked });
  }

  handleContinue = e => {
    const {
      commentId,
      notes,
      licenseeVisible,
      isFlag,
      licenseeInformationDueBy
    } = this.state;
    const { isLicensee } = this.props;

    if (!notes) {
      this.setState({ showValidation: true });
    } else {
      this.props.continue({
        commentId,
        notes,
        licenseeVisible: licenseeVisible || isLicensee,
        isFlag,
        licenseeInformationDueBy
      });
    }
  };

  render() {
    const {
      notes,
      licenseeVisible,
      isFlag,
      showValidation,
      licenseeInformationDueBy
    } = this.state;
    const { isLicensee, cancel, inProgress } = this.props;
    const {
      handleTextInputUpdate,
      handleCheckInputUpdate,
      handleContinue
    } = this;
    return (
      <div className="modal-blocker">
        <div className="modal">
          <form>
            <div className="modal-menu">
              <button type="button" className="action" onClick={() => cancel()}>
                X
              </button>
            </div>
            <div className="modal-title">
              <h2>Comment</h2>
            </div>
            <div className="modal-body">
              <label>
                Comment
                <textarea
                  autoFocus
                  type="text"
                  required
                  className={showValidation && !notes ? "invalid" : ""}
                  value={notes}
                  onChange={e => handleTextInputUpdate(e, "notes")}
                />
              </label>
            </div>
            {!isLicensee && (
              <div className="modal-body">
                <label>
                  Retailer Visible
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      onChange={e =>
                        handleCheckInputUpdate(e, "licenseeVisible")
                      }
                      checked={licenseeVisible}
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
              </div>
            )}
            {!isLicensee && (
              <React.Fragment>
                <div className="modal-body">
                  <label>
                    Licensee Information Due By
                    <input
                      autoFocus
                      type="date"
                      required
                      value={licenseeInformationDueBy}
                      onChange={e =>
                        handleTextInputUpdate(e, "licenseeInformationDueBy")
                      }
                    />
                  </label>
                </div>
              </React.Fragment>
            )}
            <div className="modal-buttons">
              <button
                type="button"
                className="action"
                disabled={inProgress}
                onClick={() => handleContinue()}
              >
                Save
              </button>
              <button
                type="button"
                className="action negative"
                disabled={inProgress}
                onClick={() => cancel()}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default SaveCommentModal;
