import React from "react";

class SaveDocumentEditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documentId: 0,
      categoryId: "",
      description: "",
      fileName: undefined,
      fileType: undefined,
      licenseeVisible: false,

      showValidation: false
    };

    this.handleCheckInputUpdate = this.handleCheckInputUpdate.bind(this);
    this.handleTextInputUpdate = this.handleTextInputUpdate.bind(this);
  }

  componentDidMount() {
    const {
      documentId,
      categoryId,
      description,
      fileName,
      fileType,
      licenseeVisible
    } = this.props.document;

    this.setState({
      documentId,
      categoryId,
      description,
      fileName,
      fileType,
      licenseeVisible
    });
  }

  handleTextInputUpdate(e, fieldName) {
    this.setState({ [fieldName]: e.target.value });
  }

  handleCheckInputUpdate(e, fieldName) {
    this.setState({ [fieldName]: true && e.target.checked });
  }

  handleContinue = e => {
    const {
      documentId,
      categoryId,
      description,
      fileName,
      fileType,
      licenseeVisible
    } = this.state;
    const { isLicensee } = this.props;

    if (!categoryId || !description || !fileName) {
      this.setState({ showValidation: true });
    } else {
      this.props.continue({
        documentId,
        categoryId,
        description,
        fileName,
        fileType,
        licenseeVisible: licenseeVisible || isLicensee
      });
    }
  };

  render() {
    const {
      description,
      licenseeVisible,
      fileName,
      categoryId,
      showValidation
    } = this.state;
    const { isLicensee, cancel, documentCategories, inProgress } = this.props;
    const {
      handleTextInputUpdate,
      handleCheckInputUpdate,
      handleContinue
    } = this;
    return (
      <div className="modal-blocker">
        <div className="modal">
          <form>
            <div className="modal-menu">
              <button type="button" className="action" onClick={() => cancel()}>
                X
              </button>
            </div>
            <div className="modal-title">
              <h2>Document - {fileName}</h2>
            </div>
            <div className="modal-body">
              <label>
                Document Category
                <select
                  autoFocus
                  type="text"
                  required
                  className={showValidation && !categoryId ? "invalid" : ""}
                  value={categoryId}
                  onChange={e => handleTextInputUpdate(e, "categoryId")}
                >
                  <option value="">Please Select</option>
                  {documentCategories.map(d => (
                    <option key={d.key} value={d.key}>
                      {d.value}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                Description
                <textarea
                  autoFocus
                  type="text"
                  required
                  className={showValidation && !description ? "invalid" : ""}
                  value={description}
                  onChange={e => handleTextInputUpdate(e, "description")}
                />
              </label>
            </div>
            {!isLicensee && (
              <div className="modal-body">
                <label>
                  Retailer Visible
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      onChange={e =>
                        handleCheckInputUpdate(e, "licenseeVisible")
                      }
                      checked={licenseeVisible}
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
              </div>
            )}
            <div className="modal-buttons">
              <button
                type="button"
                className="action"
                disabled={inProgress}
                onClick={() => handleContinue()}
              >
                Save
              </button>
              <button
                type="button"
                className="action negative"
                disabled={inProgress}
                onClick={() => cancel()}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default SaveDocumentEditModal;
