import React from 'react';
import Pagination from '../../../shared/Pagination';
import ExtendedFields from './ExtendedFields';
import { Prompt } from 'react-router';

class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 30,
      showAdditionalDetails: false,
      dirtyApp: [],
      triggerSave: false
    };

    this.recordIsDirty  = this.recordIsDirty.bind(this);
    this.saveExtendedDetails  = this.saveExtendedDetails.bind(this);
  }

  getExpirationIcon(expiringSoon, daysUntilExpiry, extensionRequested, active) {
    if (extensionRequested) {
      if (active) {
        return (
          <div
            className="icon icon-siteid-expiring-white"
            title="An extension has been requested and is pending review."
          />
        );
      }
      return (
        <div
          className="icon icon-siteid-expiring-green"
          title="An extension has been requested and is pending review."
        />
      );
    }

    if (expiringSoon) {
      if (active) {
        return (
          <div
            className="icon icon-siteid-expiring-white"
            title={`${daysUntilExpiry} days until expiry.`}
          />
        );
      }

      return (
        <div
          className="icon icon-siteid-expiring"
          title={`${daysUntilExpiry} days until expiry.`}
        />
      );
    }

    return null;
  }

  formatStatus(statusId, status, requested, created) {

      return status;
  }

  recordIsDirty(applicationId){
    const {dirtyApp} = this.state;

      if(dirtyApp.indexOf(applicationId) === -1){
        this.setState({dirtyApp: [...dirtyApp, applicationId]});
      }
  }

  saveExtendedDetails(){
    this.setState({dirtyApp: [], 
      triggerSave: true
    });

    setTimeout(() => this.setState({triggerSave: false}), 1000)
  }

  render() {
    const {
      doSearch,
      navigateItemDetails,
      filter,
      handleFieldUpdate,
      data,
      page,
      doPaging,
      selectedRecords,
      toggleApplication,
      sortOrder,
      panMap,
      isReadOnly
    } = this.props;

    const { pageSize, showAdditionalDetails, dirtyApp, triggerSave } = this.state;

    const {recordIsDirty } = this;

    const rowCount = data && data.length ? data.length : 0;

    const records = data || [];

    if (sortOrder.indexOf('_asc') > -1) {
      if (sortOrder.indexOf('created') > -1) {
        records.sort((a, b) => a.applicationId - b.applicationId);
      } else if (sortOrder.indexOf('modified') > -1) {
        records.sort((a, b) => new Date(a.modified) - new Date(b.modified));
      } else if (sortOrder.indexOf('name') > -1) {
        records.sort((a, b) => {
          const text1 = (a.siteName || '').toLowerCase();
          const text2 = (b.siteName || '').toLowerCase();

          if (text1 > text2) {
            return 1;
          }

          if (text1 < text2) {
            return -1;
          }

          return 0;
        });
      }
    } else if (sortOrder.indexOf('created') > -1) {
      records.sort((a, b) => b.applicationId - a.applicationId);
    } else if (sortOrder.indexOf('modified') > -1) {
      records.sort((a, b) => new Date(b.modified) - new Date(a.modified));
    } else if (sortOrder.indexOf('name') > -1) {
      records.sort((a, b) => {
        const text1 = (a.siteName || '').toLowerCase();
        const text2 = (b.siteName || '').toLowerCase();

        if (text1 > text2) {
          return -1;
        }

        if (text1 < text2) {
          return 1;
        }

        return 0;
      });
    }

    const pinned = records.filter((item) => (selectedRecords.indexOf(item.applicationId) !== -1));
    const unpinned = records.filter((item) => (selectedRecords.indexOf(item.applicationId) === -1));

    return (
      <div className="table">
        <div className="table-header">
          <div className="search-container">
            <input
              className="search"
              placeholder="Search..."
              value={filter}
              disabled={dirtyApp.length > 0}
              onChange={(e) => handleFieldUpdate(e, 'filter')}
              onKeyDown={(e) => (e.key === 'Enter' ? doSearch() : null)}
            />
          </div>
          <h2>Applications</h2>
          <Pagination
            rowCount={rowCount}
            page={page}
            pageSize={pageSize}
            doPaging={doPaging}
            preventNav= {dirtyApp.length > 0}
          />
        </div>
        <div className="table-scroll">
          <table>
            <thead>
              <tr>
                <th> </th>
                <th> </th>
                <th> </th>
                {showAdditionalDetails? null:<th className='sticky-column'>Company</th>}
                <th className='sticky-column'>Site Name</th>
                <th className='sticky-column'>Status</th>
                      {showAdditionalDetails ?
<>
<th>Forecast Opening</th>
<th>Head Of Terms Agreed</th>
<th>Lease Signed/Site Purchased</th>
<th>Planning Required</th>
<th>Planning Submitted</th>
<th>Planning Granted</th>
<th>Planned Opening Date</th>
<th>Planned Schematic Date</th>
</> : null

                      }
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {records
                && pinned.concat(unpinned)
                  .slice((page - 1) * pageSize, page * pageSize)
                  .map((item) => (
                    <tr
                      key={item.applicationId}
                      className={`${
                        selectedRecords.length > 0
                          && selectedRecords.indexOf(item.applicationId) > -1
                          ? 'active'
                          : ''
                      }`}
                    >
                      <td>
                        <button
                          type="button"
                          className="multi-select"
                          onClick={() => toggleApplication(item.applicationId)}
                        >
                          +
                        </button>
                      </td>
                      <td>
                        {item.hasFlags ? (
                          <div className={`icon icon-siteid-flag${selectedRecords.length > 0
                          && selectedRecords.indexOf(item.applicationId) > -1 ? '-white' : ''}`}
                          />
                        ) : null}
                      </td>
                      <td >
                        {this.getExpirationIcon(
                          item.expiringSoon,
                          item.daysUntilExpiry,
                          item.extensionRequested,
                          selectedRecords.length > 0
                          && selectedRecords.indexOf(item.applicationId) > -1,
                        )}
                      </td>
                      {showAdditionalDetails? null :<th  className='sticky-column'>{item.company}</th>}
                      <th className='sticky-column'>
                        <span 
                          onClick={() => panMap(item.latitude, item.longitude)} 
                          className="site-name-link">{item.siteName}</span>
                      </th>
                      <th className='sticky-column'>
                        {this.formatStatus(item.statusId, item.status, item.projectRequested, item.projectCreated)}
                      </th>
                      {showAdditionalDetails ?
                        <ExtendedFields applicationId={item.applicationId} isDirtyFunc={recordIsDirty} triggerSave={triggerSave}/> : null

                      }
                      <td>
                        <button
                          type="button"
                          onClick={() => navigateItemDetails(item.applicationId)}
                          disabled= {dirtyApp.length > 0}
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
        <div className="table-footer">
          <div />
          <Pagination
            rowCount={rowCount}
            page={page}
            pageSize={pageSize}
            doPaging={doPaging}
            preventNav= {dirtyApp.length > 0}
          />
        </div>
        <Prompt
          when={dirtyApp.length > 0}
          message="Changes made haven't been saved. Are you sure you want to leave?"
        />
      </div>
    );
  }
}

export default Table;
