import React from 'react';
import { connect } from 'react-redux';
import apacOrderTrackerActions from '../../../actions/apacOrderTrackerActions';
import { navigationActions } from '../../../actions/navigationActions';
import './AotOrders.scss';
import Pagination from '../../shared/Pagination';
import qs from 'query-string';
import MultiSelect from '../../siteID/siteIDApplications/list/MultiSelect';

class AotOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      filterOrders: {
        shipmentTypeId: '',
        marketId: '',
        customerNumber: '',
        trackingNumber: '',
        sbuxOrderNumber: '',
        orderTypeId: '',
        orderStatusIds: [],
        orderDate: '',
        periodFilter: '',
        itemNumber:'',
        supplierName: '',
        masterBill: '',
        consolidatedShipment: false,
        selectedMonths: [],
        selectedYears: [],
      },
      page: 1,
      pageSize: 25,
      displayMode: 1,
      isAdmin: false,
      isClosed: false,
    };

    this.setDisplayMode = this.setDisplayMode.bind(this);
    this.handleFilterInputUpdate = this.handleFilterInputUpdate.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleFilterClear = this.handleFilterClear.bind(this);
    this.doToggleMulti = this.doToggleMulti.bind(this);
    this.handleExportSummary = this.handleExportSummary.bind(this);
    this.handleExportResults = this.handleExportResults.bind(this);
    this.doPaging = this.doPaging.bind(this);
    this.initialiseState = this.initialiseState.bind(this);
    this.doFilter = this.doFilter.bind(this);
    this.doToggleMonthsMulti = this.doToggleMonthsMulti.bind(this);
    this.doToggleYearsMulti = this.doToggleYearsMulti.bind(this);
  }

  componentDidMount() {
    const newState = this.initialiseState();

    if (window.location.toString().toLocaleLowerCase().indexOf('admin') > -1) {
      newState.isAdmin = true;
    }

    if (window.location.toString().toLocaleLowerCase().indexOf('close') > -1) {
      newState.isClosed = true;
    }

    const { filterOrders, page, pageSize, isAdmin, isClosed } = newState;

    this.props.getOrders({ ...filterOrders, page, pageSize, isAdmin, isClosed });

    this.setState(newState);
  }

  componentDidUpdate() {
    const windowLocation = window.location.toString().toLocaleLowerCase();
  
    if (this.state.isAdmin != windowLocation.indexOf('admin') > -1 ||
      this.state.isClosed != windowLocation.indexOf('close') > -1) {
        
      const newState = this.initialiseState();

      if (window.location.toString().toLocaleLowerCase().indexOf('admin') > -1) {
        newState.isAdmin = true;
      }

      if (window.location.toString().toLocaleLowerCase().indexOf('close') > -1) {
        newState.isClosed = true;
      }

      const { filterOrders, page, pageSize, isAdmin, isClosed } = newState;

      this.props.getOrders({ ...filterOrders, page, pageSize, isAdmin, isClosed });

      this.setState(newState);
    }
  }

  initialiseState() {
    const {
      location,
    } = this.props;

    const {
      filter,
    } = this.state;

    const { search } = location;

    const filterOrders = {
      shipmentTypeId: '',
      marketId: '',
      customerNumber: '',
      trackingNumber: '',
      sbuxOrderNumber: '',
      orderTypeId: '',
      orderStatusIds: [],
      selectedMonths: [],
      selectedYears: [],
      itemNumber:'',
      supplierName: '',
      masterBill: '',
      consolidatedShipment: false,
    };
    
    let newState = {
      filterOrders : filterOrders,
      page: 1,
      pageSize: 25,
      displayMode: 1,
      isAdmin: false,
      isClosed: false,
    };

    if (filter != '') {
      const parsed = qs.parse(search.toLowerCase());
 
      const newFilterOrders = {
        shipmentTypeId: parsed.shipmenttype ? parsed.shipmenttype : '' ,
        marketId: parsed.market ? parsed.market : '' ,
        customerNumber: parsed.customernumber ? parsed.customernumber : '' ,
        trackingNumber: parsed.trackingnumber ? parsed.trackingnumber : '' ,
        sbuxOrderNumber: parsed.sbuxordernumber ? parsed.sbuxordernumber : '' ,
        orderTypeId: parsed.ordertype ? parsed.ordertype : '' ,
        orderStatusIds: parsed.orderstatuses ? parsed.orderstatuses : '',
        itemNumber: parsed.itemNumber ? parsed.itemNumber : '',
        supplierName: parsed.supplierName ? parsed.supplierName : '',
        masterBill: parsed.masterBill ? parsed.masterBill : '',
        consolidatedShipment: parsed.consolidatedShipment ? parsed.consolidatedShipment: false,
        selectedMonths: parsed.selectedMonths ? parsed.selectedMonths : [],
        selectedYears: parsed.selectedYears ? parsed.selectedYears: [],

      };

      newState = {
        ...newState,
        filterOrders: newFilterOrders,
        page: parsed.page ? parseInt(parsed.page, 10) : 1,
        pageSize:25,
      };
    }

    return newState;
  }

  setDisplayMode(mode){
    this.setState({ displayMode: mode });
  }

  handleFilterInputUpdate(e, fieldName) {
    this.setState({ filterOrders: { ...this.state.filterOrders, [fieldName]: e.target.value } });
  }
  handleCheckInputUpdate(e, fieldName) {
    this.setState({ filterOrders: { ...this.state.filterOrders, [fieldName]: e.target.checked },
    });
  }

  doFilter(){
    const oldState = this.state;

    this.handleFilter(oldState);
  }

  handleFilter(newState){
    
    const { pathname } = this.props.location;
    const {
      filterOrders,
      pageSize,
      page,
      isAdmin,
      isClosed,
    } = newState;
    
    const { replace } = this.props;

    const filterString = `shipmentType=${filterOrders.shipmentTypeId}&market=${filterOrders.marketId}`
      + `&customerNumber=${filterOrders.customerNumber}&trackingNumber=${filterOrders.trackingNumber}&sbuxOrderNumber=${filterOrders.sbuxOrderNumber}`
      + `&orderType=${filterOrders.orderTypeId}&orderStatuses=${filterOrders.orderStatusIds}`
      + `&itemNumber=${filterOrders.itemNumber}&supplierName=${filterOrders.supplierName}`
      + `&masterBill=${filterOrders.masterBill}&consolidatedShipment=${filterOrders.consolidatedShipment}` 
      + `&selectedMonths=${filterOrders.selectedMonths}&selectedYears=${filterOrders.selectedYears}`;
      
    replace(`${pathname}?page=${page}&${filterString}`);

    const joinedStatusIds = filterOrders.orderStatusIds && filterOrders.orderStatusIds?.join(',');

    this.props.getOrders( { ...filterOrders, orderStatusIds: joinedStatusIds, isAdmin, isClosed, page, pageSize } );

    this.setState({ ...newState });
  }

  handleFilterClear(){
    const { isAdmin, isClosed, pageSize } = this.state;

    const filterOrders = {
      shipmentTypeId: '',
      marketId: '',
      customerNumber: '',
      trackingNumber: '',
      sbuxOrderNumber: '',
      orderTypeId: '',
      orderStatusIds: [],
      itemNumber:'',
      supplierName: '',
      masterBill: '',
      consolidatedShipment: false,
      selectedMonths: [],
      selectedYears: [],
    };

    const newState = {
      filterOrders,
      page: 1,
      pageSize,
      isAdmin,
      isClosed,
    };

    this.handleFilter(newState);  
  }

  doPaging(page) {
    const oldState = this.state;

    const newState = {
      ...oldState,
      page: page,
    };

    this.handleFilter(newState);
  }

  handleExportSummary(){
    const { isAdmin, isClosed, filterOrders } = this.state;

    this.props.exportOrders( { ...filterOrders, isAdmin, isClosed, summary:true } );
  }

  handleExportResults(){
    const { isAdmin, isClosed, filterOrders } = this.state;

    this.props.exportOrders( { ...filterOrders, isAdmin, isClosed, summary:false } );
  }
  
  handleViewClosedOrders(){
    const { isAdmin } = this.state;

    if (isAdmin) {
      this.props.navigate('/module/220/AOT-Admin-ClosedOrders');
    } else {
      this.props.navigate('/module/230/AOT-ClosedOrders');
    }
  }
  
  handleViewInProgressSummary(){
    this.props.navigate('/module/220/AOT-Admin-InProgressSummary');
  }

  doToggleMulti(selectedValues, fieldName) {
    this.setState(prevState => ({
      filterOrders: { ...prevState.filterOrders, [fieldName]: selectedValues } }));
  }

  doToggleMonthsMulti (selectedValues) {
    this.setState({ filterOrders: { ...this.state.filterOrders, selectedMonths: selectedValues } });
  }

  doToggleYearsMulti(selectedValues)  {
    this.setState({ filterOrders: { ...this.state.filterOrders, selectedYears: selectedValues } });

  }

  render (){
    const { orders, isLoading, navigate, orderStatuses, orderTypes, mbus, shipmentTypes, rowCount } = this.props;
    const { displayMode, filterOrders, isAdmin, isClosed, page, pageSize } = this.state;
    const { setDisplayMode, doToggleMonthsMulti, doToggleYearsMulti } = this;

    const months = [
      { key: 1, value: 'January' },
      { key: 2, value: 'February' },
      { key: 3, value: 'March' },
      { key: 4, value: 'April' },
      { key: 5, value: 'May' },
      { key: 6, value: 'June' },
      { key: 7, value: 'July' },
      { key: 8, value: 'August' },
      { key: 9, value: 'September' },
      { key: 10, value: 'October' },
      { key: 11, value: 'November' },
      { key: 12, value: 'December' },
    ];
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 1;

    const availableTimePeriods = [
      { key: currentYear.toString(), value: currentYear.toString() }, { key: startYear.toString(), value: startYear.toString() },
    ];

    return(
      <div className='page'>
        <div className="floating-panel form-container">
          <div className="form-header apac">
            <div className="header-title">
              <div className="icon icon-table"></div>
              <h2>{isClosed ? 'Closed Order Archive' : 'In Progress Orders'}</h2>
            </div>

            <div className="search-order-container">
              {isAdmin ?
                <div className="group-details accordion download-container"
                  style={{ marginRight: '18px' } }
                >
                  <div className="accordion-header">
                    <h3>View Summary Data</h3>
                  </div>
                  <div className="accordion-body horizontal-flex order-fields">
                    <div>
                      <button
                        className="action"
                        onClick={() => this.handleViewInProgressSummary()}
                        type="button"
                        title="Export"
                        disabled={isLoading}
                      >
                        View
                      </button>
                    </div>
                  </div>
                </div> : null}
              <div className="group-details accordion download-container"
                style={{ marginRight: '18px' } }>
                <div className="accordion-header">
                  <h3>Download Results</h3>
                </div>
                <div className="accordion-body horizontal-flex order-fields">
                  <div>
                    <button
                      className="action export"
                      onClick={() => this.handleExportResults()}
                      type="button"
                      title="Export"
                      disabled={isLoading}
                    >
                      <div
                        className="icon icon-download-light"
                        alt="Export"
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div className="group-details accordion download-container summary"
                style={{ marginRight: '18px' } }
              >
                <div className="accordion-header">
                  <h3>Download Summary</h3>
                </div>
                <div className="accordion-body horizontal-flex order-fields">
                  <div>
                    <button
                      className="action export"
                      type="button"
                      title="Export"
                      disabled={isLoading}
                      onClick={() => this.handleExportSummary()}
                    >
                      <div
                        className="icon icon-download-light"
                        alt="Export"
                      />
                    </button>
                  </div>
                </div>
              </div>
              {!isClosed ?
                <div className="group-details accordion download-container"
                  style={{ marginRight: '18px' } }>
                  <div className="accordion-header">
                    <h3>View Closed Orders</h3>
                  </div>
                  <div className="accordion-body horizontal-flex order-fields">
                    <div>
                      <button
                        className="action"
                        onClick={() => this.handleViewClosedOrders()}
                        type="button"
                        title="Export"
                        disabled={isLoading}
                      >
                    View
                      </button>
                    </div>
                  </div>
                </div> : null}
            </div>

            
          </div>
        </div>

        <div className='floating-panel form-container'>
          <div> 
            <div className='form-body'>
              <label>
                    Shipment Type
                <select
                  value={filterOrders.shipmentTypeId}
                  onChange={e => this.handleFilterInputUpdate(e, 'shipmentTypeId')}
                >
                  <option value=''>All Shipment Types</option>
                  {shipmentTypes && shipmentTypes.length && shipmentTypes.map(m => 
                    <option key={m.key}
                      value={m.key}>{m.value}</option>,
                  )
                  }
                </select>
              </label>
              {isAdmin &&
                <>
                  <label>
                    MBU
                    <select
                      value={filterOrders.marketId}
                      onChange={e => this.handleFilterInputUpdate(e, 'marketId')}
                    >
                      <option value=''>Select an MBU</option>
                      {mbus && mbus.length && mbus.map(m => 
                        <option key={m.key}
                          value={m.key}>{m.value}</option>,
                      )
                      }
                    </select>
                  </label>
                  <label>
                    Customer Number
                    <input
                      type="text"
                      placeholder="Customer number"
                      onChange={e => this.handleFilterInputUpdate(e, 'customerNumber')}
                      value={filterOrders.customerNumber}
                    />
                  </label>
                </>}
              <label>
                  Tracking Number
                <input
                  type="text"
                  placeholder="Tracking number"
                  onChange={e => this.handleFilterInputUpdate(e, 'trackingNumber')}
                  value={filterOrders.trackingNumber}
                />
              </label>
              <label>
                  Orderly Order Number
                <input
                  type="text"
                  placeholder="Orderly order number"
                  onChange={e => this.handleFilterInputUpdate(e, 'sbuxOrderNumber')}
                  value={filterOrders.sbuxOrderNumber}
                />
              </label>
              <label>
                  Order Type
                <select
                  value={filterOrders.orderTypeId}
                  onChange={e => this.handleFilterInputUpdate(e, 'orderTypeId')}
                >
                  <option value=''>Select an order type</option>
                  {orderTypes && orderTypes.length && orderTypes.map(o => 
                    <option key={o.key}
                      value={o.key}>{o.value}</option>,
                  )
                  }
                </select>
              </label>
              <label>
                  Item Number
                <input
                  type="text"
                  placeholder="Item number"
                  onChange={e => this.handleFilterInputUpdate(e, 'itemNumber')}
                  value={filterOrders.itemNumber}
                />
              </label>
              <label>
                  Supplier Name
                <input
                  type="text"
                  placeholder="Supplier Name"
                  onChange={e => this.handleFilterInputUpdate(e, 'supplierName')}
                  value={filterOrders.supplierName}
                />
              </label>
             
              <label>
                  MasterBill
                <input
                  type="text"
                  placeholder="MasterBill"
                  onChange={e => this.handleFilterInputUpdate(e, 'masterBill')}
                  value={filterOrders.masterBill}
                />
              </label>
              <label>
                  Consolidated Shipment
                <div className="toggle-wrapper">
                  <input
                    type="checkbox"
                    className='toggle'
                    onChange={e => this.handleCheckInputUpdate(e, 'consolidatedShipment')}
                    value={filterOrders.consolidatedShipment}
                  />
                  <div className="toggle-icon"></div>
                </div>
              </label>
              {!isClosed && orderStatuses && orderStatuses.length > 0 && (
                <MultiSelect
                  title="Order Statuses"
                  selectedValues={filterOrders.orderStatusIds}
                  valueChanged={this.doToggleMulti}
                  values={orderStatuses}
                  valueName="orderStatusIds"
                />
              )}
              <MultiSelect
                title="Months"
                selectedValues={filterOrders?.selectedMonths ?? []}
                valueChanged={doToggleMonthsMulti}
                values={months}
                valueName="selectedMonths"
              />
              <MultiSelect
                title="Years"
                selectedValues={filterOrders?.selectedYears ?? []}
                valueChanged={doToggleYearsMulti}
                values={availableTimePeriods}
                valueName="selectedYears"
              />   
            </div>
          </div>

          <div
            style={{ paddingBottom:'18px', display:'flex', justifyContent:'flex-end' }}
          >
            <button onClick={() => this.doFilter()}
              type='button'
              style={{ marginRight:'18px' }}
              className='action'>
                    Filter
            </button>

                
            <button onClick={() => this.handleFilterClear()}
              type='button'
              className='action negative'>
                    Clear Filter
            </button>
          </div>
        </div>
        
        
        
        <div className={'customer-ordertracker-container in-progress'}>
          <div className="table-container">
            <div className="table-header">
              <div className="display-options">
            Table Size
                <button
                  type="button"
                  className="icon-display-mode-1"
                  disabled={displayMode === 1}
                  onClick={() => setDisplayMode(1)}
                />
                <button
                  type="button"
                  className="icon-display-mode-2"
                  disabled={displayMode === 2}
                  onClick={() => setDisplayMode(2)}
                />
                <button
                  type="button"
                  className="icon-display-mode-3"
                  disabled={displayMode === 3}
                  onClick={() => setDisplayMode(3)}
                />
              </div>
              <h2>
            Search Results
              </h2>
              <div className="pagination-controls">
                <Pagination
                  rowCount={rowCount}
                  page={page}
                  pageSize={pageSize}
                  doPaging={this.doPaging}
                />
              </div>
            </div>
            <div className="table-body">
              <table className={`table-display-mode-${displayMode}`}>
                <thead>
                  <tr>
                    <th>MBU</th>
                    <th>Customer Number</th>
                    <th>Tracking Number</th>
                    <th>Orderly Order Number</th>
                    <th>Order Type</th>
                    <th>Order Status</th>
                    <th>Days Delayed</th>
                    <th>Order Date</th>
                    <th>View Details</th>
                  </tr>
                </thead>
                <tbody>
                  {orders && orders.length === 0 &&
                <tr>
                  <td
                    colSpan={9}
                    className="no-records"
                    style={{ textAlign: 'center' } }
                  >
                    {isLoading ? 'Loading...' : 'No orders found.'}
                  </td>
                </tr>
                  }
                  {orders && orders.length > 0 && orders.map(o => 
                    <tr key={o.id}>
                      <td>{o.mbu}</td>
                      <td>{o.customerNumber}</td>
                      <td>{o.trackingNumber}</td>
                      <td>{o.sbOrderNumber}</td>
                      <td>{orderTypes.find(m => m.key == o.orderTypeId)?.value ?? o.orderTypeId}</td>
                      <td>{orderStatuses.find(m => m.key == o.orderStatusId)?.value ?? o.orderStatusId}</td>
                      <td>{o.daysDelayed ?? 0}</td>
                      <td>{o.orderDate && new Date(o.orderDate).toLocaleDateString()}</td>
                      <td>
                        {o.trackingNumber ?
                          <button
                            className="link"
                            type="button"
                            onClick={() => navigate(`/module/233/AOT-ShippingDetails/${o.trackingNumber}`)}>
                            View Details
                          </button> : null}
                      </td>
                    </tr>,
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.apacOrderTracker.isLoading,
    isLoaded: state.apacOrderTracker.isLoaded,
    orders: state.apacOrderTracker.orders,
    error: state.apacOrderTracker.error,
    shipmentTypes: state.apacOrderTracker.shipmentTypes,
    orderStatuses: state.apacOrderTracker.orderStatuses,
    orderTypes: state.apacOrderTracker.orderTypes,
    mbus: state.apacOrderTracker.mbus,
    pageNumber: state.apacOrderTracker.pageNumber,
    pageCount: Math.ceil(state.apacOrderTracker.totalRecords / state.apacOrderTracker.pageSize),
    rowCount: state.apacOrderTracker.totalRecords,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getOrders: filter => dispatch(apacOrderTrackerActions.getApacOrders(filter)),
  replace: path => dispatch(navigationActions.replaceNavigation(path)),
  exportOrders: filter => dispatch(apacOrderTrackerActions.exportApacOrders(filter)),
  clearState: () => dispatch(apacOrderTrackerActions.clearState()),
  navigate: (path) => dispatch(navigationActions.pushNavigation(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AotOrders);
