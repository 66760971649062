import {
  SITEID_APPLICATIONDETAIL_REQUEST,
  SITEID_APPLICATIONDETAIL_SUCCESS,
  SITEID_APPLICATIONDETAIL_FAILURE,
  SITEID_SAVEAPPLICATIONDETAIL_REQUEST,
  SITEID_SAVEAPPLICATIONDETAIL_SUCCESS,
  SITEID_SAVEAPPLICATIONDETAIL_FAILURE,
  SITEID_SAVECOMMENT_REQUEST,
  SITEID_SAVECOMMENT_SUCCESS,
  SITEID_SAVECOMMENT_FAILURE,
  SITEID_DELETECOMMENT_REQUEST,
  SITEID_DELETECOMMENT_SUCCESS,
  SITEID_DELETECOMMENT_FAILURE,
  SITEID_UPDATEMAP_REQUEST,
  SITEID_UPDATEMAP_SUCCESS,
  SITEID_UPDATEMAP_FAILURE,
  SITEID_APPLICATIONS_REQUEST,
  SITEID_DOCUMENTSAVE_REQUEST,
  SITEID_DOCUMENTSAVE_SUCCESS,
  SITEID_DOCUMENTSAVE_FAILURE,
  SITEID_DOCUMENTDELETE_REQUEST,
  SITEID_DOCUMENTDELETE_SUCCESS,
  SITEID_DOCUMENTDELETE_FAILURE,
  SITEID_EXTEND_REQUEST,
  SITEID_EXTEND_SUCCESS,
  SITEID_EXTEND_FAILURE,
  SITEID_PROGRESSSTATUS_REQUEST,
  SITEID_PROGRESSSTATUS_SUCCESS,
  SITEID_PROGRESSSTATUS_FAILURE,
  SITEID_BULKPROGRESSSTATUS_SUCCESS,
} from '../actions/actionTypes';
import initialState from './initialState';

export const siteIDApplicationDetailReducer = (
  state = initialState.siteIDApplicationDetailState,
  action = {},
) => {
  switch (action.type) {
  case SITEID_APPLICATIONS_REQUEST: {
    return initialState.siteIDApplicationDetailState;
  }

  case SITEID_APPLICATIONDETAIL_REQUEST: {
    return {
      // ...state,
      isFetching: true,
      isLoaded: false,
      errorMessage: '',
      data: {},
    };
  }
  case SITEID_APPLICATIONDETAIL_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      isLoaded: true,
      data: action.payload.application,
      assigneeTypes: action.payload.assigneeTypes,
      companies: action.payload.companies,
      companyUsers: action.payload.companyUsers,
      counties: action.payload.counties,
      documentCategories: action.payload.documentCategories,
      defaultResponses: action.payload.defaultResponses,
      dtmps: action.payload.dtmps,
      priorityAreas: action.payload.priorityAreas,
      equityStores: action.payload.equityStores,
      extensionOutcomes: action.payload.extensionOutcomes,
      formats: action.payload.formats,
      leases: action.payload.leases,
      nearApplications: action.payload.nearApplications,
      companyTerritory: action.payload.companyTerritory,
      planningGrantTypes: action.payload.planningGrantTypes,
      primaryBusinessGenerators: action.payload.primaryBusinessGenerators,
      sitePositions: action.payload.sitePositions,
      siteTypes: action.payload.siteTypes,
      statuses: action.payload.statuses,
      tenureTypes: action.payload.tenureTypes,
      projectTypes: action.payload.projectTypes,
      isLicensee: action.payload.isLicensee,
      isReadOnly: action.payload.isReadOnly,
      apiKey: action.payload.apiKey,
      maxExtensions: action.payload.maxExtensions,
      driveThroughEnabled: action.payload.enableDriveThroughPlan,
      errorMessage: '',
    };
  }
  case SITEID_APPLICATIONDETAIL_FAILURE: {
    return {
      isFetching: false,
      isLoaded: false,
      data: {},
      errorMessage: action.payload.message,
    };
  }
  case SITEID_UPDATEMAP_REQUEST: {
    return {
      ...state,
      dtmps: [],
      errorMessage: '',
    };
  }
  case SITEID_UPDATEMAP_SUCCESS: {
    return {
      ...state,
      dtmps: action.payload.dtmps,
      equityStores: action.payload.equityStores,
      nearApplications: action.payload.nearApplications,
      errorMessage: '',
    };
  }
  case SITEID_BULKPROGRESSSTATUS_SUCCESS: {
    let newNearApplicationData = [];

    if (state.nearApplications) {
      newNearApplicationData = state.nearApplications.map((m) => {
        const newApplication = action.payload.changes.find(
          (c) => c && c.applicationId === m.applicationId,
        );

        if (!newApplication) return m;

        return {
          ...m,
          statusId: newApplication.toStatusId,
          status: newApplication.toStatus,
        };
      });
    }
    return {
      ...state,
      nearApplications: [...newNearApplicationData],
    };
  }
  case SITEID_UPDATEMAP_FAILURE: {
    return {
      ...state,
      errorMessage: action.payload.message,
    };
  }
  case SITEID_SAVEAPPLICATIONDETAIL_REQUEST: {
    return {
      ...state,
      isChanging: true,
      isChanged: false,
      isSaved: false,
      errorMessage: '',
    };
  }
  case SITEID_SAVEAPPLICATIONDETAIL_SUCCESS: {
    return {
      ...state,
      isChanging: false,
      isChanged: true,
      isSaved: true,
      data: action.payload,
      errorMessage: '',
    };
  }
  case SITEID_SAVEAPPLICATIONDETAIL_FAILURE: {
    return {
      ...state,
      isChanging: false,
      isChanged: false,
      isSaved: false,
      errorMessage: action.payload.message,
    };
  }
  case SITEID_SAVECOMMENT_REQUEST: {
    return {
      ...state,
      isChanging: true,
      isChanged: false,
      errorMessage: '',
    };
  }
  case SITEID_SAVECOMMENT_SUCCESS: {
    const newCommentData = state.data.comment.filter(
      (c) => c.commentId !== action.payload.commentId,
    );

    newCommentData.push(action.payload);

    return {
      ...state,
      isChanging: false,
      isChanged: true,
      data: { ...state.data, comment: newCommentData },
      errorMessage: '',
    };
  }
  case SITEID_SAVECOMMENT_FAILURE: {
    return {
      ...state,
      isChanging: false,
      isChanged: false,
      errorMessage: action.payload.message,
    };
  }
  case SITEID_DOCUMENTSAVE_REQUEST: {
    return {
      ...state,
      isChanging: true,
      isChanged: false,
      errorMessage: '',
    };
  }
  case SITEID_DOCUMENTSAVE_SUCCESS: {
    const newDocumentData = state.data.document.filter(
      (c) => c.documentId !== action.payload.documentId,
    );

    newDocumentData.push(action.payload);

    return {
      ...state,
      isChanging: false,
      isChanged: true,
      data: {
        ...state.data,
        document: newDocumentData,
        proofOfDeveloperGroup: action.payload.developerProofLoaded,
        recpackUploaded: action.payload.recPackLoaded,
      },
      errorMessage: '',
    };
  }
  case SITEID_DOCUMENTSAVE_FAILURE: {
    return {
      ...state,
      isChanging: false,
      isChanged: false,
      errorMessage: action.payload.message,
    };
  }
  case SITEID_DELETECOMMENT_REQUEST: {
    return {
      ...state,
      isChanging: true,
      isChanged: false,
      errorMessage: '',
    };
  }
  case SITEID_DELETECOMMENT_SUCCESS: {
    const newDeletedData = state.data.comment.filter(
      (c) => c.commentId !== action.payload,
    );

    return {
      ...state,
      isChanging: false,
      isChanged: true,
      data: { ...state.data, comment: newDeletedData },
      errorMessage: '',
    };
  }
  case SITEID_DELETECOMMENT_FAILURE: {
    return {
      ...state,
      isChanging: false,
      isChanged: false,
      errorMessage: action.payload.message,
    };
  }
  case SITEID_DOCUMENTDELETE_REQUEST: {
    return {
      ...state,
      isChanging: true,
      isChanged: false,
      errorMessage: '',
    };
  }
  case SITEID_DOCUMENTDELETE_SUCCESS: {
    const newDeletedDocumentData = state.data.document.filter(
      (c) => c.documentId !== action.payload.documentId,
    );

    return {
      ...state,
      isChanging: false,
      isChanged: true,
      data: {
        ...state.data,
        document: newDeletedDocumentData,
        proofOfDeveloperGroup: action.payload.developerProofLoaded,
        recpackUploaded: action.payload.recPackLoaded,
      },
      errorMessage: '',
    };
  }
  case SITEID_DOCUMENTDELETE_FAILURE: {
    return {
      ...state,
      isChanging: false,
      isChanged: false,
      errorMessage: action.payload.message,
    };
  }

  case SITEID_EXTEND_REQUEST: {
    return {
      ...state,
      isChanging: true,
      isChanged: false,
      errorMessage: '',
    };
  }
  case SITEID_EXTEND_SUCCESS: {
    const newExtensionData = state.data.extension.filter(
      (c) => c.extensionId !== action.payload.extensionId,
    );

    newExtensionData.push(action.payload);

    return {
      ...state,
      isChanging: false,
      isChanged: true,
      data: { ...state.data, extension: newExtensionData },
      errorMessage: '',
    };
  }
  case SITEID_EXTEND_FAILURE: {
    return {
      ...state,
      isChanging: false,
      isChanged: false,
      errorMessage: action.payload.message,
    };
  }

  case SITEID_PROGRESSSTATUS_REQUEST: {
    return {
      ...state,
      isChanging: true,
      isChanged: false,
      errorMessage: '',
    };
  }
  case SITEID_PROGRESSSTATUS_SUCCESS: {
    const newStatusData = state.data.statusChange.filter(
      (c) => c.statusChangeId !== action.payload.statusChangeId,
    );

    newStatusData.push(action.payload);

    return {
      ...state,
      isChanging: false,
      isChanged: true,
      data: { ...state.data, statusChange: newStatusData },
      errorMessage: '',
    };
  }
  case SITEID_PROGRESSSTATUS_FAILURE: {
    return {
      ...state,
      isChanging: false,
      isChanged: false,
      errorMessage: action.payload.message,
    };
  }
  default:
    return state;
  }
};
export default siteIDApplicationDetailReducer;
