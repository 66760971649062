import React from 'react';
import { connect } from 'react-redux';
import { OverdueInvoiceActions } from '../../../actions/overdueInvoiceActions';

class OverdueInvoices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      customerName: '',
      invoiceDate: undefined,
      dueDate: undefined,
    };

    this.doPaging = this.doPaging.bind(this);
    this.handleTextInputUpdate = this.handleTextInputUpdate.bind(this);
  }

  componentDidMount() {
    const { page, customerName, invoiceDate, dueDate } = this.state;
    this.props.getOverdueInvoices(page, customerName, invoiceDate, dueDate);
  }

  doPaging(page) {
    const { customerName, invoiceDate, dueDate } = this.state;
    this.setState({ page });

    this.props.getOverdueInvoices(page, customerName, invoiceDate, dueDate);
  }

  handleTextInputUpdate(e, fieldName) {
    let update = {};

    update[fieldName] = e.target.value;

    this.setState(update);
  }

  render() {
    const { overdueInvoiceList, loaded, page, pageSize, rowCount } = this.props;
    const { customerName, invoiceDate, dueDate } = this.state;
    const { doPaging, handleTextInputUpdate } = this;
    return (
      <div className="overdue-invoices-page page">
        <div className="floating-panel">
          <div className="floating-header">
            <div className="header-title">
              <div className="icon icon-overdue-invoices"></div>
              <h2>Overdue Invoices</h2>
            </div>
            <div className="pagination-controls">
              {rowCount === 0 && '0-0 of 0'}
              {rowCount > 0 &&
                `${page * pageSize - pageSize + 1}-${
                  page * pageSize < rowCount ? page * pageSize : rowCount
                } of ${rowCount}`}
              <button
                type="button"
                disabled={page === 1}
                onClick={() => doPaging(page - 1)}>
                {'<'}
              </button>
              <button
                type="button"
                disabled={page >= rowCount / pageSize}
                onClick={() => doPaging(page + 1)}>
                {'>'}
              </button>
            </div>
          </div>
          <p>
            Orderly takes no responsibility for the consequences of error or
            for any loss or damage suffered by users of any of the information
            published in this module. The information provided has been prepared
            for information purposes only and does not represent or replace the
            actual invoice issued by Orderly.
          </p>
          <p>
            This information relates to sales orders created in Orderly and
            processed through Oracle. It does not include any other type of
            invoice raised outside of OM. The display is updated at 05:00 CET.
            If you have made a payment today this will be posted into your
            account within approximately 5 working days.
          </p>
        </div>
        <div className="form-container">
          <div className="form-body">
            <label>
              Customer Name
              <input
                type="text"
                placeholder="Customer Name..."
                value={customerName}
                onChange={e => handleTextInputUpdate(e, 'customerName')}
              />
            </label>
            <label>
              Invoice Date
              <input
                type="date"
                value={invoiceDate}
                placeholder="Invoice Date..."
                onChange={e => handleTextInputUpdate(e, 'invoiceDate')}
              />
            </label>
            <label>
              Due Date
              <input
                type="date"
                value={dueDate}
                placeholder="Due Date..."
                onChange={e => handleTextInputUpdate(e, 'dueDate')}
              />
            </label>
          </div>
          <div className="form-buttons">
            <button
              type="button"
              className="float"
              onClick={() => doPaging(page)}>
              Search
            </button>
          </div>
        </div>
        <div className="overdue-invoices-container">
          {overdueInvoiceList.map(overdueInvoice => (
            <div
              key={overdueInvoice.invoiceNumber}
              className="invoice floating-panel">
              <div className="invoice-header">
                <h4>Invoice: {overdueInvoice.invoiceNumber}</h4>
                <h4>{overdueInvoice.customer}</h4>
              </div>
              <div className="invoice-body">
                <div>
                  <div className="label-value-pair">
                    <label>Purchase Order Ref:</label>
                    <span>{overdueInvoice.referencePurchaseOrder}</span>
                  </div>
                  <div className="label-value-pair">
                    <label>Starbuck's Order Ref:</label>
                    <span>{overdueInvoice.referenceStarbucksOrder}</span>
                  </div>
                </div>
                <div>
                  <div className="label-value-pair">
                    <label>Terms:</label>
                    <span>{overdueInvoice.paymentTerms}</span>
                  </div>
                  <div className="label-value-pair">
                    <label>Issued:</label>
                    <span>
                      {new Date(
                        overdueInvoice.invoiceDate,
                      ).toLocaleDateString()}
                    </span>
                  </div>
                  <div className="label-value-pair">
                    <label>Due:</label>
                    <span>
                      {new Date(overdueInvoice.dueDate).toLocaleDateString()}
                    </span>
                  </div>
                </div>
                <div className="invoice-prices">
                  <div className="label-value-pair">
                    <label>Invoiced:</label>
                    <span>
                      {overdueInvoice.invoiceAmount.toFixed(2)}{' '}
                      {overdueInvoice.currency}
                    </span>
                  </div>
                  <div className="label-value-pair">
                    <label>Applied:</label>
                    <span>
                      {overdueInvoice.amountApplied.toFixed(2)}{' '}
                      {overdueInvoice.currency}
                    </span>
                  </div>
                  <div className="label-value-pair">
                    <label>Remaining:</label>
                    <span>
                      {overdueInvoice.amountDueRemaining.toFixed(2)}{' '}
                      {overdueInvoice.currency}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {overdueInvoiceList.length === 0 && loaded && (
            <h3>
              Thank you, all sales order related payments are up-to-date, and
              you have no outstanding invoices.
            </h3>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loading: state.overdueInvoices.isFetching,
  loaded: state.overdueInvoices.isLoaded,
  overdueInvoiceList: state.overdueInvoices.overdueInvoiceList,
  page: state.overdueInvoices.pageNumber,
  pageSize: state.overdueInvoices.pageSize,
  rowCount: state.overdueInvoices.totalRecords,
});

const mapDispatchToProps = dispatch => {
  return {
    getOverdueInvoices: (page, customerName, invoiceDate, dueDate) =>
      dispatch(
        OverdueInvoiceActions.requestOverdueInvoices(
          page,
          customerName,
          invoiceDate,
          dueDate,
        ),
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OverdueInvoices);
