import React from 'react';
import ApplicationHelpers from '../../helpers/applicationHelpers';
import statuses from '../../helpers/applicationStatusEnum';
import projectTypes from '../../helpers/applicationProjectTypeEnum';

export class ComplianceInformation extends React.Component {

  render() {
    const {
      handleTextInputUpdate,
      locked,
      isReadOnly,
      fields,
      companiesDetail,
      formatsDetail,
      primaryBusinessGeneratorsDetail,
      planningGrantTypesDetail,
      projectTypesDetail,
      tenureTypesDetail,
      companyUsersDetail,
      applicationDetail,
      siteTypesDetail,
      countiesDetail,
      leaseDetail,
      handleCheckInputUpdate,
      nearApplications  } = this.props;
    const {
      companyId,
      siteName,
      projectTypeId,
      formatId,
      tenureTypeId,
      netSquareFeet,
      grossSquareFeet,
      primaryBusinessGeneratorId,
      estimatedOpeningDate,
      plannedClosingDate,
      primaryContactId,
      secondaryContactid,
      statusId,
      street1,
      street2,
      city,
      countyId,
      postCode,
      latitude,
      longitude,
      showValidation,
      storeNo,
      developer,
      finalDeveloper,
      siteReferenceNo,
      projectType,
      headsOfTermsAgreed,
      leaseSignedOrSitePurchasedId,
      siteTypeId,
      planningRequired,
      planningGrantedId,
      planningGranted,
      planningSubmitted,
      planningComments,
      plannedSchematic,
      latestEstimatedOpeningDate,
    } = fields;

    const {
      company,
      tenureType,
      format,
      primaryBusinessGenerator,
      projectStatus,
      siteType,
    } = applicationDetail;

    const primaryContact = companyUsersDetail.find(
      (c) => c.userId === primaryContactId,
    );
    const secondaryContact = companyUsersDetail.find(
      (c) => c.userId === secondaryContactid,
    );
    const county = countiesDetail.find(
      (c) => c.key === countyId,
    );

    return (
      <div>
        <h3>Compliance Information</h3>
        <p>
          <span className="required"> *</span>
          {' '}
          These fields are mandatory to
          progress this application.
        </p>
        {!ApplicationHelpers.validateProximity(nearApplications, statusId, projectTypeId) ?
          <span  className="required">
           This application is too close to an existing application.
          </span> : null}
        <div className="form-section">
          <label 
                  className="wrapping">
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                    />
                    <div className="toggle-icon"></div>
                  </div>
                  <span>
                  <b>Client Approved?</b>
                  </span>
                </label>
        </div>
      </div>
    );

  }
}

export default ComplianceInformation;