import React from 'react';

class SaveStatusModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fromStatusId: -1,
      toStatusId: -1,
      notes: '',
      expirationOrLongStop: undefined,
      rejectedForApplication: undefined,
      notesMandatory: undefined,
      addedMonths : undefined,
      showValidation: false,

      selectedResponses: [],
    };
    this.handleTextInputUpdate = this.handleTextInputUpdate.bind(this);
    this.handleExtensionDateUpdate = this.handleExtensionDateUpdate.bind(this);
    this.handleResponses = this.handleResponses.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
  }

  componentDidMount() {
    const {
      fromStatusId,
      toStatusId,
      isLicensee,
      nearApplications,
    } = this.props;
    let displayExpiration = false;
    let blameApplication = false;
    let notesMandatory = false;

    if (!isLicensee && [3, 5, 7].indexOf(toStatusId) > -1) {
      displayExpiration = true;
    }

    if (toStatusId === 9 && nearApplications && nearApplications.length > 0) {
      blameApplication = true;
    }

    if ([9, 10, 11].indexOf(toStatusId) > -1) {
      notesMandatory = true;
    }

    notesMandatory = false;

    let expirationOrLongStop = new Date();

    if (displayExpiration) {
      expirationOrLongStop.setDate(expirationOrLongStop.getDate() + 7 * 12);
    } else {
      expirationOrLongStop = undefined;
    }

    this.setState({
      fromStatusId,
      toStatusId,
      displayExpiration,
      blameApplication,
      notesMandatory,
      expirationOrLongStop,
    });
  }

  handleTextInputUpdate(e, fieldName) {
    this.setState({ [fieldName]: e.target.value });
  }
  
  handleExtensionDateUpdate(e) {
    const { longstop } = this.props;

    let newDate = new Date();

    let updateMode = e.target.value;

    if (updateMode === '1') {
      this.setState({ expirationOrLongStop: longstop });
      return;
    } else if (updateMode === '3') {
      newDate.setDate(newDate.getDate() + (12 * 7));
    } else if (updateMode === '5') {
      newDate.setDate(newDate.getDate() + (21 * 7));
    } else if (updateMode === '6') {
      newDate.setDate(newDate.getDate() + (26 * 7));
    } else if (updateMode === '7') {
      newDate.setDate(newDate.getDate() + (31 * 7));
    }else {
      return;
    }

    this.setState({ expirationOrLongStop: (newDate).toISOString().substr(0,10) });
  }

  handleResponses(e, id) {
    const { selectedResponses } = this.state;

    let newSelectedValues = selectedResponses;
    if (e.target.checked) {
      newSelectedValues = [...newSelectedValues, id];
    } else {
      const position = newSelectedValues.indexOf(id);
      if (position > -1) {
        newSelectedValues.splice(position, 1);
      }
    }

    this.setState({ selectedResponses: newSelectedValues });
  }

  handleContinue() {
    const {
      fromStatusId,
      toStatusId,
      notes,
      expirationOrLongStop,
      displayExpiration,
      rejectedForApplication,
      notesMandatory,
      selectedResponses,
      addedMonths,
    } = this.state;

    const {
      defaultResponses,
    } = this.props;

    if (
      (!notes && selectedResponses.length === 0 && notesMandatory)
      || (displayExpiration && !expirationOrLongStop)
    ) {
      this.setState({ showValidation: true });
    } else {
      this.props.continue({
        fromStatusId,
        toStatusId,
        notes: selectedResponses.map((s) => `${defaultResponses.find((d) => d.id === s).response}\r\n\r\n`) + notes,
        expirationOrLongStop,
        rejectedForApplication,
        addedMonths,
      });
    }
  }

  render() {
    const {
      notes,
      expirationOrLongStop,
      toStatusId,
      showValidation,
      displayExpiration,
      blameApplication,
      rejectedForApplication,
      notesMandatory,
      selectedResponses,
      addedMonths,
    } = this.state;
    const {
      cancel,
      inProgress,
      title,
      nearApplications,
      isLicensee,
      defaultResponses,
      errorMessage,
      longstop,
    } = this.props;
    const { handleTextInputUpdate, handleExtensionDateUpdate, handleContinue } = this;
    return (
      <div className="modal-blocker">
        <div className="modal">
          <form>
            <div className="modal-menu">
              <button type="button"
                className="action"
                onClick={() => cancel()}>
                X
              </button>
            </div>
            <div className="modal-title">
              <h2>{title}</h2>
            </div>
            <div className="modal-body">
              {defaultResponses && defaultResponses.length > 0
              && <div>Default Responses</div>}
              {
                defaultResponses
                  .filter((d) => (!d.rejection && [9, 10, 11].indexOf(toStatusId) === -1)
                                  || (d.rejection && [9, 10, 11].indexOf(toStatusId) > -1))
                  .map((d) => (
                    <label key={d.id}
                      className="inline-checkbox" >
                      <div className="toggle-wrapper">
                        <input
                          type="checkbox"
                          className="toggle"
                          onChange={(e) => this.handleResponses(e, d.id)}
                          checked={selectedResponses.indexOf(d.id) > -1}
                        />
                        <div className="toggle-icon" />
                      </div>
                      {d.title}
                    </label>
                  ))
              }
              <label>
                Notes
                {' '}
                {!isLicensee ? '(Retailer Visible)' : ''}
                <textarea
                  autoFocus
                  required
                  className={
                    showValidation && notesMandatory && !notes ? 'invalid' : ''
                  }
                  value={notes}
                  onChange={(e) => handleTextInputUpdate(e, 'notes')}
                />
              </label>
              {displayExpiration && (<>
                <label>
                  {toStatusId === 7
                    ? 'Application Long Stop Options'
                    : 'Status Expiration Options'}
                
                  <select    
                    value={addedMonths}
                    onChange={e => handleExtensionDateUpdate(e) }>
                    <option value="">Please Select</option>
                    <option value={3}>3 Months</option>
                    <option value={5}>5 Months</option>
                    <option value={6}>6 Months</option>
                    <option value={7}>7 Months</option>
                    {longstop ? <option value={1}>EMEA Long Stop</option> : null}
                  </select>
                </label>
                <label>
                  {toStatusId === 7
                    ? 'Application Long Stop'
                    : 'Status Expiration'}
                  <input
                    autoFocus
                    type="date"
                    required
                    className={
                      showValidation && !expirationOrLongStop
                        ? 'invalid'
                        : ''
                    }
                    value={
                      expirationOrLongStop &&
                      new Date(expirationOrLongStop).toISOString().substr(0, 10)
                    }
                    onChange={e => handleTextInputUpdate(e, 'expirationOrLongStop')}
                  />
                </label></>
              )}
              {blameApplication && (
                <label>
                  Rejected for
                  <select
                    value={rejectedForApplication}
                    onChange={(e) => handleTextInputUpdate(e, 'rejectedForApplication')}
                  >
                    <option value="">Please Select</option>
                    {nearApplications
                    .filter(a => a.distanceFromSource <= 5 && [2,3,4,5,6,7,8,-93,-95].indexOf(a.statusId) > -1)
                    .sort(function(a, b){return a.distanceFromSource - b.distanceFromSource})
                    .map((n) => (
                      <option key={n.applicationId}
                        value={n.applicationId}>
                        {`SREF-${n.applicationId} - ${n.siteName}`}
                      </option>
                    ))}
                  </select>
                </label>
              )}
            </div>
            <div className="modal-buttons">
              <button
                type="button"
                className="action"
                disabled={inProgress}
                onClick={() => handleContinue()}
              >
                Save
              </button>
              <button
                type="button"
                className="action negative"
                disabled={inProgress}
                onClick={() => cancel()}
              >
                Cancel
              </button>
            </div>
            <div className="modal-error-message">
              {errorMessage}
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default SaveStatusModal;
