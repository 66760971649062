import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../actions/navigationActions';
import { posItemNumberRequestActionsAdmin } from '../../../actions/posItemNumberRequestActionsAdmin';
import qs from 'query-string';
import TableViewControls from '../../shared/tableViewControls';
import Pagination from '../../shared/Pagination';

class PendingCategoryReview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: '',
      page: 1,
      pageSize: 25,
      displayMode: 1,
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.setDisplayMode = this.setDisplayMode.bind(this);

    this.doPaging = this.doPaging.bind(this);
    this.doSearch = this.doSearch.bind(this);

    this.navigateItemDetails = this.navigateItemDetails.bind(this);
  }

  handleStatusChange(e) {
    this.setState({ status: e.target.value });
    this.handleFilterChange({ ...this.state, status: e.target.value });

    e.preventDefault();
  }

  componentDidMount() {
    const { search } = this.props.location;

    let newState = {
      pageSize: 25,
      filter: '',
      page: 1,
      displayMode: 1,
    };

    if (search) {
      const parsed = qs.parse(search.toLowerCase());

      newState = {
        ...newState,
        filter: parsed.filter ? parsed.filter : '',
        page: parsed.page ? parseInt(parsed.page) : 1,
        displayMode: parsed.displaymode ? parseInt(parsed.displaymode) : 1,
      };
    }

    this.setState(newState);

   
    this.props.getItems(
      newState.page, newState.pageSize, newState.filter, 
    );
  }
  


  handleSearch(e) {
    this.setState({ filter : e.target.value, page: 1 });
    e.preventDefault();
  }

  navigateItemDetails(itemId) {
    const { pathname } = this.props.location;
    this.props.push(`${pathname}/${itemId}`);
  }

  doSearch() {
    this.handleFilterChange({ ...this.state });
  }

  doPaging(page) {
    this.setState({ page });

    this.handleFilterChange({ ...this.state, page });
  }

  setDisplayMode(mode) {
    this.setState({ displayMode: mode });
  }

  handleFilterChange(currentState) {
    const { pathname } = this.props.location;
    const {
      filter,
      displayMode,
      pageSize,
      page,
    } = currentState;

    this.props.replace(
      `${pathname}?=$filter=${filter}&page=${page}&displayMode=${displayMode}`,
    );

    this.props.getItems(
      page, pageSize, filter,
    );
  }

  lookupCustomerStoreGroup(id, storeGroups){
    let currentStoreGroup = storeGroups.filter(s => s.storeGroupId === id);

    if(currentStoreGroup.length > 0){
      return currentStoreGroup[0].storeGroupName;
    }

    return '-';
  }

  render() {
    const { 
      rowCount,  
      adminItemList, 
      storeGroups } = this.props;
    const {
      page,
      pageSize,
      filter,
      displayMode,
    } = this.state;
    const {
      handleSearch,
      doSearch,
      doPaging,
      navigateItemDetails,
    } = this;

  
    return (
      <div className="pending-category-review page">
        <div className="floating-panel">
          <div className="floating-header">
            <div className="header-title">
              <div className="icon icon-table" />
              <div>
                <h2>
                Pending Category Review
                </h2>
                <p>{`Customer's Orderly POS item number requests will first be detailed here. 
                The request will not proceed to MDIO until the item is approved by Category.`}</p>
              </div>
            </div>
            <div className= "search-wrapper"> 
              <input
                className= "search-item"
                type="text"
                placeholder="Search..."
                value={filter}
                onChange={e => handleSearch(e)}
              />
              <button className="action"
                onClick={() => doSearch()}
              >Search</button>
            </div>
          </div>  
        </div>   
        <div className="table-container">
          <div className= "table-header">
            <TableViewControls
              displayMode = {this.state.displayMode}
              setDisplayMode = {this.setDisplayMode}
            />
            <h2>
              POS Item Number Requests 
            </h2>
            <div className="pagination-controls">
              <Pagination
                rowCount={rowCount}
                page={page}
                pageSize={pageSize}
                doPaging={doPaging}
              />
            </div>
          </div>
          
          <div className="table-body">
            <table  className={`table-display-mode-${displayMode}`}>
              <thead>
                <tr>
                  <th>Customer</th>
                  <th>Market</th>
                  <th>Requested By</th>
                  <th>Customer Item Number</th>
                  <th>Item POS Description</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
               
                {adminItemList.map(item => (
                  <tr
                    key={item.id}>
                    <td>{this.lookupCustomerStoreGroup(item.customerStoreGroupId, storeGroups)}</td>
                    <td>{item.market}</td>
                    <td>{item.createdBy}</td>
                    <td>{item.customerItemNumber}</td>
                    <td>{item.customerItemDescription}</td>
                    <td className="right-cell">
                      <button
                        type="button"
                        className="action"
                        onClick={() => navigateItemDetails(item.id)}
                      
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="table-header footer">
            <div></div>
            <div className="pagination-controls">
              <Pagination
                rowCount={rowCount}
                page={page}
                pageSize={pageSize}
                doPaging={doPaging}
              />
            </div>
          </div>
        </div>
        
      </div>

    );
  }
}
const mapStateToProps = (state) => ({
  loading: state.lsPosItemNumberAdmin.isFetching,
  loaded: state.lsPosItemNumberAdmin.isLoaded,
  stores: state.auth.stores,
  storeGroups: state.lsPosItemNumberAdmin.storeGroups,
  adminItemList: state.lsPosItemNumberAdmin.adminItemList,
  pageNumber: state.lsPosItemNumberAdmin.pageNumber,
  pageCount: Math.ceil(state.lsPosItemNumberAdmin.totalRecords / state.lsPosItemNumberAdmin.pageSize),
  rowCount: state.lsPosItemNumberAdmin.totalRecords,
});

const mapDispatchToProps = dispatch => {
  return {
    getItems: (page, pageSize, filter) =>
      dispatch(
        posItemNumberRequestActionsAdmin.requestItems(
          page, pageSize, filter, 
        ),
      ),
   
    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PendingCategoryReview);
