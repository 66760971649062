// AUTH
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const TOKEN_REFRESH_REQUEST = 'TOKEN_REFRESH_REQUEST';
export const TOKEN_REFRESH_SUCCESS = 'TOKEN_REFRESH_SUCCESS';
export const TOKEN_REFRESH_FAILURE = 'TOKEN_REFRESH_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const ACCEPT_TERMS_REQUEST = 'ACCEPT_TERMS_REQUEST';
export const ACCEPT_TERMS_SUCCESS = 'ACCEPT_TERMS_SUCCESS';
export const ACCEPT_TERMS_FAILURE = 'ACCEPT_TERMS_FAILURE';
export const TERMS_ACCEPTED = 'TERMS_ACCEPTED';

export const CONNECT_DISPLAY = 'CONNECT_DISPLAY';
export const CONNECT_HIDE = 'CONNECT_HIDE';

export const REQUESTPASSWORDRESET_REQUEST = 'REQUESTPASSWORDRESET_REQUEST';
export const REQUESTPASSWORDRESET_SUCCESS = 'REQUESTPASSWORDRESET_SUCCESS';
export const REQUESTPASSWORDRESET_FAILURE = 'REQUESTPASSWORDRESET_FAILURE';

export const PASSWORDRESET_REQUEST = 'PASSWORDRESET_REQUEST';
export const PASSWORDRESET_SUCCESS = 'PASSWORDRESET_SUCCESS';
export const PASSWORDRESET_FAILURE = 'PASSWORDRESET_FAILURE';

export const ACTIVATEACCOUNT_REQUEST = 'ACTIVATEACCOUNT_REQUEST';
export const ACTIVATEACCOUNT_SUCCESS = 'ACTIVATEACCOUNT_SUCCESS';
export const ACTIVATEACCOUNT_FAILURE = 'ACTIVATEACCOUNT_FAILURE';
// MENU STRUCTURE
export const MODULE_REQUEST = 'MODULE_REQUEST';
export const MODULE_SUCCESS = 'MODULE_SUCCESS';
export const MODULE_FAILURE = 'MODULE_FAILURE';

// USER MANAGEMENT
export const USERS_REQUEST = 'USERS_REQUEST';
export const USERS_SUCCESS = 'USERS_SUCCESS';
export const USERS_FAILURE = 'USERS_FAILURE';

export const USERID_REQUEST = 'USERID_REQUEST';
export const USERID_SUCCESS = 'USERID_SUCCESS';
export const USERID_FAILURE = 'USERID_FAILURE';

export const SAVEUSER_REQUEST = 'SAVEUSER_REQUEST';
export const SAVEUSER_SUCCESS = 'SAVEUSER_SUCCESS';
export const SAVEUSER_FAILURE = 'SAVEUSER_FAILURE';

export const UPDATEMYUSER_REQUEST = 'UPDATEMYUSER_REQUEST';
export const UPDATEMYUSER_SUCCESS = 'UPDATEMYUSER_SUCCESS';
export const UPDATEMYUSER_FAILURE = 'UPDATEMYUSER_FAILURE';

export const DELETEUSER_REQUEST = 'DELETEUSER_REQUEST';
export const DELETEUSER_SUCCESS = 'DELETEUSER_SUCCESS';
export const DELETEUSER_FAILURE = 'DELETEUSER_FAILURE';

export const MODULEGROUPS_REQUEST = 'MODULEGROUPS_REQUEST';
export const MODULEGROUPS_SUCCESS = 'MODULEGROUPS_SUCCESS';
export const MODULEGROUPS_FAILURE = 'MODULEGROUPS_FAILURE';

export const MODULEGROUPID_REQUEST = 'MODULEGROUPID_REQUEST';
export const MODULEGROUPID_SUCCESS = 'MODULEGROUPID_SUCCESS';
export const MODULEGROUPID_FAILURE = 'MODULEGROUPID_FAILURE';

export const SAVEMODULEGROUP_REQUEST = 'SAVEMODULEGROUP_REQUEST';
export const SAVEMODULEGROUP_SUCCESS = 'SAVEMODULEGROUP_SUCCESS';
export const SAVEMODULEGROUP_FAILURE = 'SAVEMODULEGROUP_FAILURE';

export const DELETEMODULEGROUP_REQUEST = 'DELETEMODULEGROUP_REQUEST';
export const DELETEMODULEGROUP_SUCCESS = 'DELETEMODULEGROUP_SUCCESS';
export const DELETEMODULEGROUP_FAILURE = 'DELETEMODULEGROUP_FAILURE';

export const STOREGROUPS_REQUEST = 'STOREGROUPS_REQUEST';
export const STOREGROUPS_SUCCESS = 'STOREGROUPS_SUCCESS';
export const STOREGROUPS_FAILURE = 'STOREGROUPS_FAILURE';

export const STOREGROUPID_REQUEST = 'STOREGROUPID_REQUEST';
export const STOREGROUPID_SUCCESS = 'STOREGROUPID_SUCCESS';
export const STOREGROUPID_FAILURE = 'STOREGROUPID_FAILURE';

export const SAVESTOREGROUP_REQUEST = 'SAVESTOREGROUP_REQUEST';
export const SAVESTOREGROUP_SUCCESS = 'SAVESTOREGROUP_SUCCESS';
export const SAVESTOREGROUP_FAILURE = 'SAVESTOREGROUP_FAILURE';

export const DELETESTOREGROUP_REQUEST = 'DELETESTOREGROUP_REQUEST';
export const DELETESTOREGROUP_SUCCESS = 'DELETESTOREGROUP_SUCCESS';
export const DELETESTOREGROUP_FAILURE = 'DELETESTOREGROUP_FAILURE';

export const COMPLETEMFA_REQUEST = 'COMPLETEMFA_REQUEST';
export const COMPLETEMFA_SUCCESS = 'COMPLETEMFA_SUCCESS';
export const COMPLETEMFA_FAILURE = 'COMPLETEMFA_FAILURE';

// Notifications
export const NOTIFICATIONS_REQUEST = 'NOTIFICATIONS_REQUEST';
export const NOTIFICATIONS_SUCCESS = 'NOTIFICATIONS_SUCCESS';
export const NOTIFICATIONS_FAILURE = 'NOTIFICATIONS_FAILURE';

export const SITEID_NOTIFICATIONS_REQUEST = 'SITEID_NOTIFICATIONS_REQUEST';
export const SITEID_NOTIFICATIONS_SUCCESS = 'SITEID_NOTIFICATIONS_SUCCESS';
export const SITEID_NOTIFICATIONS_FAILURE = 'SITEID_NOTIFICATIONS_FAILURE';

export const ACKNOTIFICATION_REQUEST = 'ACKNOTIFICATION_REQUEST';
export const ACKNOTIFICATION_SUCCESS = 'ACKNOTIFICATION_SUCCESS';
export const ACKNOTIFICATION_FAILURE = 'ACKNOTIFICATION_FAILURE';

export const ALLACKNOTIFICATION_REQUEST = 'ALLACKNOTIFICATION_REQUEST';
export const ALLACKNOTIFICATION_SUCCESS = 'ALLACKNOTIFICATION_SUCCESS';
export const ALLACKNOTIFICATION_FAILURE = 'ALLACKNOTIFICATION_FAILURE';

export const ALLACKSITEIDNOTIFICATION_REQUEST = 'ALLACKSITEIDNOTIFICATION_REQUEST';
export const ALLACKSITEIDNOTIFICATION_SUCCESS = 'ALLACKSITEIDNOTIFICATION_SUCCESS';
export const ALLACKSITEIDNOTIFICATION_FAILURE = 'ALLACKSITEIDNOTIFICATION_FAILURE';

export const ALERTS_REQUEST = 'ALERTS_REQUEST';
export const ALERTS_SUCCESS = 'ALERTS_SUCCESS';
export const ALERTS_FAILURE = 'ALERTS_FAILURE';

export const ALERT_REQUEST = 'ALERT_REQUEST';
export const ALERT_SUCCESS = 'ALERT_SUCCESS';
export const ALERT_FAILURE = 'ALERT_FAILURE';

export const SAVEALERT_REQUEST = 'SAVEALERT_REQUEST';
export const SAVEALERT_SUCCESS = 'SAVEALERT_SUCCESS';
export const SAVEALERT_FAILURE = 'SAVEALERT_FAILURE';

export const DELETEALERT_REQUEST = 'DELETEALERT_REQUEST';
export const DELETEALERT_SUCCESS = 'DELETEALERT_SUCCESS';
export const DELETEALERT_FAILURE = 'DELETEALERT_FAILURE';

export const DISTRIBUTION_DETAIL_REQUEST = 'DISTRIBUTION_DETAIL_REQUEST';
export const DISTRIBUTION_DETAIL_SUCCESS = 'DISTRIBUTION_DETAIL_SUCCESS';
export const DISTRIBUTION_DETAIL_FAILURE = 'DISTRIBUTION_DETAIL_FAILURE';
export const DISTRIBUTION_MARKETS_REQUEST = 'DISTRIBUTION_MARKETS_REQUEST';
export const DISTRIBUTION_MARKETS_SUCCESS = 'DISTRIBUTION_MARKETS_SUCCESS';
export const DISTRIBUTION_MARKETS_FAILURE = 'DISTRIBUTION_MARKETS_FAILURE';
export const DISTRIBUTION_SAVE_REQUEST = 'DISTRIBUTION_SAVE_REQUEST';
export const DISTRIBUTION_SAVE_SUCCESS = 'DISTRIBUTION_SAVE_SUCCESS';
export const DISTRIBUTION_SAVE_FAILURE = 'DISTRIBUTION_SAVE_FAILURE';
// Customer Service
export const SUPPLIERFEEDBACK_REQUEST = 'SUPPLIERFEEDBACK_REQUEST';
export const SUPPLIERFEEDBACK_SUCCESS = 'SUPPLIERFEEDBACK_SUCCESS';
export const SUPPLIERFEEDBACK_FAILURE = 'SUPPLIERFEEDBACK_FAILURE';

export const PRODUCTFEEDBACK_REQUEST = 'PRODUCTFEEDBACK_REQUEST';
export const PRODUCTFEEDBACK_SUCCESS = 'PRODUCTFEEDBACK_SUCCESS';
export const PRODUCTFEEDBACK_FAILURE = 'PRODUCTFEEDBACK_FAILURE';

export const PORTALFEEDBACK_REQUEST = 'PORTALFEEDBACK_REQUEST';
export const PORTALFEEDBACK_SUCCESS = 'PORTALFEEDBACK_SUCCESS';
export const PORTALFEEDBACK_FAILURE = 'PORTALFEEDBACK_FAILURE';

// Marketing
export const ORDERLYREQUEST_REQUEST = 'ORDERLYREQUEST_REQUEST';
export const ORDERLYREQUEST_SUCCESS = 'ORDERLYREQUEST_SUCCESS';
export const ORDERLYREQUEST_FAILURE = 'ORDERLYREQUEST_FAILURE';

// Forecast Change Request
export const FORECASTCHANGEREQUEST_REQUEST = 'FORECASTCHANGEREQUEST_REQUEST';
export const FORECASTCHANGEREQUEST_SUCCESS = 'FORECASTCHANGEREQUEST_SUCCESS';
export const FORECASTCHANGEREQUEST_FAILURE = 'FORECASTCHANGEREQUEST_FAILURE';

export const CHECKITEM_REQUEST = 'CHECKITEM_REQUEST';
export const CHECKITEM_SUCCESS = 'CHECKITEM_SUCCESS';
export const CHECKITEM_FAILURE = 'CHECKITEM_FAILURE';

// File Download
export const DOWNLOAD_REQUEST = 'DOWNLOAD_REQUEST';
export const DOWNLOAD_SUCCESS = 'DOWNLOAD_SUCCESS';
export const DOWNLOAD_FAILURE = 'DOWNLOAD_FAILURE';

// Documents
export const DOCUMENTS_REQUEST = 'DOCUMENTS_REQUEST';
export const DOCUMENTS_SUCCESS = 'DOCUMENTS_SUCCESS';
export const DOCUMENTS_FAILURE = 'DOCUMENTS_FAILURE';

export const SAVEFOLDER_REQUEST = 'SAVEFOLDER_REQUEST';
export const SAVEFOLDER_SUCCESS = 'SAVEFOLDER_SUCCESS';
export const SAVEFOLDER_FAILURE = 'SAVEFOLDER_FAILURE';

export const UPDATEFOLDER_REQUEST = 'UPDATEFOLDER_REQUEST';
export const UPDATEFOLDER_SUCCESS = 'UPDATEFOLDER_SUCCESS';
export const UPDATEFOLDER_FAILURE = 'UPDATEFOLDER_FAILURE';

export const DELETEFOLDER_REQUEST = 'DELETEFOLDER_REQUEST';
export const DELETEFOLDER_SUCCESS = 'DELETEFOLDER_SUCCESS';
export const DELETEFOLDER_FAILURE = 'DELETEFOLDER_FAILURE';

export const CREATEDOC_REQUEST = 'CREATEDOC_REQUEST';
export const CREATEDOC_SUCCESS = 'CREATEDOC_SUCCESS';
export const CREATEDOC_FAILURE = 'CREATEDOC_FAILURE';

export const UPDATEDOC_REQUEST = 'UPDATEDOC_REQUEST';
export const UPDATEDOC_SUCCESS = 'UPDATEDOC_SUCCESS';
export const UPDATEDOC_FAILURE = 'UPDATEDOC_FAILURE';

export const MOVEDOC_REQUEST = 'MOVEDOC_REQUEST';
export const MOVEDOC_SUCCESS = 'MOVEDOC_SUCCESS';
export const MOVEDOC_FAILURE = 'MOVEDOC_FAILURE';

export const DELETEDOC_REQUEST = 'DELETEDOC_REQUEST';
export const DELETEDOC_SUCCESS = 'DELETEDOC_SUCCESS';
export const DELETEDOC_FAILURE = 'DELETEDOC_FAILURE';

// MyDocuments
export const MYDOCUMENTS_REQUEST = 'MYDOCUMENTS_REQUEST';
export const MYDOCUMENTS_SUCCESS = 'MYDOCUMENTS_SUCCESS';
export const MYDOCUMENTS_FAILURE = 'MYDOCUMENTS_FAILURE';

export const SAVEMYDOC_REQUEST = 'SAVEMYDOC_REQUEST';
export const SAVEMYDOC_SUCCESS = 'SAVEMYDOC_SUCCESS';
export const SAVEMYDOC_FAILURE = 'SAVEMYDOC_FAILURE';

export const DELETEMYDOC_REQUEST = 'DELETEMYDOC_REQUEST';
export const DELETEMYDOC_SUCCESS = 'DELETEMYDOC_SUCCESS';
export const DELETEMYDOC_FAILURE = 'DELETEMYDOC_FAILURE';

//Email Document Management 

export const EMAILDOCUMENTENTRIES_REQUEST = 'EMAILDOCUMENTENTRIES_REQUEST';
export const EMAILDOCUMENTENTRIES_SUCCESS = 'EMAILDOCUMENTENTRIES_SUCCESS';
export const EMAILDOCUMENTENTRIES_FAILURE = 'EMAILDOCUMENTENTRIES_FAILURE';
export const EMAILDOCUMENTENTRY_REQUEST = 'EMAILDOCUMENTENTRY_REQUEST';
export const EMAILDOCUMENTENTRY_SUCCESS = 'EMAILDOCUMENTENTRY_SUCCESS';
export const EMAILDOCUMENTENTRY_FAILURE = 'EMAILDOCUMENTENTRY_FAILURE';
export const SAVEEMAILDOCUMENTENTRY_REQUEST = 'SAVEEMAILDOCUMENTENTRY_REQUEST';
export const SAVEEMAILDOCUMENTENTRY_SUCCESS = 'SAVEEMAILDOCUMENTENTRY_SUCCESS';
export const SAVEEMAILDOCUMENTENTRY_FAILURE = 'SAVEEMAILDOCUMENTENTRY_FAILURE';
export const DELETEEMAILDOCUMENTENTRY_REQUEST = 'DELETEEMAILDOCUMENTENTRY_REQUEST';
export const DELETEEMAILDOCUMENTENTRY_SUCCESS = 'DELETEEMAILDOCUMENTENTRY_SUCCESS';
export const DELETEEMAILDOCUMENTENTRY_FAILURE = 'DELETEEMAILDOCUMENTENTRY_FAILURE';

// Stores
export const STORES_REQUEST = 'STORES_REQUEST';
export const STORES_SUCCESS = 'STORES_SUCCESS';
export const STORES_FAILURE = 'STORES_FAILURE';

// Overdue Invoices
export const OVERDUEINVOICES_REQUEST = 'OVERDUEINVOICES_REQUEST';
export const OVERDUEINVOICES_SUCCESS = 'OVERDUEINVOICES_SUCCESS';
export const OVERDUEINVOICES_FAILURE = 'OVERDUEINVOICES_FAILURE';

// RslPerformances
export const RSLPERFORMANCE_REQUEST = 'RSLPERFORMANCE_REQUEST';
export const RSLPERFORMANCE_SUCCESS = 'RSLPERFORMANCE_SUCCESS';
export const RSLPERFORMANCE_FAILURE = 'RSLPERFORMANCE_FAILURE';

// ItemCatalogue
export const ITEMCATALOGUE_REQUEST = 'ITEMCATALOGUE_REQUEST';
export const ITEMCATALOGUE_SUCCESS = 'ITEMCATALOGUE_SUCCESS';
export const ITEMCATALOGUE_FAILURE = 'ITEMCATALOGUE_FAILURE';

export const ITEMDETAIL_REQUEST = 'ITEMDETAIL_REQUEST';
export const ITEMDETAIL_SUCCESS = 'ITEMDETAIL_SUCCESS';
export const ITEMDETAIL_FAILURE = 'ITEMDETAIL_FAILURE';

export const ITEMCATALOGUEEXPORT_REQUEST = 'ITEMCATALOGUEEXPORT_REQUEST';
export const ITEMCATALOGUEEXPORT_SUCCESS = 'ITEMCATALOGUEEXPORT_SUCCESS';
export const ITEMCATALOGUEEXPORT_FAILURE = 'ITEMCATALOGUEEXPORT_FAILURE';

// Value added services
export const VASLIST_REQUEST = 'VASLIST_REQUEST';
export const VASLIST_SUCCESS = 'VASLIST_SUCCESS';
export const VASLIST_FAILURE = 'VASLIST_FAILURE';

export const VASDETAIL_REQUEST = 'VASDETAIL_REQUEST';
export const VASDETAIL_SUCCESS = 'VASDETAIL_SUCCESS';
export const VASDETAIL_FAILURE = 'VASDETAIL_FAILURE';

// Booking Forms
export const BOOKINGFORMS_REQUEST = 'BOOKINGFORMS_REQUEST';
export const BOOKINGFORMS_SUCCESS = 'BOOKINGFORMS_SUCCESS';
export const BOOKINGFORMS_FAILURE = 'BOOKINGFORMS_FAILURE';

// Upload Master File
export const UPLOADMASTERFILE_REQUEST = 'UPLOADEMASTERFILE_REQUEST';
export const UPLOADMASTERFILE_SUCCESS = 'UPLOADEMASTERFILE_SUCCESS';
export const UPLOADMASTERFILE_FAILURE = 'UPLOADEMASTERFILE_FAILURE';

//  Requirements
export const GETCUSTOMERREQUIREMENTS_REQUEST = 'GETCUSTOMERREQUIREMENTS_REQUEST';
export const GETCUSTOMERREQUIREMENTS_SUCCESS = 'GETCUSTOMERREQUIREMENTS_SUCCESS';
export const GETCUSTOMERREQUIREMENTS_FAILURE = 'GETCUSTOMERREQUIREMENTS_FAILURE';

export const GETCUSTOMERREQUIREMENTSODS_REQUEST = 'GETCUSTOMERREQUIREMENTSODS_REQUEST';
export const GETCUSTOMERREQUIREMENTSODS_SUCCESS = 'GETCUSTOMERREQUIREMENTSODS_SUCCESS';
export const GETCUSTOMERREQUIREMENTSODS_FAILURE = 'GETCUSTOMERREQUIREMENTSODS_FAILURE';

export const SAVECUSTOMERREQUIREMENTSODS_REQUEST = 'SAVECUSTOMERREQUIREMENTSODS_REQUEST';
export const SAVECUSTOMERREQUIREMENTSODS_SUCCESS = 'SAVECUSTOMERREQUIREMENTSODS_SUCCESS';
export const SAVECUSTOMERREQUIREMENTSODS_FAILURE = 'SAVECUSTOMERREQUIREMENTSODS_FAILURE';

export const DOWNLOADDOCUMENTODS_REQUEST = 'DOCUMENTDOWNLOAD_REQUEST';
export const DOWNLOADDOCUMENTODS_SUCCESS = 'DOCUMENTDOWNLOAD_SUCCESS';
export const DOWNLOADDOCUMENTODS_FAILURE = 'DOCUMENTDOWNLOAD_FAILURE';

export const UPDATECUSTOMERREQUIREMENTS_REQUEST = 'UPDATECUSTOMERREQUIREMENTS_REQUEST';
export const UPDATECUSTOMERREQUIREMENTS_SUCCESS = 'UPDATECUSTOMERREQUIREMENTS_SUCCESS';
export const UPDATECUSTOMERREQUIREMENTS_FAILURE = 'UPDATECUSTOMERREQUIREMENTS_FAILURE';

// Pricing Catalogue
export const ITEMPRICELIST_REQUEST = 'ITEMPRICELIST_REQUEST';
export const ITEMPRICELIST_SUCCESS = 'ITEMPRICELIST_SUCCESS';
export const ITEMPRICELIST_FAILURE = 'ITEMPRICELIST_FAILURE';

export const EXPORTITEMPRICELIST_REQUEST = 'EXPORTITEMPRICELIST_REQUEST';
export const EXPORTITEMPRICELIST_SUCCESS = 'EXPORTITEMPRICELIST_SUCCESS';
export const EXPORTITEMPRICELIST_FAILURE = 'EXPORTITEMPRICELIST_FAILURE';

// Navigation Actions
export const PUSHNAVIGATION_REQUEST = 'PUSHNAVIGATION_REQUEST';

export const REPLACENAVIGATION_REQUEST = 'REPLACENAVIGATION_REQUEST';

export const BACKNAVIGATION_REQUEST = 'BACKNAVIGATION_REQUEST';

export const RESETNAVIGATION_REQUEST = 'RESETNAVIGATION_REQUEST';

// Side bar
export const TOGGLESIDEBAR_REQUEST = 'TOGGLESIDEBAR_REQUEST';

// Platform usage
export const MODULEDATA_REQUEST = 'MODULEDATA_REQUEST';
export const MODULEDATA_SUCCESS = 'MODULEDATA_SUCCESS';
export const MODULEDATA_FAILURE = 'MODULEDATA_FAILURE';

export const USERDATA_REQUEST = 'USERDATA_REQUEST';
export const USERDATA_SUCCESS = 'USERDATA_SUCCESS';
export const USERDATA_FAILURE = 'USERDATA_FAILURE';

export const ACTIONDATA_REQUEST = 'ACTIONDATA_REQUEST';
export const ACTIONDATA_SUCCESS = 'ACTIONDATA_SUCCESS';
export const ACTIONDATA_FAILURE = 'ACTIONDATA_FAILURE';

// Instant Messaging
export const CONNECTMESSAGING = 'CONNECTMESSAGING';
export const DISCONNECTMESSAGING = 'DISCONNECTMESSAGING';

export const FETCHCHATROOMS = 'FETCHALLCHATROOMS';
export const RECEIVECHATROOMS = 'RECEIVEALLCHATROOMS';

export const HOSTCHATROOM = 'HOSTCHATROOM';
export const JOINCHATROOM = 'JOINCHATROOM';

export const HOSTINGCHATROOM = 'HOSTINGCHATROOM';
export const USERJOINEDQUEUE = 'USERJOINEDQUEUE';

export const QUEUEUPDATED = 'QUEUEUPDATED';

export const STARTCONVERSATION = 'STARTCONVERSATION';
export const CONVERSATIONSTARTED = 'CONVERSATIONSTARTED';

export const SENDMESSAGE = 'SENDMESSAGE';
export const RECEIVEMESSAGE = 'RECEIVEMESSAGE';

export const TERMINATECONVERSATION = 'TERMINATECONVERSATION';
export const CONVERSATIONTERMINATED = 'CONVERSATIONTERMINATED';

// Message Archive
export const INSTANTMESSAGEARCHIVE_REQUEST = 'INSTANTMESSAGEARCHIVE_REQUEST';
export const INSTANTMESSAGEARCHIVE_SUCCESS = 'INSTANTMESSAGEARCHIVE_SUCCESS';
export const INSTANTMESSAGEARCHIVE_FAILURE = 'INSTANTMESSAGEARCHIVE_FAILURE';

export const INSTANTMESSAGEARCHIVE_DETAIL_REQUEST = 'INSTANTMESSAGEARCHIVE_DETAIL_REQUEST';
export const INSTANTMESSAGEARCHIVE_DETAIL_SUCCESS = 'INSTANTMESSAGEARCHIVE_DETAIL_SUCCESS';
export const INSTANTMESSAGEARCHIVE_DETAIL_FAILURE = 'INSTANTMESSAGEARCHIVE_DETAIL_FAILURE';

// Site ID Applications
export const SITEID_APPLICATIONS_REQUEST = 'SITEID_APPLICATIONS_REQUEST';
export const SITEID_APPLICATIONS_SUCCESS = 'SITEID_APPLICATIONS_SUCCESS';
export const SITEID_APPLICATIONS_FAILURE = 'SITEID_APPLICATIONS_FAILURE';

export const SITEID_APPLICATIONS_INACTIVE_REQUEST = 'SITEID_APPLICATIONS_INACTIVE_REQUEST';
export const SITEID_APPLICATIONS_INACTIVE_SUCCESS = 'SITEID_APPLICATIONS_INACTIVE_SUCCESS';
export const SITEID_APPLICATIONS_INACTIVE_FAILURE = 'SITEID_APPLICATIONS_INACTIVE_FAILURE';

export const SITEID_OPEN_REQUEST = 'SITEID_OPEN_REQUEST';
export const SITEID_OPEN_SUCCESS = 'SITEID_OPEN_SUCCESS';
export const SITEID_OPEN_FAILURE = 'SITEID_OPEN_FAILURE';

export const SITEID_EQUITY_REQUEST = 'SITEID_EQUITY_REQUEST';
export const SITEID_EQUITY_SUCCESS = 'SITEID_EQUITY_SUCCESS';
export const SITEID_EQUITY_FAILURE = 'SITEID_EQUITY_FAILURE';

export const SITEID_DTMP_REQUEST = 'SITEID_DTMP_REQUEST';
export const SITEID_DTMP_SUCCESS = 'SITEID_DTMP_SUCCESS';
export const SITEID_DTMP_FAILURE = 'SITEID_DTMP_FAILURE';

export const SITEID_APPLICATIONDETAIL_REQUEST = 'SITEID_APPLICATIONDETAIL_REQUEST';
export const SITEID_APPLICATIONDETAIL_SUCCESS = 'SITEID_APPLICATIONDETAIL_SUCCESS';
export const SITEID_APPLICATIONDETAIL_FAILURE = 'SITEID_APPLICATIONDETAIL_FAILURE';

export const SITEID_SAVEAPPLICATIONDETAIL_REQUEST = 'SITEID_SAVEAPPLICATIONDETAIL_REQUEST';
export const SITEID_SAVEAPPLICATIONDETAIL_SUCCESS = 'SITEID_SAVEAPPLICATIONDETAIL_SUCCESS';
export const SITEID_SAVEAPPLICATIONDETAIL_FAILURE = 'SITEID_SAVEAPPLICATIONDETAIL_FAILURE';

export const SITEID_SAVECOMMENT_REQUEST = 'SITEID_SAVECOMMENT_REQUEST';
export const SITEID_SAVECOMMENT_SUCCESS = 'SITEID_SAVECOMMENT_SUCCESS';
export const SITEID_SAVECOMMENT_FAILURE = 'SITEID_SAVECOMMENT_FAILURE';

export const SITEID_BULKCOMMENT_START = 'SITEID_BULKCOMMENT_START';
export const SITEID_BULKSAVECOMMENT_REQUEST = 'SITEID_BULKSAVECOMMENT_REQUEST';
export const SITEID_BULKSAVECOMMENT_SUCCESS = 'SITEID_BULKSAVECOMMENT_SUCCESS';
export const SITEID_BULKSAVECOMMENT_FAILURE = 'SITEID_BULKSAVECOMMENT_FAILURE';

export const SITEID_BULKSAVERELATE_REQUEST = 'SITEID_BULKSAVERELATE_REQUEST';
export const SITEID_BULKSAVERELATE_SUCCESS = 'SITEID_BULKSAVERELATE_SUCCESS';
export const SITEID_BULKSAVERELATE_FAILURE = 'SITEID_BULKSAVERELATE_FAILURE';

export const SITEID_BULKSAVEUNRELATE_REQUEST = 'SITEID_BULKSAVERELATE_REQUEST';
export const SITEID_BULKSAVEUNRELATE_SUCCESS = 'SITEID_BULKSAVERELATE_SUCCESS';
export const SITEID_BULKSAVEUNRELATE_FAILURE = 'SITEID_BULKSAVERELATE_FAILURE';

export const SITEID_DELETECOMMENT_REQUEST = 'SITEID_DELETECOMMENT_REQUEST';
export const SITEID_DELETECOMMENT_SUCCESS = 'SITEID_DELETECOMMENT_SUCCESS';
export const SITEID_DELETECOMMENT_FAILURE = 'SITEID_DELETECOMMENT_FAILURE';

export const SITEID_UPDATEMAP_REQUEST = 'SITEID_UPDATEMAP_REQUEST';
export const SITEID_UPDATEMAP_SUCCESS = 'SITEID_UPDATEMAP_SUCCESS';
export const SITEID_UPDATEMAP_FAILURE = 'SITEID_UPDATEMAP_FAILURE';

export const SITEID_DOCUMENTSAVE_REQUEST = 'SITEID_DOCUMENTSAVE_REQUEST';
export const SITEID_DOCUMENTSAVE_SUCCESS = 'SITEID_DOCUMENTSAVE_SUCCESS';
export const SITEID_DOCUMENTSAVE_FAILURE = 'SITEID_DOCUMENTSAVE_FAILURE';

export const SITEID_DOCUMENTDELETE_REQUEST = 'SITEID_DOCUMENTDELETE_REQUEST';
export const SITEID_DOCUMENTDELETE_SUCCESS = 'SITEID_DOCUMENTDELETE_SUCCESS';
export const SITEID_DOCUMENTDELETE_FAILURE = 'SITEID_DOCUMENTDELETE_FAILURE';

export const SITEID_EXTEND_REQUEST = 'SITEID_EXTEND_REQUEST';
export const SITEID_EXTEND_SUCCESS = 'SITEID_EXTEND_SUCCESS';
export const SITEID_EXTEND_FAILURE = 'SITEID_EXTEND_FAILURE';

export const SITEID_PROGRESSSTATUS_REQUEST = 'SITEID_PROGRESSSTATUS_REQUEST';
export const SITEID_PROGRESSSTATUS_SUCCESS = 'SITEID_PROGRESSSTATUS_SUCCESS';
export const SITEID_PROGRESSSTATUS_FAILURE = 'SITEID_PROGRESSSTATUS_FAILURE';

export const SITEID_BULKSTATUS_START = 'SITEID_BULKSTATUS_START';
export const SITEID_BULKPROGRESSSTATUS_REQUEST = 'SITEID_BULKPROGRESSSTATUS_REQUEST';
export const SITEID_BULKPROGRESSSTATUS_SUCCESS = 'SITEID_BULKPROGRESSSTATUS_SUCCESS';
export const SITEID_BULKPROGRESSSTATUS_FAILURE = 'SITEID_BULKPROGRESSSTATUS_FAILURE';

export const SITEID_UPLOADEQUITYSTORES_REQUEST = 'SITEID_UPLOADEQUITYSTORES_REQUEST';
export const SITEID_UPLOADEQUITYSTORES_SUCCESS = 'SITEID_UPLOADEQUITYSTORES_SUCCESS';
export const SITEID_UPLOADEQUITYSTORES_FAILURE = 'SITEID_UPLOADEQUITYSTORES_FAILURE';

export const SITEID_COMPANIES_REQUEST = 'SITEID_COMPANIES_REQUEST';
export const SITEID_COMPANIES_SUCCESS = 'SITEID_COMPANIES_SUCCESS';
export const SITEID_COMPANIES_FAILURE = 'SITEID_COMPANIES_FAILURE';

export const SITEID_COMPANY_REQUEST = 'SITEID_COMPANY_REQUEST';
export const SITEID_COMPANY_SUCCESS = 'SITEID_COMPANY_SUCCESS';
export const SITEID_COMPANY_FAILURE = 'SITEID_COMPANY_FAILURE';

export const SITEID_SAVECOMPANY_REQUEST = 'SITEID_SAVECOMPANY_REQUEST';
export const SITEID_SAVECOMPANY_SUCCESS = 'SITEID_SAVECOMPANY_SUCCESS';
export const SITEID_SAVECOMPANY_FAILURE = 'SITEID_SAVECOMPANY_FAILURE';

export const SITEID_DELETECOMPANY_REQUEST = 'SITEID_DELETECOMPANY_REQUEST';
export const SITEID_DELETECOMPANY_SUCCESS = 'SITEID_DELETECOMPANY_SUCCESS';
export const SITEID_DELETECOMPANY_FAILURE = 'SITEID_DELETECOMPANY_FAILURE';

export const SITEID_UPLOADDTMPFILE_REQUEST = 'SITEID_UPLOADDTMPFILE_REQUEST';
export const SITEID_UPLOADDTMPFILE_SUCCESS = 'SITEID_UPLOADDTMPFILE_SUCCESS';
export const SITEID_UPLOADDTMPFILE_FAILURE = 'SITEID_UPLOADDTMPFILE_FAILURE';

export const SITEID_DEFAULTRESPONSE_REQUEST = 'SITEID_DEFAULTRESPONSE_REQUEST';
export const SITEID_DEFAULTRESPONSE_SUCCESS = 'SITEID_DEFAULTRESPONSE_SUCCESS';
export const SITEID_DEFAULTRESPONSE_FAILURE = 'SITEID_DEFAULTRESPONSE_FAILURE';
export const SITEID_DEFAULTRESPONSES_REQUEST = 'SITEID_DEFAULTRESPONSES_REQUEST';
export const SITEID_DEFAULTRESPONSES_SUCCESS = 'SITEID_DEFAULTRESPONSES_SUCCESS';
export const SITEID_DEFAULTRESPONSES_FAILURE = 'SITEID_DEFAULTRESPONSES_FAILURE';
export const SITEID_SAVERESPONSE_REQUEST = 'SITEID_SAVERESPONSE_REQUEST';
export const SITEID_SAVERESPONSE_SUCCESS = 'SITEID_SAVERESPONSE_SUCCESS';
export const SITEID_SAVERESPONSE_FAILURE = 'SITEID_SAVERESPONSE_FAILURE';
export const SITEID_DELETERESPONSE_REQUEST = 'SITEID_DELETERESPONSE_REQUEST';
export const SITEID_DELETERESPONSE_SUCCESS = 'SITEID_DELETERESPONSE_SUCCESS';
export const SITEID_DELETERESPONSE_FAILURE = 'SITEID_DELETERESPONSE_FAILURE';

export const BULKAPPLICATIONUPLOAD_REQUEST = 'BULKAPPLICATIONUPLOAD_REQUEST';
export const BULKAPPLICATIONUPLOAD_SUCCESS = 'BULKAPPLICATIONUPLOAD_SUCCESS';
export const BULKAPPLICATIONUPLOAD_FAILURE = 'BULKAPPLICATIONUPLOAD_FAILURE';


// Site ID Reporting
export const SITEIDREPORT_REFERENCEDATA_REQUEST = 'SITEIDREPORT_REFERENCEDATA_REQUEST';
export const SITEIDREPORT_REFERENCEDATA_SUCCESS = 'SITEIDREPORT_REFERENCEDATA_SUCCESS';
export const SITEIDREPORT_REFERENCEDATA_FAILURE = 'SITEIDREPORT_REFERENCEDATA_FAILURE';

export const SITEIDREPORT_REPORTDATA_REQUEST = 'SITEIDREPORT_REPORTDATA_REQUEST';
export const SITEIDREPORT_REPORTDATA_SUCCESS = 'SITEIDREPORT_REPORTDATA_SUCCESS';
export const SITEIDREPORT_REPORTDATA_FAILURE = 'SITEIDREPORT_REPORTDATA_FAILURE';

// ORDER TRACKER
export const ORDERTRACKER_CLEARSTATE = 'ORDERTRACKER_CLEARSTATE';
export const ORDERTRACKER_GETORDER_REQUEST = 'ORDERTRACKER_GETORDER_REQUEST';
export const ORDERTRACKER_GETORDER_SUCCESS = 'ORDERTRACKER_GETORDER_SUCCESS';
export const ORDERTRACKER_GETORDER_FAILURE = 'ORDERTRACKER_GETORDER_FAILURE';
export const ORDERTRACKER_SAVEORRDER_REQUEST = 'ORDERTRACKER_SAVEORDER_REQUEST';
export const ORDERTRACKER_SAVEORRDER_SUCCESS = 'ORDERTRACKER_SAVEORDER_SUCCESS';
export const ORDERTRACKER_SAVEORRDER_FAILURE = 'ORDERTRACKER_SAVEORDER_FAILURE';
export const ORDERTRACKER_SEARCH_REQUEST = 'ORDERTRACKER_SEARCH_REQUEST';
export const ORDERTRACKER_SEARCH_SUCCESS = 'ORDERTRACKER_SEARCH_SUCCESS';
export const ORDERTRACKER_SEARCH_FAILURE = 'ORDERTRACKER_SEARCH_FAILURE';
export const ORDERTRACKER_EXPORT_REQUEST = 'ORDERTRACKER_EXPORT_REQUEST';
export const ORDERTRACKER_EXPORT_SUCCESS = 'ORDERTRACKER_EXPORT_SUCCESS';
export const ORDERTRACKER_EXPORT_FAILURE = 'ORDERTRACKER_EXPORT_FAILURE';
export const ORDERTRACKER_CUSTOMERSUMMARY_REQUEST = 'ORDERTRACKER_CUSTOMERSUMMARY_REQUEST';
export const ORDERTRACKER_CUSTOMERSUMMARY_SUCCESS = 'ORDERTRACKER_CUSTOMERSUMMARY_SUCCESS';
export const ORDERTRACKER_CUSTOMERSUMMARY_FAILURE = 'ORDERTRACKER_CUSTOMERSUMMARY_FAILURE';
export const ORDERTRACKER_CUSTOMERSUMMARYEXPORT_REQUEST = 'ORDERTRACKER_CUSTOMERSUMMARYEXPORT_REQUEST';
export const ORDERTRACKER_CUSTOMERSUMMARYEXPORT_SUCCESS = 'ORDERTRACKER_CUSTOMERSUMMARYEXPORT_SUCCESS';
export const ORDERTRACKER_CUSTOMERSUMMARYEXPORT_FAILURE = 'ORDERTRACKER_CUSTOMERSUMMARYEXPORT_FAILURE';
export const ORDERTRACKER_CONTACT_REQUEST = 'ORDERTRACKER_CONTACT_REQUEST';
export const ORDERTRACKER_CONTACT_SUCCESS = 'ORDERTRACKER_CONTACT_SUCCESS';
export const ORDERTRACKER_CONTACT_FAILURE = 'ORDERTRACKER_CONTACT_FAILURE';
export const ORDERTRACKER_LOADBULK_REQUEST = 'ORDERTRACKER_LOADBULK_REQUEST';
export const ORDERTRACKER_LOADBULK_SUCCESS = 'ORDERTRACKER_LOADBULK_SUCCESS';
export const ORDERTRACKER_LOADBULK_FAILURE = 'ORDERTRACKER_LOADBULK_FAILURE';
export const ORDERTRACKER_SAVEBULK_REQUEST = 'ORDERTRACKER_SAVEBULK_REQUEST';
export const ORDERTRACKER_SAVEBULK_SUCCESS = 'ORDERTRACKER_SAVEBULK_SUCCESS';
export const ORDERTRACKER_SAVEBULK_FAILURE = 'ORDERTRACKER_SAVEBULK_FAILURE';
export const ORDERTRACKER_DASHBOARD_REQUEST = 'ORDERTRACKER_DASHBOARD_REQUEST';
export const ORDERTRACKER_DASHBOARD_SUCCESS = 'ORDERTRACKER_DASHBOARD_SUCCESS';
export const ORDERTRACKER_DASHBOARD_FAILURE = 'ORDERTRACKER_DASHBOARD_FAILURE';
export const ORDERTRACKER_DASHBOARDCUSTOMER_REQUEST = 'ORDERTRACKER_DASHBOARDCUSTOMER_REQUEST';
export const ORDERTRACKER_DASHBOARDCUSTOMER_SUCCESS = 'ORDERTRACKER_DASHBOARDCUSTOMER_SUCCESS';
export const ORDERTRACKER_DASHBOARDCUSTOMER_FAILURE = 'ORDERTRACKER_DASHBOARDCUSTOMER_FAILURE';
export const ORDERTRACKER_CUSTOMERSEARCH_REQUEST = 'ORDERTRACKER_CUSTOMERSEARCH_REQUEST';
export const ORDERTRACKER_CUSTOMERSEARCH_SUCCESS = 'ORDERTRACKER_CUSTOMERSEARCH_SUCCESS';
export const ORDERTRACKER_CUSTOMERSEARCH_FAILURE = 'ORDERTRACKER_CUSTOMERSEARCH_FAILURE';
export const ORDERTRACKER_CUSTOMERSTORES_REQUEST = 'ORDERTRACKER_CUSTOMERSTORES_REQUEST';
export const ORDERTRACKER_CUSTOMERSTORES_SUCCESS = 'ORDERTRACKER_CUSTOMERSTORES_SUCCESS';
export const ORDERTRACKER_CUSTOMERSTORES_FAILURE = 'ORDERTRACKER_CUSTOMERSTORES_FAILURE';

//APAC Order Tracker
export const APAC_ORDERTRACKER_CLEARSTATE = 'APAC_ORDERTRACKER_CLEARSTATE';

export const APAC_ORDERTRACKER_SHIPMENT_REQUEST = 'APAC_ORDERTRACKER_SHIPMENT_REQUEST';
export const APAC_ORDERTRACKER_SHIPMENT_SUCCESS = 'APAC_ORDERTRACKER_SHIPMENT_SUCCESS';
export const APAC_ORDERTRACKER_SHIPMENT_FAILURE = 'APAC_ORDERTRACKER_SHIPMENT_FAILURE';
export const APAC_SHIPMENTLOGUPLOAD_REQUEST = 'APAC_SHIPMENTLOGUPLOAD_REQUEST';
export const APAC_SHIPMENTLOGUPLOAD_SUCCESS = 'APAC_SHIPMENTLOGUPLOAD_SUCCESS';
export const APAC_SHIPMENTLOGUPLOAD_FAILURE = 'APAC_SHIPMENTLOGUPLOAD_FAILURE';
export const APAC_SAVEORDER_REQUEST = 'APAC_SAVEORDER_REQUEST';
export const APAC_SAVEORDER_SUCCESS = 'APAC_SAVEORDER_SUCCESS';
export const APAC_SAVEORDER_FAILURE = 'APAC_SAVEORDER_FAILURE';

export const APAC_ORDERTRACKER_GETORDERS_REQUEST = 'APAC_ORDERTRACKER_GETORDERS_REQUEST';
export const APAC_ORDERTRACKER_GETORDERS_SUCCESS = 'APAC_ORDERTRACKER_GETORDERS_SUCCESS';
export const APAC_ORDERTRACKER_GETORDERS_FAILURE = 'APAC_ORDERTRACKER_GETORDERS_FAILURE';
export const APAC_ORDERTRACKER_INPROGRESSSUMMARY_REQUEST = 'APAC_ORDERTRACKER_INPROGRESSSUMMARY_REQUEST';
export const APAC_ORDERTRACKER_INPROGRESSSUMMARY_SUCCESS = 'APAC_ORDERTRACKER_INPROGRESSSUMMARY_SUCCESS';
export const APAC_ORDERTRACKER_INPROGRESSSUMMARY_FAILURE = 'APAC_ORDERTRACKER_INPROGRESSSUMMARY_FAILURE';
export const APAC_ORDERTRACKER_EXPORTORDERS_REQUEST = 'APAC_ORDERTRACKER_EXPORTORDERS_REQUEST';
export const APAC_ORDERTRACKER_EXPORTORDERS_SUCCESS = 'APAC_ORDERTRACKER_EXPORTORDERS_SUCCESS';
export const APAC_ORDERTRACKER_EXPORTORDERS_FAILURE = 'APAC_ORDERTRACKER_EXPORTORDERS_FAILURE';
// KPI dashboard
export const IN_FULL_KPI_CLEARSTATE = 'IN_FULL_KPI_CLEARSTATE';

export const IN_FULL_KPI_REQUEST = 'IN_FULL_KPI_REQUEST';
export const IN_FULL_KPI_FAILURE = 'IN_FULL_KPI_FAILURE';

export const GET_STORE_GROUPS_SUCCESS = 'GET_STORE_GROUPS_SUCCESS';

export const GET_STORES_SUCCESS = 'GET_STORES_SUCCESS';

export const GET_LATEST_IMPORT_SUCCESS = 'GET_LATEST_IMPORT_SUCCESS';

export const GET_KPI_CONFIGURATIONS_SUCCESS = 'GET_KPI_CONFIGURATIONS_SUCCESS';

export const UPDATE_KPI_CONFIGURATIONS_SUCCESS = 'UPDATE_KPI_CONFIGURATIONS_SUCCESS';

export const IN_FULL_KPI_DATA_REQUEST = 'IN_FULL_KPI_DATA_REQUEST';
export const IN_FULL_KPI_DATA_SUCCESS = 'IN_FULL_KPI_DATA_SUCCESS';

export const FAILEDITEMS_REQUEST = 'FAILEDITEMS_REQUEST';
export const FAILEDITEMS_SUCCESS = 'FAILEDITEMS_SUCCESS';

// Connect Action Types
export const CONNECT_TOGGLESIDEBAR_REQUEST = 'CONNECT_TOGGLESIDEBAR_REQUEST';
export const CONNECT_TOGGLEMENU_REQUEST = 'CONNECT_TOGGLEMENU_REQUEST';

export const CONNECT_CONFIGURATION_REQUEST = 'CONNECT_CONFIGURATION_REQUEST';
export const CONNECT_CONFIGURATION_SUCCESS = 'CONNECT_CONFIGURATION_SUCCESS';
export const CONNECT_CONFIGURATION_FAILURE = 'CONNECT_CONFIGURATION_FAILURE';
export const CONNECT_CONFIGURATIONSAVE_REQUEST = 'CONNECT_CONFIGURATIONSAVE_REQUEST';
export const CONNECT_CONFIGURATIONSAVE_SUCCESS = 'CONNECT_CONFIGURATIONSAVE_SUCCESS';
export const CONNECT_CONFIGURATIONSAVE_FAILURE = 'CONNECT_CONFIGURATIONSAVE_FAILURE';

export const CONNECT_GETCONTENTBUILDER_REQUEST = 'CONNECT_GETCONTENTBUILDER_REQUEST';
export const CONNECT_GETCONTENTBUILDER_SUCCESS = 'CONNECT_GETCONTENTBUILDER_SUCCESS';
export const CONNECT_GETCONTENTBUILDER_FAILURE = 'CONNECT_GETCONTENTBUILDER_FAILURE';
export const CONNECT_CONTENTBUILDERASSETS_REQUEST = 'CONNECT_CONTENTBUILDERASSETS_REQUEST';
export const CONNECT_CONTENTBUILDERASSETS_SUCCESS = 'CONNECT_CONTENTBUILDERASSETS_SUCCESS';
export const CONNECT_CONTENTBUILDERASSETS_FAILURE = 'CONNECT_CONTENTBUILDERASSETS_FAILURE';
export const CONNECT_CONTENTBUILDERBACKGROUNDS_REQUEST = 'CONNECT_CONTENTBUILDERBACKGROUNDS_REQUEST';
export const CONNECT_CONTENTBUILDERBACKGROUNDS_SUCCESS = 'CONNECT_CONTENTBUILDERBACKGROUNDS_SUCCESS';
export const CONNECT_CONTENTBUILDERBACKGROUNDS_FAILURE = 'CONNECT_CONTENTBUILDERBACKGROUNDS_FAILURE';
export const CONNECT_CONTENTBUILDERATTACHMENTS_REQUEST = 'CONNECT_CONTENTBUILDERATTACHMENTS_REQUEST';
export const CONNECT_CONTENTBUILDERATTACHMENTS_SUCCESS = 'CONNECT_CONTENTBUILDERATTACHMENTS_SUCCESS';
export const CONNECT_CONTENTBUILDERATTACHMENTS_FAILURE = 'CONNECT_CONTENTBUILDERATTACHMENTS_FAILURE';
export const CONNECT_SAVECONTENTBUILDER_REQUEST = 'CONNECT_SAVECONTENTBUILDER_REQUEST';
export const CONNECT_SAVECONTENTBUILDER_SUCCESS = 'CONNECT_SAVECONTENTBUILDER_SUCCESS';
export const CONNECT_SAVECONTENTBUILDER_FAILURE = 'CONNECT_SAVECONTENTBUILDER_FAILURE';
export const CONNECT_ARCHIVECONTENTBUILDER_REQUEST = 'CONNECT_ARCHIVECONTENTBUILDER_REQUEST';
export const CONNECT_ARCHIVECONTENTBUILDER_SUCCESS = 'CONNECT_ARCHIVECONTENTBUILDER_SUCCESS';
export const CONNECT_ARCHIVECONTENTBUILDER_FAILURE = 'CONNECT_ARCHIVECONTENTBUILDER_FAILURE';
export const CONNECT_DELETECONTENTBUILDER_REQUEST = 'CONNECT_DELETECONTENTBUILDER_REQUEST';
export const CONNECT_DELETECONTENTBUILDER_SUCCESS = 'CONNECT_DELETECONTENTBUILDER_SUCCESS';
export const CONNECT_DELETECONTENTBUILDER_FAILURE = 'CONNECT_DELETECONTENTBUILDER_FAILURE';

export const CONNECT_ASSETCATEGORIES_REQUEST = 'CONNECT_ASSETCATEGORIES_REQUEST';
export const CONNECT_ASSETCATEGORIES_SUCCESS = 'CONNECT_ASSETCATEGORIES_SUCCESS';
export const CONNECT_ASSETCATEGORIES_FAILURE = 'CONNECT_ASSETCATEGORIES_FAILURE';
export const CONNECT_ASSETCATEGORYSAVE_SUCCESS = 'CONNECT_ASSETCATEGORYSAVE_SUCCESS';
export const CONNECT_ASSETCATEGORYSAVE_FAILURE = 'CONNECT_ASSETCATEGORYSAVE_FAILURE';
export const CONNECT_ASSETCATEGORYSAVE_REQUEST = 'CONNECT_ASSETCATEGORYSAVE_REQUEST';
export const CONNECT_ASSETCATEGORYDELETE_REQUEST = 'CONNECT_ASSETCATEGORYDELETE_REQUEST';
export const CONNECT_ASSETCATEGORYDELETE_SUCCESS = 'CONNECT_ASSETCATEGORYDELETE_SUCCESS';
export const CONNECT_ASSETCATEGORYDELETE_FAILURE = 'CONNECT_ASSETCATEGORYDELETE_FAILURE';

export const CONNECT_ASSETS_REQUEST = 'CONNECT_ASSETS_REQUEST';
export const CONNECT_ASSETS_SUCCESS = 'CONNECT_ASSETS_SUCCESS';
export const CONNECT_ASSETS_FAILURE = 'CONNECT_ASSETS_FAILURE';
export const CONNECT_ASSETSAVE_REQUEST = 'CONNECT_ASSETSAVE_REQUEST';
export const CONNECT_ASSETSAVE_SUCCESS = 'CONNECT_ASSETSAVE_SUCCESS';
export const CONNECT_ASSETSAVE_FAILURE = 'CONNECT_ASSETSAVE_FAILURE';
export const CONNECT_ASSETMOVE_REQUEST = 'CONNECT_ASSETMOVE_REQUEST';
export const CONNECT_ASSETMOVE_SUCCESS = 'CONNECT_ASSETMOVE_SUCCESS';
export const CONNECT_ASSETMOVE_FAILURE = 'CONNECT_ASSETMOVE_FAILURE';
export const CONNECT_ASSETDELETE_REQUEST = 'CONNECT_ASSETDELETE_REQUEST';
export const CONNECT_ASSETDELETE_SUCCESS = 'CONNECT_ASSETDELETE_SUCCESS';
export const CONNECT_ASSETDELETE_FAILURE = 'CONNECT_ASSETDELETE_FAILURE';

export const CONNECT_ASSET_REQUEST = 'CONNECT_ASSET_REQUEST';
export const CONNECT_ASSET_SUCCESS = 'CONNECT_ASSET_SUCCESS';
export const CONNECT_ASSET_FAILURE = 'CONNECT_ASSET_FAILURE';

export const CONNECT_CONTENTDISPLAY_REQUEST = 'CONNECT_CONTENTDISPLAY_REQUEST';
export const CONNECT_CONTENTDISPLAY_SUCCESS = 'CONNECT_CONTENTDISPLAY_SUCCESS';
export const CONNECT_CONTENTDISPLAY_FAILURE = 'CONNECT_CONTENTDISPLAY_FAILURE';
export const CONNECT_CONTENTLIST_REQUEST = 'CONNECT_CONTENTLIST_REQUEST';
export const CONNECT_CONTENTLIST_SUCCESS = 'CONNECT_CONTENTLIST_SUCCESS';
export const CONNECT_CONTENTLIST_FAILURE = 'CONNECT_CONTENTLIST_FAILURE';
export const CONNECT_CONTENTARCHIVE_REQUEST = 'CONNECT_CONTENTARCHIVE_REQUEST';
export const CONNECT_CONTENTARCHIVE_SUCCESS = 'CONNECT_CONTENTARCHIVE_SUCCESS';
export const CONNECT_CONTENTARCHIVE_FAILURE = 'CONNECT_CONTENTARCHIVE_FAILURE';

export const CONNECT_CONTENTLABEL_REQUEST = 'CONNECT_CONTENTLABEL_REQUEST';
export const CONNECT_CONTENTLABEL_SUCCESS = 'CONNECT_CONTENTLABEL_SUCCESS';
export const CONNECT_CONTENTLABEL_FAILURE = 'CONNECT_CONTENTLABEL_FAILURE';
export const CONNECT_CONTENTLABELS_REQUEST = 'CONNECT_CONTENTLABELS_REQUEST';
export const CONNECT_CONTENTLABELS_SUCCESS = 'CONNECT_CONTENTLABELS_SUCCESS';
export const CONNECT_CONTENTLABELS_FAILURE = 'CONNECT_CONTENTLABELS_FAILURE';
export const CONNECT_SAVELABEL_REQUEST = 'CONNECT_SAVELABEL_REQUEST';
export const CONNECT_SAVELABEL_SUCCESS = 'CONNECT_SAVELABEL_SUCCESS';
export const CONNECT_SAVELABEL_FAILURE = 'CONNECT_SAVELABEL_FAILURE';
export const CONNECT_DELETELABEL_REQUEST = 'CONNECT_DELETELABEL_REQUEST';
export const CONNECT_DELETELABEL_SUCCESS = 'CONNECT_DELETELABEL_SUCCESS';
export const CONNECT_DELETELABEL_FAILURE = 'CONNECT_DELETELABEL_FAILURE';

// Connect Tasks
export const CONNECT_GETTASKS_REQUEST = 'CONNECT_GETTASKS_REQUEST';
export const CONNECT_GETTASKS_SUCCESS = 'CONNECT_GETTASKS_SUCCESS';
export const CONNECT_GETTASKS_FAILURE = 'CONNECT_GETTASKS_FAILURE';

export const CONNECT_COMPLETETASK_REQUEST = 'CONNECT_COMPLETETASK_REQUEST';
export const CONNECT_COMPLETETASK_SUCCESS = 'CONNECT_COMPLETETASK_SUCCESS';
export const CONNECT_COMPLETETASK_FAILURE = 'CONNECT_COMPLETETASK_FAILURE';

export const CONNECT_GETTASKLIST_REQUEST = 'CONNECT_GETTASKLIST_REQUEST';
export const CONNECT_GETTASKLIST_SUCCESS = 'CONNECT_GETTASKLIST_SUCCESS';
export const CONNECT_GETTASKLIST_FAILURE = 'CONNECT_GETTASKLIST_FAILURE';

export const CONNECT_GETEDITTASK_REQUEST = 'CONNECT_GETEDITTASK_REQUEST';
export const CONNECT_GETEDITTASK_SUCCESS = 'CONNECT_GETEDITTASK_SUCCESS';
export const CONNECT_GETEDITTASK_FAILURE = 'CONNECT_GETEDITTASK_FAILURE';

export const CONNECT_SAVETASK_REQUEST = 'CONNECT_SAVETASK_REQUEST';
export const CONNECT_SAVETASK_SUCCESS = 'CONNECT_SAVETASK_SUCCESS';
export const CONNECT_SAVETASK_FAILURE = 'CONNECT_SAVETASK_FAILURE';

export const CONNECT_DELETETASK_REQUEST = 'CONNECT_DELETETASK_REQUEST';
export const CONNECT_DELETETASK_SUCCESS = 'CONNECT_DELETETASK_SUCCESS';
export const CONNECT_DELETETASK_FAILURE = 'CONNECT_DELETETASK_FAILURE';

// Weather and Events
export const CONNECT_WEATHER_REQUEST = 'CONNECT_WEATHER_REQUEST';
export const CONNECT_WEATHER_SUCCESS = 'CONNECT_WEATHER_SUCCESS';
export const CONNECT_WEATHER_FAILURE = 'CONNECT_WEATHER_FAILURE';

export const CONNECT_EVENTS_REQUEST = 'CONNECT_EVENTS_REQUEST';
export const CONNECT_EVENTS_SUCCESS = 'CONNECT_EVENTS_SUCCESS';
export const CONNECT_EVENTS_FAILURE = 'CONNECT_EVENTS_FAILURE';

// Connect Reporting & Dashboards
export const CONNECT_DASHBOARD_REQUEST = 'CONNECT_DASHBOARD_REQUEST';
export const CONNECT_DASHBOARD_SUCCESS = 'CONNECT_DASHBOARD_SUCCESS';
export const CONNECT_DASHBOARD_FAILURE = 'CONNECT_DASHBOARD_FAILURE';

export const CONNECT_DASHBOARDNOTIFICATIONS_REQUEST = 'CONNECT_DASHBOARDNOTIFICATIONS_REQUEST';
export const CONNECT_DASHBOARDNOTIFICATIONS_SUCCESS = 'CONNECT_DASHBOARDNOTIFICATIONS_SUCCESS';
export const CONNECT_DASHBOARDNOTIFICATIONS_FAILURE = 'CONNECT_DASHBOARDNOTIFICATIONS_FAILURE';

export const CONNECT_DASHBOARDTASKS_REQUEST = 'CONNECT_DASHBOARDTASKS_REQUEST';
export const CONNECT_DASHBOARDTASKS_SUCCESS = 'CONNECT_DASHBOARDTASKS_SUCCESS';
export const CONNECT_DASHBOARDTASKS_FAILURE = 'CONNECT_DASHBOARDTASKS_FAILURE';

export const CONNECT_USERACTION_REQUEST = 'CONNECT_USERACTION_REQUEST';
export const CONNECT_USERACTION_SUCCESS = 'CONNECT_USERACTION_SUCCESS';
export const CONNECT_USERACTION_FAILURE = 'CONNECT_USERACTION_FAILURE';

//LOSPOS Submitted Request
export const LSPOS_ITEMNUMBER_REQUEST = 'LSPOS_ITEMNUMBER_REQUEST';
export const LSPOS_ITEMNUMBER_SUCCESS = 'LSPOS_ITEMNUMBER_SUCCESS';
export const LSPOS_ITEMNUMBER_FAILURE = 'LSPOS_ITEMNUMBER_FAILURE';
export const LSPOS_ITEMNUMBER_DETAIL_REQUEST = 'LSPOS_ITEMNUMBER_DETAIL_REQUEST';
export const LSPOS_ITEMNUMBER_DETAIL_SUCCESS = 'LSPOS_ITEMNUMBER_DETAIL_SUCCESS';
export const LSPOS_ITEMNUMBER_DETAIL_FAILURE = 'LSPOS_ITEMNUMBER_DETAIL_FAILURE';
export const LSPOS_ITEMNUMBER_SAVE_REQUEST = 'LSPOS_ITEMNUMBER_SAVE_REQUEST';
export const LSPOS_ITEMNUMBER_SAVE_SUCCESS = 'LSPOS_ITEMNUMBER_SAVE_SUCCESS';
export const LSPOS_ITEMNUMBER_SAVE_FAILURE = 'LSPOS_ITEMNUMBER_SAVE_FAILURE';
export const LSPOS_ITEMCATALOGUE_REQUEST = 'LSPOS_ITEMCATALOGUE_REQUEST';
export const LSPOS_ITEMCATALOGUE_SUCCESS = 'LSPOS_ITEMCATALOGUE_SUCCESS';
export const LSPOS_ITEMCATALOGUE_FAILURE = 'LSPOS_ITEMCATALOGUE_FAILURE';
export const LSPOS_ITEMCATALOGUE_DETAIL_REQUEST = 'LSPOS_ITEMCATALOGUE_DETAIL_REQUEST';
export const LSPOS_ITEMCATALOGUE_DETAIL_SUCCESS = 'LSPOS_ITEMCATALOGUE_DETAIL_SUCCESS';
export const LSPOS_ITEMCATALOGUE_DETAIL_FAILURE = 'LSPOS_ITEMCATALOGUE_DETAIL_FAILURE';
export const LSPOS_ITEMCATALOGUE_SAVE_REQUEST = 'LSPOS_ITEMCATALOGUE_SAVE_REQUEST';
export const LSPOS_ITEMCATALOGUE_SAVE_SUCCESS = 'LSPOS_ITEMCATALOGUE_SAVE_SUCCESS';
export const LSPOS_ITEMCATALOGUE_SAVE_FAILURE = 'LSPOS_ITEMCATALOGUE_SAVE_FAILURE';


//LSPOS Admin
export const LSPOS_ITEMNUMBERADMIN_REQUEST = 'LSPOS_ITEMNUMBERADMIN_REQUEST';
export const LSPOS_ITEMNUMBERADMIN_SUCCESS = 'LSPOS_ITEMNUMBERADMIN_SUCCESS';
export const LSPOS_ITEMNUMBERADMIN_FAILURE = 'LSPOS_ITEMNUMBERADMIN_FAILURE';
export const LSPOS_ITEMNUMBERADMIN_DETAIL_REQUEST = 'LSPOS_ITEMNUMBERADMIN_DETAIL_REQUEST';
export const LSPOS_ITEMNUMBERADMIN_DETAIL_SUCCESS = 'LSPOS_IITEMNUMBERADMIN_DETAIL_SUCCESS';
export const LSPOS_ITEMNUMBERADMIN_DETAIL_FAILURE = 'LSPOS_ITEMNUMBERADMIN_DETAIL_FAILURE';
export const LSPOS_ITEMNUMBERADMIN_SAVE_REQUEST = 'LSPOS_ITEMNUMBERADMIN_SAVE_REQUEST';
export const LSPOS_ITEMNUMBERADMIN_SAVE_SUCCESS = 'LSPOS_ITEMNUMBERADMIN_SAVE_SUCCESS';
export const LSPOS_ITEMNUMBERADMIN_SAVE_FAILURE = 'LSPOS_ITEMNUMBERADMIN_SAVE_FAILURE';
export const LSPOS_MDIO_REQUEST = 'LSPOS_MDIO_REQUEST';
export const LSPOS_MDIO_SUCCESS = 'LSPOS_MDIO_SUCCESS';
export const LSPOS_MDIO_FAILURE = 'LSPOS_MDIO_FAILURE';
export const LSPOS_MDIO_DETAIL_REQUEST = 'LSPOS_MDIO_DETAIL_REQUEST';
export const LSPOS_MDIO_DETAIL_SUCCESS = 'LSPOS_MDIO_DETAIL_SUCCESS';
export const LSPOS_MDIO_DETAIL_FAILURE = 'LSPOS_MDIO_DETAIL_FAILURE';
export const LSPOS_MDIO_SAVE_REQUEST = 'LSPOS_MDIO_SAVE_REQUEST';
export const LSPOS_MDIO_SAVE_SUCCESS = 'LSPOS_MDIO_SAVE_SUCCESS';
export const LSPOS_MDIO_SAVE_FAILURE = 'LSPOS_MDIO_SAVE_FAILURE';
export const LSPOS_MDIO_BULKSAVE_REQUEST = 'LSPOS_MDIO_BULKSAVE_REQUEST';
export const LSPOS_MDIO_BULKSAVE_SUCCESS = 'LSPOS_MDIO_BULKSAVE_SUCCESS';
export const LSPOS_MDIO_BULKSAVE_FAILURE = 'LSPOS_MDIO_BULKSAVE_FAILURE';

//LSPOS Email Management
export const LSPOS_EMAILCONFIG_REQUEST = 'LSPOS_EMAILCONFIG_REQUEST';
export const LSPOS_EMAILCONFIG_SUCCESS = 'LSPOS_EMAILCONFIG_SUCCESS';
export const LSPOS_EMAILCONFIG_FAILURE = 'LSPOS_EMAILCONFIG_FAILURE';
export const LSPOS_EMAILCONFIG_SAVE_REQUEST = 'LSPOS_EMAILCONFIG_SAVE_REQUEST';
export const LSPOS_EMAILCONFIG_SAVE_SUCCESS = 'LSPOS_EMAILCONFIG_SAVE_SUCCESS';
export const LSPOS_EMAILCONFIG_SAVE_FAILURE = 'LSPOS_EMAILCONFIG_SAVE_FAILURE';

//Chat bot 
export const CHAT_TOKEN_REQUEST = 'CHAT_TOKEN_REQUEST';
export const CHAT_TOKEN_SUCCESS = 'CHAT_TOKEN_SUCCESS';
export const CHAT_TOKEN_FAILURE = 'CHAT_TOKEN_FAILURE';

//Apac Order Documents
export const APAC_ORDERDOCUMENTS_REQUEST = 'APAC_ORDERDOCUMENTS_REQUEST';
export const APAC_ORDERDOCUMENTS_SUCCESS = 'APAC_ORDERDOCUMENTS_SUCCESS';
export const APAC_ORDERDOCUMENTS_FAILURE = 'APAC_ORDERDOCUMENTS_FAILURE';
export const APAC_ORDERDOCUMENTS_CLEARSTATE = 'APAC_ORDERDOCUMENTS_CLEARSTATE';
export const APAC_ORDERDOCUMENTS_SAVE_REQUEST = 'APAC_ORDERDOCUMENTS_SAVE_REQUEST';
export const APAC_ORDERDOCUMENTS_SAVE_SUCCESS = 'APAC_ORDERDOCUMENTS_SAVE_SUCCESS';
export const APAC_ORDERDOCUMENTS_SAVE_FAILURE = 'APAC_ORDERDOCUMENTS_SAVE_FAILURE';
export const APAC_ORDERDOCUMENTS_DELETE_REQUEST = 'APAC_ORDERDOCUMENTS_DELETE_REQUEST';
export const APAC_ORDERDOCUMENTS_DELETE_SUCCESS = 'APAC_ORDERDOCUMENTS_DELETE_SUCCESS';
export const APAC_ORDERDOCUMENTS_DELETE_FAILURE = 'APAC_ORDERDOCUMENTS_DELETE_FAILURE';


//APAC Forecast Perfomance Dashboards
export const GETFORECASTDASHBOARD_REQUEST = 'GETFORECASTDASHBOARD_REQUEST';
export const GETORDERMETRICS_REQUEST = 'GETORDERMETRICS_REQUEST';
export const GETORDERMETRICS_SUCCESS = 'GETORDERMETRICS_SUCCESS';
export const GETORDERMETRICS_FAILURE = 'GETORDERMETRICS_FAILURE';

export const GETSHIPMENTPERFORMANCE_REQUEST = 'GETSHIPMENTPERFORMANCE_REQUEST';
export const GETSHIPMENTPERFORMANCE_SUCCESS = 'GETSHIPMENTPERFORMANCE_SUCCESS';
export const GETSHIPMENTPERFORMANCE_FAILURE = 'GETSHIPMENTPERFORMANCE_FAILURE';

export const GETMBUS_REQUEST = 'GETMBUS_REQUEST';
export const GETMBUS_SUCCESS = 'GETMBUS_SUCCESS';
export const GETMBUS_FAILURE = 'GETMBUS_FAILURE';

export const GETEMERGENCYORDER_REQUEST = 'GETEMERGENCYORDER_REQUEST';
export const GETEMERGENCYORDER_SUCCESS = 'GETEMERGENCYORDER_SUCCESS';
export const GETEMERGENCYORDER_FAILURE = 'GETEMERGENCYORDER_FAILURE';

export const GETFORECASTCHANGEREQUEST_REQUEST = 'GETFORECASTCHANGEREQUEST_REQUEST';
export const GETFORECASTCHANGEREQUEST_SUCCESS = 'GETFORECASTCHANGEREQUEST_SUCCESS';
export const GETFORECASTCHANGEREQUEST_FAILURE = 'GETFORECASTCHANGEREQUEST_FAILURE';

export const GETKPI_REQUEST = 'GETKPI_REQUEST';
export const GETKPI_SUCCESS = 'GETKPI_SUCCESS';
export const GETKPI_FAILURE = 'GETKPI_FAILURE';

export const GETMAPE_REQUEST = 'GETMAPE_REQUEST';
export const GETMAPE_SUCCESS = 'GETMAPE_SUCCESS';
export const GETMAPE_FAILURE = 'GETMAPE_FAILURE';

export const GETMAPEBYCATEGORY_REQUEST = 'GETMAPEBYCATEGORY_REQUEST';
export const GETMAPEBYCATEGORY_SUCCESS = 'GETMAPEBYCATEGORY_SUCCESS';
export const GETMAPEBYCATEGORY_FAILURE = 'GETMAPEBYCATEGORY_FAILURE';

export const GETMAPEBYTOPITEM_REQUEST = 'GETMAPEBYTOPITEM_REQUEST';
export const GETMAPEBYTOPITEM_SUCCESS = 'GETMAPEBYTOPITEM_SUCCESS';
export const GETMAPEBYTOPITEM_FAILURE = 'GETMAPEBYTOPITEM_FAILURE';


export const GETFORECASTVSORDER_REQUEST = 'GETFORECASTVSORDER_REQUEST';
export const GETFORECASTVSORDER_SUCCESS = 'GETFORECASTVSORDER_SUCCESS';
export const GETFORECASTVSORDER_FAILURE = 'GETFORECASTVSORDER_FAILURE';

export const GETPRODUCTCATEGORIES_SUCCESS = 'GETPRODUCTCATEGORIES_SUCCESS';
export const GETPRODUCTCATEGORIES_FAILURE = 'GETPRODUCTCATEGORIES_FAILURE';

export const GETKPIREFERENCEDATA_SUCCESS = 'GETKPIREFERENCEDATA_SUCCESS';
export const GETKPIREFERENCEDATA_FAILURE = 'GETKPIREFERENCEDATA_FAILURE';

export const EXPORTDATA_REQUEST = 'EXPORTDATA_REQUEST';
export const EXPORTDATA_SUCCESS = 'EXPORTDATA_SUCCESS';
export const EXPORTDATA_FAILURE = 'EXPORTDATA_FAILURE';
