import React from 'react';

class PriorityKey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: true,
    };
  }

  render() {
    const {
      isExpanded,
    } = this.state;

    const { canViewPriorityAreas } = this.props;

    if (!canViewPriorityAreas) {
      return null;
    }

    return (

      <div className="equity-wrapper-priority">
        {/*<div className="key-header-priority">
          <div>
            <button
              type="button"
              className={isExpanded ? 'close-button' : 'open-button'}
              onClick={() => this.setState(
                { isExpanded: !isExpanded },
              )}
            >
              +
            </button>
          </div>
              </div>*/}
      </div>
    
    );
  }
}

export default PriorityKey;
