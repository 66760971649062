import React from 'react';

export class AdditionalInformationForm extends React.Component {

  render() {
    const {  
      fields, 
      isLicensee,
      applicationDetail,
      locked
    } = this.props;

    const {
      casework,  
      adt,
      adt3,
      adt5,
      at,
      at3,
      at5,
      recapprovalDate,
      equipmentProduction,
      equipmentTechnology,
      lighting,
      furnishings,
      artwork,
      signage,
      //approvalStatus,
      initialInventory,
      buildingAndSiteImprovements,
      leaseholdImprovements,
      nonRefundableKeyMoney,
      overhead,
      totalStoreCosts,
      totalStoreInvestment,
      totalYearsStarbucksControlledLeaseExtOptions,
      approvedFinalResponse,
      submissionStatus,
      approvedFinalConditionNotes,
      conditionalApprovalNotes,
      landlord,
      initialLeaseTermYears,
      otherProfessionalFees,
      y1totalRent,
      y3totalRent1,
      y5totalRent1,
      y1realEstateTaxes,
      y3realEstateTaxes1,
      y5realEstateTaxes1,
      totalLeaseTermYears,
      initialLeaseExpiration,
      designConstructionFees,
      aws,
      aws3,
      aws5,
      dateSentToEmea,
      longStopDate,
      projectNo,
    } = fields;
    
    const {
      projectStatus,
    } = applicationDetail;

    return (
      <div>
        <h3>Additional Information</h3>
        <p>
          <span className="required">*</span>
          {' '}
          These fields are mandatory to
          progress this application.
        </p>
        <div className="form-section">
          <label>
              Submission Status
            <input
              value={submissionStatus}
              readOnly
            />
          </label>

          {projectNo &&
          <label>
            Project No
            <span className="required"> *</span>
            <input
              type="text"
              value={projectNo}
              readOnly={locked}
            />
          </label>}
                    
          {projectStatus &&
          <label>
            Project Status
            <input
              type="text"
              value={projectStatus}
              readOnly={true}
            />
          </label>}
          <label>
             Final Response Date
            <input
              value={recapprovalDate}
              readOnly
            />
          </label>
          {!isLicensee ? (
            <label>
              Date Sent to EMEA
              <input
                value={dateSentToEmea}
                readOnly
              />
            </label>
          ) : null}
          <label>
              Approved Final Response
            <input
              value={approvedFinalResponse}
              readOnly
            />
          </label>
          <label>
             Approved Final Condition Notes
            <textarea
              value={approvedFinalConditionNotes || conditionalApprovalNotes}
              readOnly
            />
          </label>
          <label>
              Long Stop Date
            <input
              value={longStopDate}
              readOnly
            />
          </label>
        </div>
        <div className="form-section">
          <label>
              Landlord
            <input
              value={landlord}
              readOnly
            />
          </label>
          <label>
              Building and Site Improvements
            <input
              value={buildingAndSiteImprovements}
              readOnly
            />
          </label>
          <label>
             Lease Improvements
            <input
              value={leaseholdImprovements}
              readOnly
            />
          </label>
          <label>
              Case Work
            <input
              value={casework}
              readOnly
            />
          </label>
          <label>
              Equipment - Production
            <input
              value={equipmentProduction}
              readOnly
            />
          </label>
          <label>
              Equipment - Technology
            <input
              value={equipmentTechnology}
              readOnly
            />
          </label>
          <label>
              Lighting
            <input
              value={lighting}
              readOnly
            />
          </label>
          <label>
              Furnishings
            <input
              value={furnishings}
              readOnly
            />
          </label>
          <label>
              Artwork
            <input
              value={artwork}
              readOnly
            />
          </label>
          <label>
              Signage
            <input
              value={signage}
              readOnly
            />
          </label>
          <label>
              Other Professional Fees
            <input
              value={otherProfessionalFees}
              readOnly
            />
          </label>
          <label>
              Non-Refundable Key Money
            <input
              value={nonRefundableKeyMoney}
              readOnly
            />
          </label>
          <label>
              Overhead (Equity / JV Only)
            <input
              value={overhead}
              readOnly
            />
          </label>
          <label>
              Design and Construction Fees
            <input
              value={designConstructionFees}
              readOnly
            />
          </label>
          <label>
              Initial Inventory
            <input
              value={initialInventory}
              readOnly
            />
          </label>
          <label>
              Total Store Costs
            <input
              value={totalStoreCosts? totalStoreCosts :totalStoreInvestment}
              readOnly
            />
          </label>
          <label>
              Year 3 ADT
            <input
              value={adt3}
              readOnly
            />
          </label>
          <label>
              Year 5 ADT
            <input
              value={adt5}
              readOnly
            />
          </label>
          <label>
              Year 3 AT
            <input
              value={at3}
              readOnly
            />
          </label>
          <label>
              Year 5 AT
            <input
              value={at5}
              readOnly
            />
          </label>
          <label>
              Year 3 Weekly Sales
            <input
              value={aws3}
              readOnly
            />
          </label>
          <label>
              Year 5 Weekly Sales
            <input
              value={aws5}
              readOnly
            />
          </label>
          <label>
              Year 1 Total Rent
            <input
              value={y1totalRent}
              readOnly
            />
          </label>
          <label>
              Year 3 Total Rent
            <input
              value={y3totalRent1}
              readOnly
            />
          </label>
          <label>
              Year 5 Total Rent
            <input
              value={y5totalRent1}
              readOnly
            />
          </label>
          <label>
              Year 1 Real Estate Taxes
            <input
              value={y1realEstateTaxes}
              readOnly
            />
          </label>
          <label>
              Year 3 Real Estate Taxes
            <input
              value={y3realEstateTaxes1}
              readOnly
            />
          </label>
          <label>
              Year 5 Real Estate Taxes
            <input
              value={y5realEstateTaxes1}
              readOnly
            />
          </label>
          <label>
              Inital Lease Term (years)
            <input
              value={initialLeaseTermYears}
              readOnly
            />
          </label>
          <label>
              Total Years in Starbucks Controlled Lease Extension Options
            <input
              value={totalYearsStarbucksControlledLeaseExtOptions}
              readOnly
            />
          </label>
          <label>
              Total Lease Term (years)
            <input
              value={totalLeaseTermYears}
              readOnly
            />
          </label>
          <label>
              Initial Lease Expiration
            <input
              value={initialLeaseExpiration}
              readOnly
            />
          </label>
         
        </div>
      </div>
    );

  }
}

export default AdditionalInformationForm;