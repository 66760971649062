import React from 'react';
import SaveStatusModal from '../modals/SaveStatusModal';
import SaveExtensionRequestModal from '../modals/SaveExtensionRequestModal';
import SaveExtensionResponseModal from '../modals/SaveExtensionResponseModal';
import helpers from '../helpers/applicationHelpers';
import statuses from '../helpers/applicationStatusEnum';
import CircularProgress from '../../../shared/CircularProgress';
import ApplicationInformationForm from './forms/ApplicationInformationForm';
import AdditionalInformationForm from './forms/AdditionalInformationForm';
import projectTypes from '../helpers/applicationProjectTypeEnum';
import FinanceInformationForm from './forms/FinanceInformationForm';
import ComplianceInformation from './forms/ComplianceInformation';
import CommercialInformation from './forms/CommercialInformation';
import RetailerRequirementsForm from './forms/RetailerRequirementsForm';

class Fields extends React.Component {
  constructor(props) {
    super(props);
    this.state= helpers.initialiseState();

    this.handleCheckInputUpdate = this.handleCheckInputUpdate.bind(this);
    this.handleTextInputUpdate = this.handleTextInputUpdate.bind(this);

    this.handleSave = this.handleSave.bind(this);

    this.handleModalToggle = this.handleModalToggle.bind(this);

    this.handleSaveStatus = this.handleSaveStatus.bind(this);
    this.handleSaveExtension = this.handleSaveExtension.bind(this);

    this.handleChangeTab = this.handleChangeTab.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      // new load
      this.props.applicationDetail.applicationId
      !== prevProps.applicationDetail.applicationId
    ) {
      this.initialiseState();
    } else if (
      this.props.newAddress !== prevProps.newAddress
      && this.props.newAddress.address_components
    ) {
      const newAddress = {};

      this.props.newAddress.address_components.forEach((a) => {
        if (a.types.indexOf('street_number') > -1) {
          newAddress.street1 = a.long_name;
        } else if (a.types.indexOf('route') > -1) {
          newAddress.street2 = a.long_name;
        } else if (a.types.indexOf('postal_town') > -1) {
          newAddress.city = a.long_name;
        } else if (a.types.indexOf('administrative_area_level_2') > -1) {
          let county = this.props.countiesDetail.find(
            (c) => c.value.toLowerCase() === a.long_name.toLowerCase()
              || c.value.toLowerCase() === a.short_name.toLowerCase(),
          );

          if (!county) {
            county = this.props.countiesDetail.find(
              (c) => c.value.toLowerCase().indexOf(a.long_name.toLowerCase()) > -1
                || c.value.toLowerCase().indexOf(a.short_name.toLowerCase())
                > -1
                || a.long_name.toLowerCase().indexOf(c.value.toLowerCase()) > -1
                || a.short_name.toLowerCase().indexOf(c.value.toLowerCase()) > -1,
            );
          }
          if (county) {
            newAddress.county = a.long_name;
            newAddress.countyId = county.key;
            newAddress.countyEditable = false;
          } else {
            newAddress.countyEditable = true;
            console.log(a.long_name);
          }
        } else if (a.types.indexOf('administrative_area_level_1') > -1) {
          newAddress.country = a.long_name;
        } else if (a.types.indexOf('postal_code') > -1) {
          newAddress.postCode = a.long_name;
        }
      });

      newAddress.street1 = (newAddress.street1 || '') + ' ' + newAddress.street2;
      newAddress.street2 = '';

      newAddress.latitude = this.props.newAddress.geometry.location.lat();
      newAddress.longitude = this.props.newAddress.geometry.location.lng();

      this.setState({ ...newAddress, isDirty: true });
    }

    if (
      this.props.isChanged
      && !prevProps.isChanged
      && this.state.forceReload
    ) {
      this.props.reloadRecord();

      this.setState({
        showStatusChangeModal: false,
        showExtensionRequestModal: false,
        showExtensionResponseModal: false,
      });
    }

    if (this.props.isSaved && !prevProps.isSaved) {
      this.setState({
        isDirty: false,
      });
    }
  }

  initialiseState() {
    const {
      applicationDetail,
      companiesDetail,
      companyUsersDetail,
    } = this.props;

    const newState = helpers.initialiseState(applicationDetail, companiesDetail, companyUsersDetail);

    this.setState(newState);
  }

  handleTextInputUpdate(e, fieldName, type) {
    const { projectNo, formatId } = this.state;

    let { value } = e.target;

    if (type === 'currency') {
      if (
        value
        && value.length > 0
        && value.indexOf('.') !== -1
        && value.length - value.indexOf('.') > 2
      ) {
        value = parseFloat(value).toFixed(2);
      }
    }

    if(fieldName === 'projectNo'){

      let valid = true;

      let testValue = value.replace('-', '');

      if(!testValue){
        value = '';
      }else{

        let result = testValue.match(new RegExp('^[0-9]+$'));

        if(result && result.length > 0 && result[0] === testValue){
          if(result[0].length >= 5){
            result = result[0].substr(0,5) + '-' + result[0].substr(5,result[0].length - 5);
          }else{
            result = result[0];
          }
        }else{
          valid = false;
        }

        if(!valid){
          value = projectNo;
        }else{
          value = result;
        }
      }
    }

    const newState = {
      [fieldName]: value, 
    };

    if (fieldName === 'projectTypeId') {
      if (e.target.value === projectTypes.newStore.toString()) {
        newState.storeNo = undefined;
        newState.plannedClosingDate = undefined;
      }
    }

    if(fieldName === 'latestEstimatedOpeningDate'){

      var date = new Date(value);

      if(formatId == 1 || formatId == 5 || formatId == 6)
      {
        date = new Date(date.setDate(date.getDate() - (27.5 * 7))).toISOString();
        newState.plannedSchematic = date;
      }
      else{
        date = new Date(date.setDate(date.getDate() - (23 * 7))).toISOString();
        newState.plannedSchematic = date;
      }
    }

    newState.isDirty = true;

    this.setState(newState);
  }

  handleCheckInputUpdate(e, fieldName) {
    this.setState({ [fieldName]: true && e.target.checked, isDirty: true });
  }

  handleModalToggle(modalStatusName, show) {
    this.setState({ [modalStatusName]: show });
  }

  handleChangeTab(tab) {
    this.setState({ currentTab: tab });
  }

  // Actual save method
  handleSave(newStatusId) {
    const {
      companyId,
      siteName,
      siteTypeId,
      formatId,
      storeNo,
      netSquareFeet,
      grossSquareFeet,
      developer,
      primaryContactId,
      secondaryContactid,
      street1,
      street2,
      street3,
      city,
      countyId,
      country,
      postCode,
      latitude,
      longitude,
      operator,
      countyEditable,
      ownershipModelId,
      ownershipModel,
      ownershipType,
      showValidation,
      projectClassification,
      projectClassificationId,
      summarySSPId,
      summarySSP,
      summaryOpenId,
      summaryOpen,
      segment,
      storesApprovedCity,
      storesApprovedCountry,
      storesOpenedCity,
      storesOpenedCountry,
      storesTotalCity,
      storesTotalCountry,
      plannedOpenDate,
      // To Be identified -> Identified
      // expiration date
      // Identified -> To be registered
      finalDeveloper,
      // To be registered -> Registered
      // expiration date
      // Registered -> To be Approved
      sitePositionId,
      primaryBusinessGeneratorId,
      estimatedOpeningDate,
      plannedSchematic,
      conditionalApprovalNotes,
      adt,
      aws,
      operatingDays,
      totalStoreInvestment,
      patioSeats,
      cafeSeats,
      carParkingSpaces,
      rent,
      initialLeaseTermYears,
      renewalOrBreakYears,
      breakEven,
      year1Roi,
      investmentPaybackYears,

      // To be approved -> Approved
      latestEstimatedOpeningDate,

      // Open

      // SiteID Admin Team
      dateSentToEmea,

      // Rejected
      notApprovedForSiteId,

      // In need of ordering
      headsOfTermsAgreed,
      headsOfTermsComments,
      leaseOrPurchaseComments,
      leaseSignedOrSitePurchased,
      leaseSignedOrSitePurchasedId,
      planningComments,
      planningGrantedId,
      planningRequired,
      planningSubmitted,
      plannedClosingDate,
      practicalCompletion,
      projectNo,
      projectTypeId,
      projectType,
      shellConstructionStarted,
      tenureTypeId,
    } = this.state;

    let save = true;
    if (newStatusId === statuses.RetailerInterest) {
      if (!companyId || !latitude || !longitude) {
        this.setState({ showValidation: true });
        save = false;
      }
    }

    if (save) {
      this.props.save({
        companyId,
        siteName,
        siteTypeId,
        formatId,
        netSquareFeet,
        storeNo,
        grossSquareFeet,
        developer,
        primaryContactId,
        secondaryContactid,
        street1,
        street2,
        street3,
        city,
        countyId,
        country,
        postCode,
        latitude,
        longitude,
        operator,
        projectTypeId,
        projectType,
        countyEditable,
        ownershipModelId,
        ownershipModel,
        ownershipType,
        showValidation,
        projectClassification,
        projectClassificationId,
        summarySSPId,
        summarySSP,
        summaryOpenId,
        summaryOpen,
        segment,
        storesApprovedCity,
        storesApprovedCountry,
        storesOpenedCity,
        storesOpenedCountry,
        storesTotalCity,
        storesTotalCountry,
        plannedOpenDate,
        // To Be identified -> Identified
        // expiration date
        // Identified -> To be registered
        finalDeveloper,
        // To be registered -> Registered
        // expiration date
        // Registered -> To be Approved
        sitePositionId,
        primaryBusinessGeneratorId,
        estimatedOpeningDate,
        plannedSchematic,
        conditionalApprovalNotes,
        adt,
        aws,
        operatingDays,
        totalStoreInvestment,
        patioSeats,
        cafeSeats,
        carParkingSpaces,
        rent,
        initialLeaseTermYears,
        renewalOrBreakYears,
        breakEven,
        year1Roi,
        investmentPaybackYears,

        // To be approved -> Approved
        latestEstimatedOpeningDate,

        // Open

        // SiteID Admin Team
        dateSentToEmea,

        // Rejected
        notApprovedForSiteId,

        // In need of ordering
        headsOfTermsAgreed,
        headsOfTermsComments,
        leaseOrPurchaseComments,
        leaseSignedOrSitePurchased,
        leaseSignedOrSitePurchasedId,
        planningComments,
        planningGrantedId,
        planningRequired,
        planningSubmitted,
        plannedClosingDate,
        practicalCompletion,
        projectNo,
        shellConstructionStarted,
        tenureTypeId,
      });
    }
  }

  handleSaveStatus(status) {
    const { saveStatus } = this.props;

    this.setState({ forceReload: true });

    saveStatus(status);
  }

  handleSaveExtension(extension) {
    const { saveExtension } = this.props;

    this.setState({ forceReload: true });

    saveExtension(extension);
  }

  handleNumberOfOperatingDays(event, value) {
    if (
      (value && value.length >= 1)
      || isNaN(event.key)
      || parseInt(event.key) > 7
    ) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  renderButtons(locked, extension, extensionCount){
    const {
      handleSave,
      handleModalToggle,
      handleSaveStatus,
    } = this;
    const {
      availableStatusIds,
      expirationDate,
      statusId,
      isDirty,

      expiringSoon,
    } = this.state;
    const {
      statusesDetail,
      isLicenseeDetail,
      isReadOnlyDetail,
      singleLicenseeMode,
      applicationDetail,
      nearApplicationsDetail,
      maxExtensions,
      isChanging,
    } = this.props;

    if(singleLicenseeMode) return null;

    return (
      <div className="button-holder">
        {!locked && (
          <button
            type="button"
            className="action"
            disabled={!isDirty || isChanging}
            onClick={() => handleSave(statusId)}
          >
                Save
          </button>
        )}
      
        {availableStatusIds
          .sort((a, b) => a - b)
          .map((a) => {
            const status = statusesDetail.find((b) => a === b.key);

            let label = '';

            if (!isLicenseeDetail && a == statuses.aborted) {
              return null;
            }

            if (isLicenseeDetail && a == statuses.rejected) {
              return null;
            }

            if (status) {
              label = `${status.value}`;
              if (a === statuses.Rejected) {
                label = 'Reject';
              } else if (a === statuses.EngagementEnded) {
                label = 'Close';
              }
            }

            var negativeStatus = [statuses.EngagementEnded, statuses.Rejected, statuses.Expired].indexOf(a) > -1;

            return (
              <button
                key={a}
                type="button"
                className={`action ${
                  negativeStatus ? 'negative' : ''
                }`}
                disabled={!negativeStatus && !helpers.canProgressApplication(isDirty, statusId, this.state, nearApplicationsDetail)}
                onClick={() => {
                  this.setState({ toStatusId: a });
                  handleModalToggle('showStatusChangeModal', true);
                }}
              >
                {label}
              </button>
            );
          })}
        {expirationDate && expiringSoon
            && [statuses.Expired, statuses.PendingContract,
              statuses.PendingNDA].indexOf(statusId) > -1
            && isLicenseeDetail
            && !extension
            && (
              <button
                disabled={extensionCount >= maxExtensions}
                type="button"
                title={extensionCount >= maxExtensions ? 'The maximum number of extensions has been reached.' : ''}
                className="action"
                onClick={() => handleModalToggle('showExtensionRequestModal', true)}
              >
                Request Extension
              </button>
            )}
        {expirationDate
            && [statuses.expired, statuses.identified,
              statuses.registered, statuses.approved].indexOf(statusId) > -1
            && !isLicenseeDetail
            && extension && (
          <button
            type="button"
            className="action"
            onClick={() => handleModalToggle('showExtensionResponseModal', true)}
          >
                Review Extension
          </button>
        )}
      </div>
    );
  }

  renderTabHeader(field, label1, label2, locked){
    const {
      currentTab,
      statusId,
    } = this.state;

    return (
      <div
        className={`tab-button ${
          currentTab === field ? 'selected' : ''
        }`}
        onClick={() => this.handleChangeTab(field)}
      >
        <div>
          {label1}
          <br />
          {label2}
        </div>
        {!locked && (
          <CircularProgress
            percentage={helpers.percCompleteToProgress(statusId, this.state)}
          />
        )}
      </div>
    );
  }

  render() {
    const {
      handleModalToggle,
    } = this;
    const {
      statusId,
      showStatusChangeModal,
      showExtensionRequestModal,
      showExtensionResponseModal,
      toStatusId,
      currentTab,
    } = this.state;
    const {
      statusesDetail,
      extensionOutcomes,
      isLicenseeDetail,
      isReadOnlyDetail,
      isChanging,
      applicationDetail,
      nearApplicationsDetail,
      defaultResponses,
      errorMessage,
      companiesDetail,
      formatsDetail,
      primaryBusinessGeneratorsDetail,
      siteTypesDetail,
      tenureTypesDetail,
      companyUsersDetail,
      countiesDetail,
      leaseDetail,
      planningGrantTypesDetail,
      projectTypesDetail,
    } = this.props;
    const {
      handleSaveStatus,
      handleSaveExtension,
    } = this;

    const status = statusesDetail && statusesDetail.find((b) => toStatusId === b.key);
    let title = '';
    if (status) {
      title = `Progress to ${status.value}`;
    }

    const latestStatusChanges = (applicationDetail
        && applicationDetail.statusChange
        && applicationDetail.statusChange.slice(0))
      || [];

    latestStatusChanges.sort((a, b) => new Date(b.created) - new Date(a.created));

    let extension;
    let extensionCount;
    let statusChange;

    if (latestStatusChanges && latestStatusChanges.length > 0) {
      statusChange = latestStatusChanges[0];
      extensionCount = applicationDetail.extension.length;
      const extensions = applicationDetail.extension
        && applicationDetail.extension.filter(
          (e) => e.statusChangeId === statusChange.statusChangeId
            && e.outcomeId === null,
        );

      extensions.sort((a, b) => b.extensionId - a.extensionId);

      if (extensions && extensions.length > 0) {
        extension = extensions[0];
      }
    }
    const locked = !helpers.canAttemptSave(statusId, isLicenseeDetail, isReadOnlyDetail);

    return (
      <div className="fields">
        {!isReadOnlyDetail && this.renderButtons(locked, extension, extensionCount)}
        <div>{ errorMessage}</div>
        <div className="tab-container">
          {this.renderTabHeader('application', 'Retailer', 'Information', locked)}
          {this.renderTabHeader('requirements', 'Retailer', 'Requirements', locked)}
          {((statusId >= statuses.CommercialDiscussions)) &&
          this.renderTabHeader('commercial', 'Commercial', 'Information', locked)
          }
          {((statusId >= statuses.DueDiligenceRequired)) &&
          this.renderTabHeader('compliance', 'Compliance', 'Information', locked)
          }
          {((statusId >= statuses.FinanceChecksRequired)) &&
          this.renderTabHeader('finance', 'Finance', 'Information', locked)
          }
        </div>
        <div className="floating-panel ">
          <div>
            {currentTab === 'application' &&
              <ApplicationInformationForm
                handleTextInputUpdate={this.handleTextInputUpdate}
                nearApplications={nearApplicationsDetail}
                locked={locked}
                isLicensee={isLicenseeDetail}
                isReadOnly={isReadOnlyDetail}
                fields={this.state}
                companiesDetail={companiesDetail}
                formatsDetail={formatsDetail}
                primaryBusinessGeneratorsDetail={primaryBusinessGeneratorsDetail}
                siteTypesDetail={siteTypesDetail}
                projectTypesDetail={projectTypesDetail}
                tenureTypesDetail={tenureTypesDetail}
                companyUsersDetail={companyUsersDetail}
                applicationDetail={applicationDetail}
                countiesDetail={countiesDetail}
                leaseDetail={leaseDetail}
                planningGrantTypesDetail={planningGrantTypesDetail}
                handleCheckInputUpdate={this.handleCheckInputUpdate}
                longstop={applicationDetail && applicationDetail.longStopDate}
              />
            }
          </div>
          <div>
            {currentTab === 'requirements' &&
              <RetailerRequirementsForm
                handleTextInputUpdate={this.handleTextInputUpdate}
                nearApplications={nearApplicationsDetail}
                locked={locked}
                isLicensee={isLicenseeDetail}
                isReadOnly={isReadOnlyDetail}
                fields={this.state}
                companiesDetail={companiesDetail}
                formatsDetail={formatsDetail}
                primaryBusinessGeneratorsDetail={primaryBusinessGeneratorsDetail}
                siteTypesDetail={siteTypesDetail}
                projectTypesDetail={projectTypesDetail}
                tenureTypesDetail={tenureTypesDetail}
                companyUsersDetail={companyUsersDetail}
                applicationDetail={applicationDetail}
                countiesDetail={countiesDetail}
                leaseDetail={leaseDetail}
                planningGrantTypesDetail={planningGrantTypesDetail}
                handleCheckInputUpdate={this.handleCheckInputUpdate}
                longstop={applicationDetail && applicationDetail.longStopDate}
              />
            }
          </div>
          <div>
            {currentTab === 'commercial' &&
              <CommercialInformation
                handleTextInputUpdate={this.handleTextInputUpdate}
                nearApplications={nearApplicationsDetail}
                locked={locked}
                isLicensee={isLicenseeDetail}
                isReadOnly={isReadOnlyDetail}
                fields={this.state}
                companiesDetail={companiesDetail}
                formatsDetail={formatsDetail}
                primaryBusinessGeneratorsDetail={primaryBusinessGeneratorsDetail}
                siteTypesDetail={siteTypesDetail}
                projectTypesDetail={projectTypesDetail}
                tenureTypesDetail={tenureTypesDetail}
                companyUsersDetail={companyUsersDetail}
                applicationDetail={applicationDetail}
                countiesDetail={countiesDetail}
                leaseDetail={leaseDetail}
                planningGrantTypesDetail={planningGrantTypesDetail}
                handleCheckInputUpdate={this.handleCheckInputUpdate}
                longstop={applicationDetail && applicationDetail.longStopDate}
              />
            }
          </div>
          <div>
            {currentTab === 'compliance' &&
              <ComplianceInformation
                handleTextInputUpdate={this.handleTextInputUpdate}
                nearApplications={nearApplicationsDetail}
                locked={locked}
                isLicensee={isLicenseeDetail}
                isReadOnly={isReadOnlyDetail}
                fields={this.state}
                companiesDetail={companiesDetail}
                formatsDetail={formatsDetail}
                primaryBusinessGeneratorsDetail={primaryBusinessGeneratorsDetail}
                siteTypesDetail={siteTypesDetail}
                projectTypesDetail={projectTypesDetail}
                tenureTypesDetail={tenureTypesDetail}
                companyUsersDetail={companyUsersDetail}
                applicationDetail={applicationDetail}
                countiesDetail={countiesDetail}
                leaseDetail={leaseDetail}
                planningGrantTypesDetail={planningGrantTypesDetail}
                handleCheckInputUpdate={this.handleCheckInputUpdate}
                longstop={applicationDetail && applicationDetail.longStopDate}
              />
            }
          </div>
          <div>
            {currentTab === 'finance' &&
              <FinanceInformationForm
                handleTextInputUpdate={this.handleTextInputUpdate}
                nearApplications={nearApplicationsDetail}
                locked={locked}
                isLicensee={isLicenseeDetail}
                isReadOnly={isReadOnlyDetail}
                fields={this.state}
                companiesDetail={companiesDetail}
                formatsDetail={formatsDetail}
                primaryBusinessGeneratorsDetail={primaryBusinessGeneratorsDetail}
                siteTypesDetail={siteTypesDetail}
                projectTypesDetail={projectTypesDetail}
                tenureTypesDetail={tenureTypesDetail}
                companyUsersDetail={companyUsersDetail}
                applicationDetail={applicationDetail}
                countiesDetail={countiesDetail}
                leaseDetail={leaseDetail}
                planningGrantTypesDetail={planningGrantTypesDetail}
                handleCheckInputUpdate={this.handleCheckInputUpdate}
                longstop={applicationDetail && applicationDetail.longStopDate}
              />
            }
          </div>
          <div>
            {currentTab === 'additional' &&
            <AdditionalInformationForm
              locked={locked}
              fields={this.state}
              applicationDetail={applicationDetail}
            />
            }
          </div>
          {showStatusChangeModal && (
            <SaveStatusModal
              cancel={() => handleModalToggle('showStatusChangeModal', false)}
              inProgress={isChanging}
              continue={handleSaveStatus}
              isLicensee={isLicenseeDetail}
              title={title}
              fromStatusId={statusId}
              toStatusId={toStatusId}
              nearApplications={nearApplicationsDetail}
              defaultResponses={defaultResponses}
              errorMessage={errorMessage}
              longstop={applicationDetail && applicationDetail.longStopDate}
            />
          )}
          {showExtensionRequestModal && isLicenseeDetail && (
            <SaveExtensionRequestModal
              cancel={() => handleModalToggle('showExtensionRequestModal', false)}
              inProgress={isChanging}
              continue={handleSaveExtension}
              isLicensee={isLicenseeDetail}
              statusChange={statusChange}
              currentStatus={statusId}
              errorMessage={errorMessage}
            />
          )}
          {showExtensionResponseModal && !isLicenseeDetail && (
            <SaveExtensionResponseModal
              cancel={() => handleModalToggle('showExtensionResponseModal', false)}
              inProgress={isChanging}
              continue={handleSaveExtension}
              isLicensee={isLicenseeDetail}
              document={document}
              extensionOutcomes={extensionOutcomes}
              extension={extension}
              extensionCount={extensionCount}
              statusChange={statusChange}
              longstop={applicationDetail && applicationDetail.longStopDate}
            />
          )}
        </div>
      </div>
    );
  }
}

export default Fields;
