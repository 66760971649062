import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import orderTrackerActions from '../../../actions/orderTrackerActions';
import { navigationActions } from '../../../actions/navigationActions';
import PeriodFilter, { getDefaultTimePeriod } from '../components/PeriodFilter/PeriodFilter';
import './SearchOrders.scss';
import StatusDropdown from '../components/StatusDropdown/StatusDropdown';
import { sanitiseNumberInput } from '../utils';

const SearchOrders = ({ searchOrders, data, completed, isLoading,
  navigate, exportOrders }) => {
  const [displayMode, setDisplayMode] = useState(1);
  const [storeNumber, setStoreNumber] = useState('');
  const [storeName, setStoreName] = useState('');
  const [orderNumber, setOrderNumber] = useState('');
  const [poNumber, setPoNumber] = useState('');
  const [trip, setTrip] = useState('');
  const [orderStatus, setOrderStatus] = useState('');
  const [createdDate, setCreatedDate] = useState('');
  const [age, setAge] = useState('');
  const [daysDelayed, setDaysDelayed] = useState('');
  const [trackingNo, setTracking] = useState('');
  const [shipmentType, setShipmentType] = useState('');
  const [mbu, setMBU] = useState('');
  const [orderType, setOrderType] = useState('');

  const [timePeriod, setTimePeriod] = useState(getDefaultTimePeriod());

  
  useEffect(
    () => handleSubmit(undefined, searchOrders),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  
  const handleSubmit = (e, dataFn) => {
    if (e) {
      e.preventDefault();
    }

    let filterMonth = '';
    let filterYear = '';

    if (timePeriod.length > 0) {
      filterMonth = timePeriod.split('-')[0];
      filterYear = timePeriod.split('-')[1];
    }

    dataFn({
      completed,
      filterMonth,
      filterYear,
      orderNumber,
      poNumber,
      trip,
      storeNumber,
      storeName,
      age,
      daysDelayed,
      status: orderStatus,
      createdAt: createdDate,
      trackingNo,
      mbu,
      shipmentType,
      orderType,
    });
  };

  const formRefContainer = useRef(null);

  const handleFormReset = (e) => {
    e.preventDefault();
    setStoreNumber('');
    setOrderNumber('');
    setPoNumber('');
    setTrip('');
    setOrderStatus('');
    setCreatedDate('');
    setTimePeriod(getDefaultTimePeriod());
    setStoreName('');
    setAge('');
    setDaysDelayed('');
    setTracking('');
    setMBU('');
    setShipmentType('');
    setOrderType('');

    // Defer the form reset until after render.
    // This allows the form to re-render with the cleared down values,
    // which then allows the validation to reset properly...
    setTimeout(
      () => {
        formRefContainer.current.reset();
      },
      0);
  };

  return (
    <div className="search-orders-page">
      <div className="floating-panel">
        <form
          onSubmit={(e) => handleSubmit(e, searchOrders)}
          className="submitted"
          ref={formRefContainer}
        >
          <div className="button-container">
            <button
              type="button"
              className="action negative"
              onClick={handleFormReset}
            >
              Clear
            </button>
            <button
              className="action"
              type="submit"
              disabled={isLoading}
            >
              Search
            </button>
          </div>
          <div className="search-order-container">
            <div className="group-details accordion">
              <div className="accordion-header">
                <h3>Order Details</h3>
              </div>
              <div className="accordion-body horizontal-flex order-fields">
                <div>
                  <label>
                    Customer Details
                    <input
                      autoFocus
                      placeholder="Customer number"
                      type="text"
                      pattern="^\d{0,15}$"
                      onChange={e => setStoreNumber(e.target.value)}
                      onKeyPress={sanitiseNumberInput}
                      value={storeNumber}
                      autoComplete="off"
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Customer Address
                    <input
                      placeholder="Customer address"
                      type="text"
                      maxLength="60"
                      onChange={e => setStoreName(e.target.value)}
                      value={storeName}
                      autoComplete="off"
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Orderly Order Number
                    <input
                      placeholder="Orderly Order Number"
                      type="text"
                      pattern="^\d{0,15}$"
                      onChange={e => setOrderNumber(e.target.value)}
                      onKeyPress={sanitiseNumberInput}
                      value={orderNumber}
                      autoComplete="off"
                    />
                  </label>
                </div>
                <>
                  <div>
                    <label>
                    Customer PO Number
                      <input
                        placeholder="Customer PO Number"
                        type="text"
                        maxLength="60"
                        onChange={e => setPoNumber(e.target.value)}
                        value={poNumber}
                        autoComplete="off"
                      />
                    </label>
                  </div>
                
                  <div>
                    <label>
                    Trip
                      <input
                        placeholder="Trip"
                        type="text"
                        pattern="^\d{0,15}$"
                        onChange={e => setTrip(e.target.value)}
                        onKeyPress={sanitiseNumberInput}
                        value={trip}
                        autoComplete="off"
                      />
                    </label>
                  </div>
                </>         
              </div>
            </div>
            <div className="group-details accordion">
              <div className="accordion-header">
                <h3>Order Status</h3>
              </div>
              <div className="accordion-body horizontal-flex order-fields">
                <div>
                  <StatusDropdown
                    value={orderStatus}
                    onChange={e => setOrderStatus(e.target.value)}
                    includedStatuses={completed ? [1,5] : [2,3,4]}
                  />
                </div>
                <div>
                  <label>
                    Created Date
                    <input
                      placeholder="Created Date"
                      type="date"
                      onChange={e => setCreatedDate(e.target.value)}
                      value={createdDate}
                      autoComplete="off"
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Age
                    <input
                      placeholder="Age"
                      type="text"
                      pattern="^\d*$"
                      value={age}
                      onChange={e => setAge(e.target.value)}
                      onKeyPress={sanitiseNumberInput}
                      autoComplete="off"
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Days Delayed
                    <input
                      placeholder="Days Delayed"
                      type="text"
                      pattern="^\d*$"
                      value={daysDelayed}
                      onChange={e => setDaysDelayed(e.target.value)}
                      onKeyPress={sanitiseNumberInput}
                      autoComplete="off"
                    />
                  </label>
                </div>
                <div>
                  <label>
                   In Progress Summary Data
                    <button className="action">View</button>
                  </label>
                </div>
              </div>
            </div>
            <div className="group-details accordion">
              <div className="accordion-header">
                <h3>Filter by Period</h3>
              </div>
              <div className="accordion-body horizontal-flex order-fields">
                <div>
                  <PeriodFilter
                    onChange={(e) => setTimePeriod(e.target.value)}
                    value={timePeriod}
                  />
                </div>
              </div>
            </div>
            <div className="group-details accordion download-container">
              <div className="accordion-header">
                <h3>Download Results</h3>
              </div>
              <div className="accordion-body horizontal-flex order-fields">
                <div>
                  <button
                    className="action export"
                    onClick={(e) => handleSubmit(e, exportOrders)}
                    type="button"
                    title="Export"
                    disabled={isLoading}
                  >
                    <div
                      className="icon icon-download-light"
                      alt="Export"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="table-container">
        <div className="table-header">
          <div className="display-options">
            Table Size
            <button
              type="button"
              className="icon-display-mode-1"
              disabled={displayMode === 1}
              onClick={() => setDisplayMode(1)}
            />
            <button
              type="button"
              className="icon-display-mode-2"
              disabled={displayMode === 2}
              onClick={() => setDisplayMode(2)}
            />
            <button
              type="button"
              className="icon-display-mode-3"
              disabled={displayMode === 3}
              onClick={() => setDisplayMode(3)}
            />
          </div>
          <h2>
            Search Results
          </h2>
          <div />
        </div>
        <div className="table-body">
          <table className={`table-display-mode-${displayMode}`}>
            
            <thead>
              <tr>
                <th>Customer Number</th>
                <th>Orderly Order Number</th>
                <th>Customer PO Number</th>
                <th>Trip</th>
                <th>Order Status</th>
                <th>Created Date</th>
                <th>Days Delayed</th>
                <th>View Details</th>
              </tr>
            </thead>
            
            
            <tbody>
              {data.length === 0 &&
                <tr>
                  <td
                    colSpan={100}
                    className="no-records"
                  >
                    {isLoading ? 'Searching...' : 'No orders found.'}
                  </td>
                </tr>
              }
              {data.length > 0 && data.map(x =>
                <tr key={x.orderId}>
                  <td>{x.storeNumber}</td>
                  <td>{x.externalOrderNumber}</td>
                  <td>{x.externalPONumber}</td>
                  <td>{x.trip}</td>
                  <td>{x.status}</td>
                  <td>{x.createdAt.split('T')[0]}</td>
                  <td>{x.daysDelayed}</td>
                  <td>
                    <button
                      className="link"
                      type="button"
                      onClick={() => navigate(`/module/220/ot-vieworder?id=${x.orderId}`)}>
                      View Details
                    </button>
                  </td>
                </tr>,
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) =>  {
  return {
    isLoading: state.orderTracker.isLoading,
    isLoaded: state.orderTracker.isLoaded,
    data: state.orderTracker.searchResults,
    error: state.orderTracker.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //TODO USE REDUCER TO POINT TO APAC SEARCH IF TENANT IS APAC
    searchOrders: (params) => dispatch(orderTrackerActions.searchOrders(params)),
    exportOrders: (params) => dispatch(orderTrackerActions.exportOrders(params)),
    navigate: (path) => dispatch(navigationActions.pushNavigation(path)),
    getFilters: () => dispatch(orderTrackerActions.getFilters()),
    getShipmentDetail: (params) => dispatch(orderTrackerActions.getShipmentDetail(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchOrders);
