import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../actions/navigationActions';
import { chatActions } from '../../../actions/chatActions';
import ReactWebChat, { createDirectLine } from 'botframework-webchat';

class Chatbot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      directLine: undefined,
    };
  }

  componentDidMount() {
    const { requestToken } = this.props;

    requestToken();
  }

  componentDidUpdate(prevProps) {
    
    if (!prevProps.isLoaded && this.props.isLoaded && this.props.token) {
      const directLine = createDirectLine({ token: this.props.token });
      this.setState({ directLine });
    }
  }

  render() {
    const { directLine } = this.state;

    return (
      <div className="chatbot-page page">
        <div className="floating-panel">
          <div className="floating-header">
            <div className="header-title">
              <div className="icon icon-orderly"></div>
              <div>
                <h2>MyOrderly Chatbot</h2>
                <p>
                                   
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id="webchat">
          {this.state.directLine ?
            <ReactWebChat
              directLine={directLine}
              styleOptions={{
                hideUploadButton: true,
              }}
            />
            : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.chatbot.token,
    isLoaded: state.chatbot.isLoaded,
  };
};

const mapDispatchToProps = dispatch => ({
  requestToken: () =>dispatch(chatActions.requestToken()),

  push: path => dispatch(navigationActions.pushNavigation(path)),
  replace: path => dispatch(navigationActions.replaceNavigation(path)),
  reset: () => dispatch(navigationActions.resetNavigation()),
  goBack: () => dispatch(navigationActions.backNavigation()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Chatbot);
