import React from 'react';
import MultiSelect from './MultiSelect';

class FilterPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
    };
  }

  render() {
    const {
      companies,
      companyIds,
      counties,
      countyIds,
      statuses,
      statusIds,
      doFilter,
      hasFlags,
      displayEquity,
      displayOpen,
      expiringSoon,
      inactiveDaysBack,
      showEquityFilter,
      doToggle,
      doToggleMulti,
      sortOrder,
      doSort,
      fullScreenMode,
      testFit,
      plannedSchematic,
    } = this.props;

    const {
      isExpanded,
    } = this.state;

    return (

      <div className={`filter-panel floating-panel ${fullScreenMode ? 'full-screen' : ''} ${isExpanded ? 'collapsed' : 'expanded'}`}>
        {fullScreenMode && (
          <button
            type="button"
            className={`map-toggle ${isExpanded ? 'expanded' : 'closed'}`}
            onClick={() => this.setState(
              { isExpanded: !isExpanded },
            )}
          >
          +
          </button>
        )}
        {fullScreenMode && isExpanded && (
          statusIds.length !== statuses.length
              || countyIds.length !== counties.length
              || companyIds.length !== companies.length
              || !displayEquity
              || !displayOpen
              || hasFlags !== ''
              || (expiringSoon !== 999 && expiringSoon !== 0 && expiringSoon !== false)
              || inactiveDaysBack !== 0) && (
          <div className="icon icon-siteid-filter" />
        )}
        {companies && companies.length > 0 && (
          <MultiSelect
            title="Company"
            selectedValues={companyIds}
            valueChanged={doToggleMulti}
            values={companies}
            valueName="companyIds"

          />
        )}
        {counties && counties.length > 0 && (
          <MultiSelect
            title="Territory"
            selectedValues={countyIds}
            valueChanged={doToggleMulti}
            values={counties}
            valueName="countyIds"
          />
        )}
        {statuses && statuses.length > 0 && (
          <MultiSelect
            title="Status"
            selectedValues={statusIds}
            valueChanged={doToggleMulti}
            values={statuses}
            valueName="statusIds"
          />
        )}
        <label>
          Expiring Soon
          <select
            value={expiringSoon}
            onChange={(e) => doFilter(e, 'expiringSoon')}
          >
            <option value={999}>Include All</option>
            <option value={-1}>Extension Requested</option>
            <option value={1}>Next Day</option>
            <option value={7}>Next 7 Days</option>
            <option value={14}>Next 14 Days</option>
            <option value={30}>Next 30 Days</option>
            <option value={90}>Next 90 Days</option>
          </select>
        </label>
        <label>
          Flags
          <select value={hasFlags}
            onChange={(e) => doFilter(e, 'hasFlags')}>
            <option value="">Ignore Flags</option>
            <option value>With Flags</option>
            <option value={false}>Without Flags</option>
          </select>
        </label>
        
        <label>
          All Active Retailers
          <select
            value={displayOpen}
            onChange={(e) => doToggle(e, 'displayOpen')}
          >
            <option value={false}>Hidden</option>
            <option value>Show</option>
          </select>
        </label>
        <label>
          Inactive Applications
          <select
            value={inactiveDaysBack}
            onChange={(e) => doFilter(e, 'inactiveDaysBack')}
          >
            <option value={0}>Exclude</option>
            <option value={7}>Last 7 Days</option>
            <option value={30}>Last 30 Days</option>
            <option value={90}>Last 90 Days</option>
            <option value={365}>Last Year</option>
            <option value={730}>Last 2 Year</option>
            <option value={1095}>Last 3 Year</option>
          </select>
        </label>
        <label>
          Order By
          <select value={sortOrder}
            onChange={(e) => doSort(e)}>
            <option value="created_desc">Created (Newest)</option>
            <option value="created_asc">Created (Oldest)</option>

            <option value="name_asc">Site Name (A-Z)</option>
            <option value="name_desc">Site Name (Z-A)</option>

            <option value="modified_desc">Modified (Recent)</option>
            <option value="modified_asc">Modified (Oldest)</option>
          </select>
        </label>
      </div>
    );
  }
}

export default FilterPanel;
