import React from 'react';

class Flags extends React.Component {
  getExpirationMessage(expiringSoon, extensionRequested, statusId, expirationDate) {
    if (extensionRequested) {
      return (
        <div className="flags floating-panel nflags">
          <div className="flags__item">
            <div className=" icon icon-siteid-expiring-white" />
          This application is pending an extension request review.
          </div>
        </div>
      );
    }

    if (expiringSoon) {
      if (statusId === 7) {
        return (
          <div className="flags floating-panel nflags">
            <div className="flags__item">
              <div className=" icon icon-siteid-expiring-white" />
              {`This application will exceed the longstop date ${
                expirationDate
                  ? new Date(expirationDate).toLocaleDateString()
                  : ''
              } soon.`}
            </div>
          </div>
        );
      }
      return (
        <div className="flags floating-panel nflags">
          <div className="flags__item">
            <div className=" icon icon-siteid-expiring-white" />
            {`This application will expire ${
              expirationDate
                ? `on ${new Date(expirationDate).toLocaleDateString()}`
                : 'soon'
            } .`}
          </div>
        </div>
      );
    }

    return null;
  }

  render() {
    const {
      flags,
      expiringSoon,
      extensionRequested,
      statusId,
      expirationDate,
      fullScreenMode,
      isLicenseeDetail,
    } = this.props;

    const filteredFlags = flags.filter((f) => !isLicenseeDetail || f.licenseeVisible);

    if (filteredFlags.length === 0 && !expiringSoon) {
      return null;
    }

    return (
      <div className={`${fullScreenMode ? 'full-screen' : ''}`}>
        {filteredFlags.map((f) => (
          <div className={`flags floating-panel nflags`}
            key={f.commentId}>
            <div className="flags__item">
              <div className="icon icon-siteid-flag-white"/> {f.notes}
            </div>
          </div>
        ))}
        {this.getExpirationMessage(expiringSoon, extensionRequested, statusId, expirationDate)}
      </div>
    );
  }
}

export default Flags;
