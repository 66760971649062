import React from 'react';
import ApplicationHelpers from '../../helpers/applicationHelpers';
import statuses from '../../helpers/applicationStatusEnum';
import projectTypes from '../../helpers/applicationProjectTypeEnum';

export class RetailerRequirementsForm extends React.Component {

  productRanges = [
  {
    id: 1,
    title: 'Morrisons The Best',
    description: 'A premium range of high-quality products, including gourmet foods,' +
    ' specialty items, and indulgent treats.',
  },
  {
    id: 9,
    title: 'Health & Wellness Range',
    description: 'Products focused on health-conscious consumers, including low-sugar,' +
    ' low-fat, high-protein, and superfood items.',
  },
  {
    id: 2,
    title: 'Morrisons Savers',
    description: 'A value range offering affordable everyday essentials at lower ' +
    'prices without compromising on basic quality.',
  },
  {
    id: 3,
    title: 'Morrisons Market Street',
    description: 'Fresh produce, meat, fish, and bakery items, often prepared and' +
     ' sold in-store, emphasizing freshness and quality.',
  },
  {
    id: 4,
    title: 'Organic Range',
    description: 'A selection of certified organic products, including fruits,' +
    ' vegetables, dairy, and packaged goods.',
  },
  {
    id: 5,
    title: 'Free From Range',
    description: 'Products catering to dietary restrictions, including gluten-free,' +
    ' dairy-free, and nut-free items.',
  },
  {
    id: 6,
    title: 'Plant Revolution',
    description: 'A dedicated plant-based range offering vegan and vegetarian products,' +
    ' such as meat substitutes, dairy alternatives, and plant-based meals.',
  },
  {
    id: 7,
    title: 'Nutmeg',
    description: "Morrisons' own clothing and lifestyle range, featuring affordable" +
    ' fashion and home essentials.',
  },
  {
    id: 10,
    title: 'Convenience Range',
    description: 'Ready-to-eat and easy-to-prepare products such as sandwiches, ready' +
    ' meals, pre-chopped vegetables, and quick-cook grains.',
  },
  {
    id: 11,
    title: 'Sustainable & Ethical Range',
    description: 'Products that emphasize sustainability, such as fair trade items,' +
    ' responsibly sourced seafood, and eco-friendly packaging.',
  },
  {
    id: 12,
    title: 'Seasonal Range',
    description: 'Products available during specific times of the year, including' +
    ' festive foods, decorations, and gifts for occasions like Christmas and Easter.',
  },
  {
    id: 13,
    title: 'Beverage Range',
    description: 'A broad selection of beverages, including soft drinks, juices,' +
    ' teas, coffees, and alcoholic beverages like wine, beer, and spirits.',
  },
  {
    id: 14,
    title: 'Home & Garden Range',
    description: 'Non-food items such as household cleaning products, kitchenware,' +
    ' home décor, gardening tools, and plants.',
  },
  {
    id: 15,
    title: 'Kids & Baby Range',
    description: 'Products designed for children and babies, including baby food, ' +
    ' diapers, toys, and children’s snacks.',
  },
  {
    id: 16,
    title: 'Luxury & Indulgence Range',
    description: 'Premium products for special occasions, including fine chocolates,' +
    ' specialty cheeses, luxury desserts, and gourmet snacks.',
  },
  {
    id: 17,
    title: 'Morrisons Food to Order',
    description: 'A range of made-to-order items like party platters, special occasion' +
    ' cakes, and festive hampers.',
  },
  {
    id: 18,
    title: 'Bulk & Value Range',
    description: 'Larger pack sizes and value packs of household staples, catering to' +
    ' families or customers looking for cost-effective options.',
  },
];

  render() {
    const {
      handleTextInputUpdate,
      locked,
      isReadOnly,
      fields,
      companiesDetail,
      formatsDetail,
      primaryBusinessGeneratorsDetail,
      planningGrantTypesDetail,
      projectTypesDetail,
      tenureTypesDetail,
      companyUsersDetail,
      applicationDetail,
      siteTypesDetail,
      countiesDetail,
      leaseDetail,
      handleCheckInputUpdate,
      nearApplications  } = this.props;
    const {
      companyId,
      siteName,
      projectTypeId,
      formatId,
      tenureTypeId,
      netSquareFeet,
      grossSquareFeet,
      primaryBusinessGeneratorId,
      estimatedOpeningDate,
      plannedClosingDate,
      primaryContactId,
      secondaryContactid,
      statusId,
      street1,
      street2,
      city,
      countyId,
      postCode,
      latitude,
      longitude,
      showValidation,
      storeNo,
      developer,
      finalDeveloper,
      siteReferenceNo,
      projectType,
      headsOfTermsAgreed,
      leaseSignedOrSitePurchasedId,
      siteTypeId,
      planningRequired,
      planningGrantedId,
      planningGranted,
      planningSubmitted,
      planningComments,
      plannedSchematic,
      latestEstimatedOpeningDate,
    } = fields;

    const {
      company,
      tenureType,
      format,
      primaryBusinessGenerator,
      projectStatus,
      siteType,
    } = applicationDetail;

    const primaryContact = companyUsersDetail.find(
      (c) => c.userId === primaryContactId,
    );
    const secondaryContact = companyUsersDetail.find(
      (c) => c.userId === secondaryContactid,
    );
    const county = countiesDetail.find(
      (c) => c.key === countyId,
    );

    return (
      <div>
        <h3>Retailer Requirements</h3>
        <p>
          <span className="required"> *</span>
          {' '}
          These fields are mandatory to
          progress this application.
        </p>
        {!ApplicationHelpers.validateProximity(nearApplications, statusId, projectTypeId) ?
          <span  className="required">
           This application is too close to an existing application.
          </span> : null}
        <div className="form-section">
          <label>
              Country of Operation
            <span className="required"> *</span>
            {!(locked)  && !isReadOnly ? (
              <>
                <select
                  value={countyId}
                  onChange={(e) => {
                    handleTextInputUpdate(e, 'countyId', 'text');
                  }}
                >
                  <option value="" />
                  {countiesDetail
                    && countiesDetail.map((c) => (
                      <option key={c.key}
                        value={c.key}>
                        {c.value}
                      </option>
                    ))}
                </select>
              </>)
              :(
                <input type="text"
                  readOnly
                  value={county && county.value} />)}
          </label>
          <label>
              Additional Requirements
            <span className="required"> *</span>
            {!(locked)  && !isReadOnly ? (
              <>
                <textarea>
                  My Additional Requirements. 
                </textarea>
              </>)
              :(
                <input type="text"
                  readOnly
                  value={county && county.value} />)}
          </label>
        </div>
          <h4>Product Ranges</h4>
          {this.productRanges.map(range =>{
            return (<><label key={range.id}
                  className="wrapping">
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      checked={[1,9].indexOf(range.id) > -1}
                    />
                    <div className="toggle-icon"></div>
                  </div>
                  <span>
                  <b>{range.title}</b>
                  </span>
                </label>
                  <div><div>{range.description}</div>
                  </div></>);
          })}
          
      </div>
    );

  }
}

export default RetailerRequirementsForm;