import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../actions/navigationActions';
import { authActions } from '../../../actions/authActions';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleLogout = this.handleLogout.bind(this);
  }

  handleLogout(event) {
    this.props.logout();
    this.props.replace('/');
    event.preventDefault();
  }

  getYear() {
    return new Date().getFullYear();
  }

  render() {
    return (
      <footer className="page-footer">
        <div>
          <div className="links">
            <div className="link-section">
              <div className="section-title">Quick Links</div>
              <button
                type="button"
                className="link"
                onClick={() => this.props.replace('/')}
              >
                Home
              </button>
              <button
                type="button"
                className="link"
                onClick={() => this.props.replace('/myaccount')}
              >
                My Account
              </button>
              <button
                type="button"
                className="link"
                onClick={(e) => this.handleLogout(e)}
              >
                Logout
              </button>
            </div>
            {/* <div className="link-section">
              <div className="section-title">Help</div>
              <button
                type="button"
                className="link"
                onClick={() => this.props.replace(`/module/350`)}>
                Documentation
              </button>
              <button
                type="button"
                className="link"
                onClick={() => this.props.replace(`/module/357`)}>
                Video Guides
              </button>
              <button
                type="button"
                className="link"
                onClick={() => this.props.replace(`/module/250`)}>
                Get Help
              </button>
    </div> */}
            <div className="link-section">
              <div className="section-title">Legal</div>
              <button
                type="button"
                className="link"
                onClick={() => this.props.replace('/termsandconditions')}
              >
                Terms &amp; Conditions
              </button>
            </div>
          </div>
          <div
            className="copyright"
            onClick={() => window.location = ('https://orderly.io')}
          >
            ©
            {this.getYear()}
            {' '}
            Powered by
            <img
              className="orderly-logo"
              src="/assets/logo-orderly.png"
              alt="orderly logo"
            />
          </div>
        </div>
        <div></div>
      </footer>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(authActions.requestLogout()),

  push: (path) => dispatch(navigationActions.pushNavigation(path)),
  replace: (path) => dispatch(navigationActions.replaceNavigation(path)),
  reset: () => dispatch(navigationActions.resetNavigation()),
  goBack: () => dispatch(navigationActions.backNavigation()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Footer);
