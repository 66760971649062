import React from 'react';
import ApplicationHelpers from '../../helpers/applicationHelpers';
import statuses from '../../helpers/applicationStatusEnum';
import projectTypes from '../../helpers/applicationProjectTypeEnum';

export class ApplicationInformationForm extends React.Component {

  render() {
    const {
      handleTextInputUpdate,
      locked,
      isReadOnly,
      fields,
      companiesDetail,
      formatsDetail,
      primaryBusinessGeneratorsDetail,
      planningGrantTypesDetail,
      projectTypesDetail,
      tenureTypesDetail,
      companyUsersDetail,
      applicationDetail,
      siteTypesDetail,
      countiesDetail,
      leaseDetail,
      handleCheckInputUpdate,
      nearApplications  } = this.props;
    const {
      companyId,
      siteName,
      projectTypeId,
      formatId,
      tenureTypeId,
      netSquareFeet,
      grossSquareFeet,
      primaryBusinessGeneratorId,
      estimatedOpeningDate,
      plannedClosingDate,
      primaryContactId,
      secondaryContactid,
      statusId,
      street1,
      street2,
      city,
      countyId,
      postCode,
      latitude,
      longitude,
      showValidation,
      storeNo,
      developer,
      finalDeveloper,
      siteReferenceNo,
      projectType,
      headsOfTermsAgreed,
      leaseSignedOrSitePurchasedId,
      siteTypeId,
      planningRequired,
      planningGrantedId,
      planningGranted,
      planningSubmitted,
      planningComments,
      plannedSchematic,
      latestEstimatedOpeningDate,
    } = fields;

    const {
      company,
      tenureType,
      format,
      primaryBusinessGenerator,
      projectStatus,
      siteType,
    } = applicationDetail;

    const primaryContact = companyUsersDetail.find(
      (c) => c.userId === primaryContactId,
    );
    const secondaryContact = companyUsersDetail.find(
      (c) => c.userId === secondaryContactid,
    );
    const county = countiesDetail.find(
      (c) => c.key === countyId,
    );

    return (
      <div>
        <h3>Retailer Information</h3>
        <p>
          <span className="required"> *</span>
          {' '}
          These fields are mandatory to
          progress this application.
        </p>
        {!ApplicationHelpers.validateProximity(nearApplications, statusId, projectTypeId) ?
          <span  className="required">
           This application is too close to an existing application.
          </span> : null}
        <div className="form-section">
          <label>
            Business Partner
            <span className="required"> *</span>
            {statusId <= statuses.RetailerInterest && !isReadOnly ? (
              <>
                <select
                  value={companyId}
                  className={showValidation && !companyId ? 'invalid' : ''}
                  onChange={(e) => handleTextInputUpdate(e, 'companyId')}
                >
                  <option value="">Please Select</option>
                  {companiesDetail
                    && companiesDetail.map((c) => (
                      <option key={c.key}
                        value={c.key}>
                        {c.value}
                      </option>
                    ))}
                </select>
              </>
            ) : (
              <input type="text"
                readOnly
                value={company} />
            )}
          </label>
          <label>
            Primary Contact
            <span className="required"> *</span>
            {!locked ? (
              <>
                <select
                  value={primaryContactId}
                  onChange={(e) => handleTextInputUpdate(e, 'primaryContactId')}
                >
                  <option value="">Please Select</option>
                  {companyUsersDetail
                    && companyUsersDetail.map((c) => (
                      <option key={c.userId}
                        value={c.userId}>
                        {c.userName}
                      </option>
                    ))}
                </select>
              </>
            ) : (
              <input
                type="text"
                readOnly
                value={primaryContact && primaryContact.userName}
              />
            )}
          </label>
        </div>

        <div className="form-section">
          <label>
            Retailer Address
            <span className="required"> *</span>
            <input
              type="text"
              value={street1}
              maxLength="128"
              readOnly={(locked) && applicationDetail && applicationDetail.street1}
              onChange={(e) => handleTextInputUpdate(e, 'street1', 'text')}
            />
          </label>
          <label>
            Street 2
            <input type="text"
              value={street2}
              maxLength="128"
              readOnly={locked}
              onChange={(e) => handleTextInputUpdate(e, 'street2', 'text')} />
          </label>
          <label>
            City
            <span className="required"> *</span>
            <input type="text"
              value={city}
              maxLength="128"
              readOnly={locked}
              onChange={(e) => handleTextInputUpdate(e, 'city', 'text')}/>
          </label>
          <label>
              Country
            <span className="required"> *</span>
            {!(locked)  && !isReadOnly ? (
              <>
                <select
                  value={countyId}
                  onChange={(e) => {
                    handleTextInputUpdate(e, 'countyId', 'text');
                  }}
                >
                  <option value="" />
                  {countiesDetail
                    && countiesDetail.map((c) => (
                      <option key={c.key}
                        value={c.key}>
                        {c.value}
                      </option>
                    ))}
                </select>
              </>)
              :(
                <input type="text"
                  readOnly
                  value={county && county.value} />)}
          </label>
          <label>
            Post Code
            <span className="required"> *</span>
            <input type="text"
              value={postCode}
              maxLength={8}
              readOnly={locked}
              onChange={(e) => handleTextInputUpdate(e, 'postCode', 'int')} />
          </label>
        </div>
      </div>
    );

  }
}

export default ApplicationInformationForm;