import React, { useState, useEffect } from 'react';
import StatusChangeModal from './StatusChangeModal';

function FcrDetail (props) {
  const [showApprovalModal, setShowApprovalModal] = useState(false);  
  const [showRejectionModal, setShowRejectionModal] = useState(false);
  const [fcr, setFcr] = useState({});

  useEffect(() => {
    fetchFcr();
  }, []);

  const fetchFcr = () => {
    async function loadFormData(){
      const response = await fetch(`/api/fcr/v1/fetch?id=${props.match.params.id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });

      if (response.status == 200){
        const data = await response.json();
        setFcr(data);
      }
    }
    loadFormData();
  };

  const completeRequest = () => {
    setShowApprovalModal(false);
    setShowRejectionModal(false);
    fetchFcr();
  };

  return (
    <div className="fcr-detail-page page">
      <div className="form-container">
        <div className="form-header">
          <div className="header-title">
            <div className="icon icon-item-catalogue"></div>
            <h2>FCR Details</h2>
          </div>
        </div>
        {fcr?.statusName == 'Pending' &&
        <div
          style={{ padding: '20px 0 18px 30px' }}
        >
          <button
            type='button'
            onClick={() => setShowApprovalModal(true)}
            style={{ marginRight:'18px' }}
            className='action'>
                    Approve
          </button>
          <button
            type='button'
            onClick={() => setShowRejectionModal(true)}
            className='action negative'>
                    Reject
          </button>
        </div>
        }
        <h4>Detail</h4>
        <div className="form-body">
          <label>
              FCR ID:{' '}
            <span>{fcr?.omId}</span>
          </label>
          <label>
              Status:{' '}
            <span>{fcr?.statusName}</span>
          </label>
          <label>
              Status Change Date:{' '}
            <span>{fcr?.statusChangeDate ? new Date(fcr?.statusChangeDate).toLocaleDateString() : ''}</span>
          </label>
          <label>
              FCR Received Date:{' '}
            <span>{fcr?.fcrReceivedDate ? new Date(fcr.fcrReceivedDate).toLocaleDateString() : ''}</span>
          </label>
        </div>
        <h4>Customer</h4>
        <div className='form-body'>
          <label>
              Customer Number:{' '}
            <span>{fcr?.customerNo}</span>
          </label>
        </div>
        <div className="form-body">
          <label>
              Customer Comments:{' '}
            <span className='large-text'>{fcr?.customerComments}</span>
          </label>
        </div>  
        <h4>Item</h4>
        <div className="form-body">
          <label>
              Date (w/c):{' '}
            <span>{fcr?.date ? new Date(fcr?.date).toLocaleDateString() : ''}</span>
          </label>
          <label>
             ICC Group:{' '}
            <span>{fcr?.iccGroupName}</span>
          </label>
          <label>
            Demand Class{' '}
            <span>{fcr?.demandClass}</span>
          </label>
          <label>
              Item Name:{' '}
            <span>{fcr?.itemName}</span>
          </label>
          <label>
              SKU:{' '}
            <span>{fcr?.sku}</span>
          </label>    
          <label>
              UOM:{' '}
            <span>{fcr?.uom}</span>
          </label> 
          <label>
              Original Quantity:{' '}
            <span>{fcr?.originalQuantity}</span>
          </label>
          <label>
              Proposed Quantity:{' '}
            <span>{fcr?.proposedQuantity}</span>
          </label>
        </div>
        <h4>Additional Information</h4>
        <div className="form-body">
          <label>
              Reason:{' '}
            <span className='large-text'>{fcr?.reason}</span>
          </label>
        </div>      
        <div className="form-body">
          <label>
              Orderly Comments:{' '}
            <span className='large-text'>{fcr?.starbucksComments}</span>
          </label>
        </div>
      </div>
      {showApprovalModal && 
        <StatusChangeModal
          id={props.match.params.id}
          status={'Approve'}
          complete={() => completeRequest()}
          cancel={() => setShowApprovalModal(false)}
        />
      }
      {showRejectionModal && 
        <StatusChangeModal
          id={props.match.params.id}
          status={'Reject'}
          complete={() => completeRequest()}
          cancel={() => setShowRejectionModal(false)}
        />
      }
    </div>
  );
}

export default FcrDetail;
