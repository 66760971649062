import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../actions/navigationActions';
import Pagination from '../../shared/Pagination';
import MultiSelect from '../../siteID/siteIDApplications/list/MultiSelect';

function BrfOrders(props) {
  const [isClosed, setIsClosed] = useState(window.location.toString().toLocaleLowerCase().indexOf('close') > -1);
  const [isAdmin, setIsAdmin] = useState(window.location.toString().toLocaleLowerCase().indexOf('admin') > -1);
  const [displayMode, setDisplayMode] = useState(1);
  const [isLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [rowCount, setRowCount] = useState(0);

  const [filterForms, setFilterForms] = useState({ 
    _3pl: undefined,
    statusId: undefined,
    marketId: undefined,
    customerNumber: undefined,
    trackingNumber: undefined,
    sbuxOrderNumber: undefined });
  const [forms, setForms] = useState({});
  const [markets, setMarkets] = useState({});
  const [_3pls, set3pls] = useState({});
  const [pageRendered, setPageRendered] = useState(false);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [selectedYears, setSelectedYears] = useState([]);

  useEffect(() => {
    async function changePage() {
      await handleFilterWithParameters({ ...filterForms, selectedMonths, selectedYears, isAdmin, isClosed, page, pageSize });
      setPageRendered(true);
    }
    changePage();
  }, [page]);

  const getForms = async(params) => {
    const convertToQuerystring = (obj) => {
      const qs = Object.keys(obj)
        .filter(key => obj[key] !== undefined)
        .map(key => 
          key =='selectedMonths' && params.selectedMonths ? 
            params.selectedMonths.map((i) => `${key}=${i}`).join('&'):
            key =='selectedYears' && params.selectedYears ? 
              params.selectedYears.map((i) => `${key}=${i}`).join('&'):
              `${key}=${obj[key]}`)
        .join('&');
    
      return qs.length === 0
        ? qs
        : `?${qs}`;
    };
      
    const response = await fetch(`/api/omorderbookingrequest/v1/GetOrderBookingRequestForms${convertToQuerystring(params, page, pageSize)}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    });

    if (response.status == 200){
      const data = await response.json();

      setForms(data.forms.data);
      setMarkets(data.mbus);
      set3pls(data._3pls);
      setPageSize(data.forms.pageSize);
      setPage(data.forms.pageNumber);
      setRowCount(data.forms.totalRecords);
      setIsAdmin(data.isAdmin);
    }
  };

  const handleExportSummary = () => {
    async function loadFormData(){
      const convertToQuerystring = (obj) => {
        const qs = Object.keys(obj)
          .filter(key => obj[key] !== undefined)
          .map(key => 
            key =='selectedMonths' && selectedMonths ? 
              selectedMonths.map((i) => `${key}=${i}`).join('&'):
              key =='selectedYears' && selectedYears ? 
                selectedYears.map((i) => `${key}=${i}`).join('&'):
                `${key}=${obj[key]}`)
          .join('&');
    
        return qs.length === 0
          ? qs
          : `?${qs}`;
      };
      
      const response = await fetch(`/api/omorderbookingrequest/v1/ExportOrderBookingRequestForms${convertToQuerystring(({ ...filterForms, selectedMonths, selectedYears, isAdmin, isClosed, page, pageSize }), page, pageSize)}`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });

      if (response.status == 200){
        const data = await response.blob();

        let filename = response.headers.get('content-disposition');
        let nameIndex = filename.indexOf('filename="');

        if (nameIndex > -1) {
          filename = filename.slice(nameIndex + 10);
          nameIndex = filename.indexOf('"');

          if (nameIndex > -1) {
            filename = filename.slice(0, nameIndex);
          }
        } else {
          nameIndex = filename.indexOf('filename=');
          if (nameIndex > -1) {
            filename = filename.slice(nameIndex + 9);

            nameIndex = filename.indexOf(';');

            if (nameIndex > -1) {
              filename = filename.slice(0, nameIndex);
            }
          }
        }

        const a = document.createElement('a');
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = filename;
        a.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }, 0);
      }
    }
    loadFormData();
  };

  const changeDisplayMode = (mode) => {
    setDisplayMode(mode);
  };


  const handleFilterWithParameters = async(params) => {
    const pathname = window.location.pathname;
    
    const filterString = `_3pl=${filterForms._3pl}&statusId=${filterForms.statusId}&marketId=${filterForms.marketId}`
      + `&customerNumber=${filterForms.customerNumber}&trackingNumber=${filterForms.trackingNumber}&sbuxOrderNumber=${filterForms.sbuxOrderNumber}`
      + `&selectedMonths=${params.selectedMonths}&selectedYears=${params.selectedYears}`;
      
    props.replaceNavigation(`${pathname}?page=${params.page}&${filterString}`);
    
    await getForms(params);
  };
 
  const handleFilter = async() => {
    const page = 1;
    setPage(page);

    await handleFilterWithParameters({ ...filterForms, selectedMonths, selectedYears, isAdmin, isClosed, page, pageSize });
  };

  const handleFilterClear = async() => {
    const newFilters = {
      _3pl: '',
      statusId: '',
      marketId: '',
      customerNumber: '',
      trackingNumber: '',
      sbuxOrderNumber: '',
    };
    const page = 1;
    setPage(1);
    setFilterForms({ ...newFilters });
    setSelectedMonths([]);
    setSelectedYears([]);

    await handleFilterWithParameters({ ...newFilters, selectedMonths: [], selectedYears: [], isAdmin, isClosed, page, pageSize });
  };

  const handleFilterInputUpdate = (e, fieldName) => {
    setFilterForms({ ...filterForms, [fieldName] : e.target.value });
  };

  const doPaging = async(newPage) => {
    setPage(newPage);
  };

  const handleViewClosedOrders = () => {
    props.replaceNavigation('/module/315/BRF-Closed');
    setIsClosed(true);
  };

  const doToggleMonthsMulti = (selectedValues) => {
    setSelectedMonths(selectedValues);
  };

  const doToggleYearsMulti = (selectedValues) => {
    setSelectedYears(selectedValues);
  };

  const months = [
    { key: 1, value: 'January' },
    { key: 2, value: 'February' },
    { key: 3, value: 'March' },
    { key: 4, value: 'April' },
    { key: 5, value: 'May' },
    { key: 6, value: 'June' },
    { key: 7, value: 'July' },
    { key: 8, value: 'August' },
    { key: 9, value: 'September' },
    { key: 10, value: 'October' },
    { key: 11, value: 'November' },
    { key: 12, value: 'December' },
  ];
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 1;

  const availableTimePeriods = [
    { key: currentYear.toString(), value: currentYear.toString() }, { key: startYear.toString(), value: startYear.toString() },
  ];

  const statuses = [
    { key: 1, value: 'Pending' },
    { key: 2, value: 'Completed' },
  ];

  return (
    <div>
      {pageRendered && (
        <React.Fragment>
          <div className='page'>
            <div className="floating-panel form-container">
              <div className="form-header apac">
                <div className="header-title">
                  <div className="icon icon-table"></div>
                  <h2>{isClosed ? 'BRF Closed Orders Archive' : 'BRF In Progress Orders'}</h2>
                </div>

                <div className="search-order-container">

                  <div className="group-details accordion download-container summary"
                    style={{ marginRight: '18px' } }
                  >
                    <div className="accordion-header">
                      <h3>Download Summary</h3>
                    </div>
                    <div className="accordion-body horizontal-flex order-fields">
                      <div>
                        <button
                          className="action export"
                          type="button"
                          title="Export"
                          disabled={isLoading}
                          onClick={() => handleExportSummary()}
                        >
                          <div
                            className="icon icon-download-light"
                            alt="Export"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  {!isClosed ?
                    <div className="group-details accordion download-container"
                      style={{ marginRight: '18px' } }>
                      <div className="accordion-header">
                        <h3>View Closed Orders</h3>
                      </div>
                      <div className="accordion-body horizontal-flex order-fields">

                        <div>
                          <button
                            className="action"
                            onClick={() => handleViewClosedOrders()}
                            type="button"
                            title="Closed Orders"
                            disabled={isLoading}
                          >
                    View
                          </button>
                        </div>
                      </div>
                    </div> : null}
                </div>
              </div>
            </div>
            <div className='floating-panel form-container'>
              <div> 
                <div className='form-body'>
                  <label>
                   3PL
                    <select
                      value={filterForms._3pl}
                      onChange={e => handleFilterInputUpdate(e, '_3pl')}
                    >
                      <option value=''>All 3PLs</option>
                      {_3pls && _3pls.length && _3pls.map(m => 
                        <option key={m.key}
                          value={m.key}>{m.value}</option>,
                      )
                      }
                    </select>
                  </label>
                  <>
                    <label>
                   Status
                      <select
                        value={filterForms.statusId}
                        onChange={e => handleFilterInputUpdate(e, 'statusId')}
                      >
                        <option value=''>Select a Status</option>
                        {statuses && statuses.length && statuses.map(m => 
                          <option key={m.key}
                            value={m.key}>{m.value}</option>,
                        )
                        }
                      </select>
                    </label>
                    <label>
                    MBU
                      <select
                        value={filterForms.marketId}
                        onChange={e => handleFilterInputUpdate(e, 'marketId')}
                      >
                        <option value=''>Select an MBU</option>
                        {markets && markets.length && markets.map(m => 
                          <option key={m.key}
                            value={m.key}>{m.value}</option>,
                        )
                        }
                      </select>
                    </label>
                    <label>
                    Customer Number
                      <input
                        type="text"
                        placeholder="Customer number"
                        onChange={e => handleFilterInputUpdate(e, 'customerNumber')}
                        value={filterForms.customerNumber}
                      />
                    </label>
                  </>
                  <label>
                  Tracking Number
                    <input
                      type="text"
                      placeholder="Tracking number"
                      onChange={e => handleFilterInputUpdate(e, 'trackingNumber')}
                      value={filterForms.trackingNumber}
                    />
                  </label>
                  <label>
                  Customer Order Number
                    <input
                      type="text"
                      placeholder="Customer order number"
                      onChange={e => handleFilterInputUpdate(e, 'sbuxOrderNumber')}
                      value={filterForms.sbuxOrderNumber}
                    />
                  </label>    
                  <MultiSelect
                    title="Months"
                    selectedValues={selectedMonths}
                    valueChanged={doToggleMonthsMulti}
                    values={months}
                    valueName="selectedMonths"
                  />
                  <MultiSelect
                    title="Years"
                    selectedValues={selectedYears}
                    valueChanged={doToggleYearsMulti}
                    values={availableTimePeriods}
                    valueName="selectedYears"
                  />       
                </div>
              </div>
              <div
                style={{ paddingBottom:'18px', display:'flex', justifyContent:'flex-end' }}
              >
                <button onClick={() => handleFilter()}
                  type='button'
                  style={{ marginRight:'18px' }}
                  className='action'>
                    Filter
                </button>
                <button onClick={() => handleFilterClear()}
                  type='button'
                  className='action negative'>
                    Clear Filter
                </button>
              </div>
            </div>
        
            <div className={'customer-ordertracker-container in-progress'}>
              <div className="table-container">
                <div className="table-header">
                  <div className="display-options">
            Table Size
                    <button
                      type="button"
                      className="icon-display-mode-1"
                      disabled={displayMode === 1}
                      onClick={() => changeDisplayMode(1)}
                    />
                    <button
                      type="button"
                      className="icon-display-mode-2"
                      disabled={displayMode === 2}
                      onClick={() => changeDisplayMode(2)}
                    />
                    <button
                      type="button"
                      className="icon-display-mode-3"
                      disabled={displayMode === 3}
                      onClick={() => changeDisplayMode(3)}
                    />
                  </div>
                  <h2>
            Search Results
                  </h2>
                  <div className="pagination-controls">
                    <Pagination
                      rowCount={rowCount}
                      page={page}
                      pageSize={pageSize}
                      doPaging={doPaging}
                    />
                  </div>
                </div>
                <div className="table-body">
                  <table className={`table-display-mode-${displayMode}`}>
                    <thead>
                      <tr>
                        <th>Status</th>
                        <th>3PL</th>
                        <th>MBU</th>
                        <th>Customer Number</th>
                        <th>Tracking Number</th>
                        <th>Customer Order Number</th>               
                        <th>View BRF</th>
                      </tr>
                    </thead>
                    <tbody>
                      {forms && forms.length === 0 &&
                <tr>
                  <td
                    colSpan={9}
                    className="no-records"
                    style={{ textAlign: 'center' } }
                  >
                    {isLoading ? 'Loading...' : 'No forms found.'}
                  </td>
                </tr>
                      }
                      {forms && forms.length > 0 && forms.map(o => 
                        <tr key={o.id}>
                          <td>{o.completed ? 'Completed' : 'Pending'}</td>
                          <td>{o._3pl}</td>
                          <td>{o.mbu}</td>
                          <td>{o.customerNumber}</td>
                          <td>{o.trackingNumber}</td>
                          <td>{o.sbOrderNumber}</td>
                          <td>
                            {o.trackingNumber ?
                              <button
                                className="link"
                                type="button"
                                onClick={() => props.pushNavigation(`/module/315/BRF-Edit/${o.id}`)}
                              >
                            View Details
                              </button> : null}
                          </td>
                        </tr>,
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

const mapStateToProps = () => {
  return {  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    pushNavigation: (url) => dispatch(navigationActions.pushNavigation(url)),
    replaceNavigation: (url) => dispatch(navigationActions.replaceNavigation(url)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BrfOrders);