import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { navigationActions } from "../../../actions/navigationActions";
import qs from "query-string";
import { siteIDReportingActions } from "../../../actions/siteIDReportingActions";
import FilterPanel from "./shared/FilterPanel";
import HeatMapPanel from "./shared/HeatMapPanel";
import {
  PieChart,
  Pie,
  BarChart,
  Bar,
  Legend,
  Tooltip,
  YAxis,
  XAxis,
  CartesianGrid,
  Cell
} from "recharts";
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import { SliderRail, Handle, Track, Tick } from "./shared/SliderComponents"; // example render components - source below

//import TagCloud from "react-tag-cloud";
//import StarRating from "./shared/StarRating";

const Months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

const COLORS = [
  "#b6e2b6",
  "#aadaff",
  "#f8f3da",
  "#f4d88b",
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042"
];

const STATUSCOLORS = [
  "#c5c5c5",
  "#ffffff",
  "#ffcd47",
  "#faad00",
  "#3782df",
  "#6137df",
  "#ec6e6e",
  "#ec2a2a",
  "#02a862",
  "#c5c5c5",
  "#c5c5c5",
  "#c5c5c5"
];

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={6} textAnchor="end" fill="#666">
          {payload.value}
        </text>
      </g>
    );
  }
}

class SiteIDReporting extends React.Component {
  constructor(props) {
    super(props);

    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    var firstDayOfMonth = new Date(y, m, 1);
    var firstDayNextMonth = new Date(firstDayOfMonth);
    firstDayNextMonth.setMonth(m + 1);

    this.state = {
      now: firstDayNextMonth,
      fromDate: firstDayOfMonth,
      toDate: firstDayNextMonth,
      companyId: undefined,
      formatId: undefined,
      countyId: undefined,
      statusId: undefined,
      questionTopic: ""
    };

    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleQuestionTopic = this.handleQuestionTopic.bind(this);

    this.handleFetchData = this.handleFetchData.bind(this);

    this.getVolumeMetrics = this.getVolumeMetrics.bind(this);
    this.getDurationMetrics = this.getDurationMetrics.bind(this);
    this.renderSlider = this.renderSlider.bind(this);

    this.onSliderChange = this.onSliderChange.bind(this);
  }

  handleQuestionTopic(e) {
    const questionTopic = e.target.value;
    this.setState({ questionTopic });

    this.updateCurrentUrl({ ...this.state, questionTopic });
  }

  handleFilterChange(e, filterName) {
    this.setState({ [filterName]: e.target.value });
    this.updateCurrentUrl({ ...this.state, [filterName]: e.target.value });
    this.handleFetchData({ ...this.state, [filterName]: e.target.value });
  }

  updateCurrentUrl(data) {
    const { pathname } = this.props.location;
    this.props.replace(
      `${pathname}?${
      !data.fromDate
        ? ""
        : `fromdate=${new Date(data.fromDate).toISOString()}&`
      }${!data.toDate ? "" : `todate=${new Date(data.toDate).toISOString()}&`}${
      !data.companyId ? "" : `companyid=${data.companyId}&`
      }${!data.formatId ? "" : `formatid=${data.formatId}&`}${
      !data.countyId ? "" : `countyid=${data.countyId}&`
      }${!data.statusId ? "" : `statusid=${data.statusId}&`}&questiontopic=${
      data.questionTopic
      }`
    );
  }

  handleFetchData(data) {
    this.props.getData(
      data.fromDate,
      data.toDate,
      data.companyId,
      data.formatId,
      data.countyId,
      data.statusId
    );
  }

  componentDidMount() {
    const { search } = this.props.location;

    const parsed = qs.parse(search.toLowerCase());

    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    var firstDayOfMonth = new Date(y, m, 1);
    var firstDayNextMonth = new Date(firstDayOfMonth);
    firstDayNextMonth.setMonth(m + 1);

    let questionTopic = parsed.questiontopic ? parsed.questiontopic : "";
    let fromDate = parsed.fromdate
      ? new Date(parsed.fromdate)
      : firstDayOfMonth;
    let toDate = parsed.todate ? new Date(parsed.todate) : firstDayNextMonth;
    let companyId = parsed.companyid ? parsed.companyid : undefined;
    let formatId = parsed.formatid ? parsed.formatid : undefined;
    let countyId = parsed.countyid ? parsed.countyid : undefined;
    let statusId = parsed.statusid ? parsed.statusid : undefined;

    const newState = {
      questionTopic,
      fromDate,
      toDate,
      companyId,
      formatId,
      countyId,
      statusId
    };

    this.setState(newState);

    this.props.getReferenceData();

    //Triggered by slider
    this.handleFetchData(newState);

    //this.fetchData(timePeriod);
  }
  /*
  fetchData(daysBack) {
    const days = 86400000;

    //23:59:59 today
    let newDate = new Date();
    newDate.setHours(0, 0, 0, 0);
    newDate = new Date(newDate.getTime() + days);

    let oldDate = new Date(newDate.getTime() - days * daysBack);

    this.props.getActionData(oldDate, newDate);
    this.props.getModuleData(oldDate, newDate);
    this.props.getUserData(oldDate, newDate);
  }*/

  getVolumeMetrics() {
    const { data, companies, formats, counties, statuses } = this.props;
    const { questionTopic } = this.state;

    let components = [];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      percent,
      name,
      index
    }) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 0.7;

      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);

      const x1 = cx + outerRadius * 1.1 * Math.cos(-midAngle * RADIAN);
      const y2 = cy + outerRadius * 1.1 * Math.sin(-midAngle * RADIAN);

      if (percent < 0.05) {
        return null;
      }
      return (
        <React.Fragment>
          <text
            x={x1}
            y={y2}
            fill="#707070"
            textAnchor={x1 > cx ? "start" : "end"}
            dominantBaseline="central"
            className="outer-label"
          >
            {name}
          </text>
          <text
            x={x + (x > cx ? -15 : 15)}
            y={y}
            stroke="#777"
            textAnchor={x > cx ? "start" : "end"}
            dominantBaseline="central"
            className="inner-label"
          >
            {`${(percent * 100).toFixed(0)}%`}
          </text>
        </React.Fragment>
      );
    };

    if (data.counties && data.counties.length > 0 && questionTopic === "") {
      const appByCountyData = data.counties.map(d => {
        if (d.value)
          return {
            name: counties[d.key.toString()],
            value: d.value
          };
        return null;
      });

      components.push(
        <div className="stat-group" key="appByCountyPie">
          <div className="stat-header">
            <h3>Applications by Territory</h3>
          </div>
          <div className="stat-body">
            <PieChart width={500} height={300}>
              <Pie
                dataKey="value"
                nameKey="name"
                data={appByCountyData}
                outerRadius={100}
                label={renderCustomizedLabel}
                labelLine={false}
              >
                {appByCountyData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    stroke="#777"
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </div>
        </div>
      );
    }

    if (data.statuses && data.statuses.length > 0 && questionTopic === "") {
      let appByStatusData = data.statuses.map(d => {
        if (d.value)
          return {
            name: statuses[d.key.toString()],
            statusId: d.key,
            value: d.value
          };
        return null;
      });

      components.push(
        <div className="stat-group" key="appByStatusPie">
          <div className="stat-header">
            <h3>Applications by Status</h3>
          </div>
          <div className="stat-body">
            <PieChart width={500} height={300}>
              <Pie
                dataKey="value"
                nameKey="name"
                data={appByStatusData}
                outerRadius={100}
                label={renderCustomizedLabel}
                labelLine={false}
              >
                {appByStatusData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={STATUSCOLORS[entry.statusId]}
                    stroke="#777"
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </div>
        </div>
      );
    }

    if (data.licensees && data.licensees.length > 0 && questionTopic === "") {
      const appByLicenseeData = data.licensees.map(d => {
        if (d.value)
          return {
            name: companies[d.key.toString()],
            value: d.value
          };
        return null;
      });

      components.push(
        <div className="stat-group" key="appByLicenseePie">
          <div className="stat-header">
            <h3>Applications by Licensee</h3>
          </div>
          <div className="stat-body">
            <PieChart width={500} height={300}>
              <Pie
                dataKey="value"
                nameKey="name"
                data={appByLicenseeData}
                outerRadius={100}
                label={renderCustomizedLabel}
                labelLine={false}
              >
                {appByLicenseeData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    stroke="#777"
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </div>
        </div>
      );
    }

    if (data.formats && data.formats.length > 0 && questionTopic === "") {
      const appByFormatData = data.formats.map(d => {
        if (d.value)
          return {
            name: formats[d.key.toString()],
            value: d.value
          };
        return null;
      });

      components.push(
        <div className="stat-group" key="appByFormatPie">
          <div className="stat-header">
            <h3>Applications by Format</h3>
          </div>
          <div className="stat-body">
            <PieChart width={350} height={300}>
              <Pie
                dataKey="value"
                nameKey="name"
                data={appByFormatData}
                outerRadius={100}
                label={renderCustomizedLabel}
                labelLine={false}
              >
                {appByFormatData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    stroke="#777"
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </div>
        </div>
      );
    }

    //Bar charts
    if (data.counties && data.counties.length > 0 && questionTopic === "") {
      const appByCountyBarData = data.counties.map(d => {
        if (d.value)
          return {
            name: counties[d.key.toString()],
            value: d.value
          };
        return null;
      });

      components.push(
        <div className="stat-group" key="appByCountyBar">
          <div className="stat-header">
            <h3>Applications by Territory</h3>
          </div>
          <div className="stat-body  stat-body--x2">
            <BarChart
              width={500}
              height={300}
              data={appByCountyBarData}
              margin={{
                top: 5,
                right: 30,
                left: 75,
                bottom: 5
              }}
              layout="vertical"
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" allowDecimals={false} />
              <YAxis dataKey="name" type="category" />
              <Tooltip />
              <Bar dataKey="value" layout="vertical">
                {appByCountyBarData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    stroke="#777"
                  />
                ))}
              </Bar>
            </BarChart>
          </div>
        </div>
      );
    }

    if (data.statuses && data.statuses.length > 0 && questionTopic === "") {
      const appByStatusBarData = data.statuses
        .sort((a, b) => a.key - b.key)
        .map(d => {
          return { name: statuses[d.key], value: d.value, statusId: d.key };
        });

      components.push(
        <div className="stat-group" key="appByStatusBar">
          <div className="stat-header">
            <h3>Applications by Status</h3>
          </div>
          <div className="stat-body  stat-body--x2">
            <BarChart
              width={500}
              height={300}
              data={appByStatusBarData}
              margin={{
                top: 5,
                right: 30,
                left: 75,
                bottom: 5
              }}
              layout="vertical"
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" allowDecimals={false} />
              <YAxis
                dataKey="name"
                type="category"
                tick={<CustomizedAxisTick />}
              />
              <Tooltip />

              <Bar dataKey="value" layout="vertical">
                {appByStatusBarData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={STATUSCOLORS[entry.statusId]}
                    stroke="#777"
                  />
                ))}
              </Bar>
            </BarChart>
          </div>
        </div>
      );
    }

    if (data.licensees && data.licensees.length > 0 && questionTopic === "") {
      const appByLicenseeBarData = data.licensees.map(d => {
        return {
          name: companies[d.key.toString()],
          value: d.value
        };
      });

      components.push(
        <div className="stat-group" key="appByLicenseeBar">
          <div className="stat-header">
            <h3>Applications by Licensee</h3>
          </div>
          <div className="stat-body  stat-body--x2">
            <BarChart
              width={500}
              height={300}
              data={appByLicenseeBarData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5
              }}
              layout="vertical"
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" allowDecimals={false} />
              <YAxis dataKey="name" type="category" />
              <Tooltip />
              <Bar dataKey="value" layout="vertical">
                {appByLicenseeBarData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    stroke="#777"
                  />
                ))}
              </Bar>
            </BarChart>
          </div>
        </div>
      );
    }

    if (data.formats && data.formats.length > 0 && questionTopic === "") {
      const appByFormatBarData = data.formats.map(d => {
        return {
          name: formats[d.key.toString()],
          value: d.value
        };
      });

      components.push(
        <div className="stat-group" key="appByFormatBar">
          <div className="stat-header">
            <h3>Applications by Format</h3>
          </div>
          <div className="stat-body  stat-body--x2">
            <BarChart
              width={500}
              height={300}
              data={appByFormatBarData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis allowDecimals={false} />
              <Tooltip />
              <Bar dataKey="value">
                {appByFormatBarData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    stroke="#777"
                  />
                ))}
              </Bar>
            </BarChart>
          </div>
        </div>
      );
    }

    //Rejected
    if (data.rejected && data.rejected.length > 0 && questionTopic === "") {
      const rejectedByStatusBarData = data.rejected.map(d => {
        return {
          name: statuses[d.key.toString()],
          value: d.value,
          statusId: d.key
        };
      });

      components.push(
        <div className="stat-group" key="rejectedByStatusBar">
          <div className="stat-header">
            <h3>Rejected Applications By Status</h3>
          </div>
          <div className="stat-body  stat-body--x2">
            <BarChart
              width={500}
              height={300}
              data={rejectedByStatusBarData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis allowDecimals={false} />
              <Tooltip />
              <Bar dataKey="value">
                {rejectedByStatusBarData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={STATUSCOLORS[entry.statusId]}
                    stroke="#777"
                  />
                ))}
              </Bar>
            </BarChart>
          </div>
        </div>
      );
    }

    //Extensions
    if (data.extensions && data.extensions.length > 0 && questionTopic === "") {
      const extensionBarData = data.extensions.map(d => {
        return {
          name: d.key.toString(),
          value: d.value
        };
      });

      components.push(
        <div className="stat-group" key="appByNumExtensionBar">
          <div className="stat-header">
            <h3>Applications By Number Of Extensions</h3>
          </div>
          <div className="stat-body  stat-body--x2">
            <BarChart
              width={500}
              height={300}
              data={extensionBarData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis allowDecimals={false} />
              <Tooltip />
              <Bar dataKey="value">
                {extensionBarData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    stroke="#777"
                  />
                ))}
              </Bar>
            </BarChart>
          </div>
        </div>
      );
    }

    if (data.durations && data.durations.length > 0 && questionTopic === "") {
      const appByDurationBar = data.durations.map(d => {
        return {
          name: companies[d.key.toString()],
          value: (d.value / (60 * 24)).toFixed(0)
        };
      });

      components.push(
        <div className="stat-group" key="appByDurationBar">
          <div className="stat-header">
            <h3>Applications by Average Duration</h3>
          </div>
          <div className="stat-body  stat-body--x2">
            <BarChart
              width={500}
              height={300}
              data={appByDurationBar}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis allowDecimals={false} unit="d" />
              <Tooltip />
              <Bar dataKey="value">
                {appByDurationBar.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    stroke="#777"
                  />
                ))}
              </Bar>
            </BarChart>
          </div>
        </div>
      );
    }

    if (data.rejected && data.rejected.length > 0 && questionTopic === "") {
      const rejectedByStatusData = data.rejected.map(d => {
        if (d.value)
          return {
            name: statuses[d.key.toString()],
            value: d.value,
            statusId: d.key
          };
        return null;
      });

      components.push(
        <div className="stat-group" key="rejectedByStatusPie">
          <div className="stat-header">
            <h3>Rejected Applications by Status</h3>
          </div>
          <div className="stat-body">
            <PieChart width={350} height={300}>
              <Pie
                dataKey="value"
                nameKey="name"
                data={rejectedByStatusData}
                outerRadius={100}
                label={renderCustomizedLabel}
                labelLine={false}
              >
                {rejectedByStatusData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={STATUSCOLORS[entry.statusId]}
                    stroke="#777"
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </div>
        </div>
      );
    }

    if (data.extensions && data.extensions.length > 0 && questionTopic === "") {
      const appByExtensionBarData = data.extensions.map(d => {
        if (d.value)
          return {
            name: d.key.toString(),
            value: d.value
          };
        return null;
      });

      components.push(
        <div className="stat-group" key="appExtensionPie">
          <div className="stat-header">
            <h3>Applications by Requested Extensions</h3>
          </div>
          <div className="stat-body">
            <PieChart width={350} height={300}>
              <Pie
                dataKey="value"
                nameKey="name"
                data={appByExtensionBarData}
                outerRadius={100}
                label={renderCustomizedLabel}
                labelLine={false}
              >
                {appByExtensionBarData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    stroke="#777"
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </div>
        </div>
      );
    }

    if (data.durations && data.durations.length > 0 && questionTopic === "") {
      const appByDurationData = data.durations.map(d => {
        if (d.value)
          return {
            name: companies[d.key.toString()],
            value: parseInt((d.value / (60 * 24)).toFixed(0))
          };
        return null;
      });

      components.push(
        <div className="stat-group" key="appDurationPie">
          <div className="stat-header">
            <h3>Applications by Average Duration (Days)</h3>
          </div>
          <div className="stat-body">
            <PieChart width={500} height={300}>
              <Pie
                dataKey="value"
                nameKey="name"
                data={appByDurationData}
                outerRadius={100}
                label={renderCustomizedLabel}
                labelLine={false}
              >
                {appByDurationData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    stroke="#777"
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </div>
        </div>
      );
    }
    return components;
  }

  getDurationMetrics() {
    const { data, statuses } = this.props;
    const { questionTopic } = this.state;

    let components = [];

    //Bar charts
    if (data.expiries && data.expiries.length > 0 && questionTopic === "") {
      var expiryData = {};

      data.expiries.forEach(status => {
        expiryData[status.key] = { name: statuses[status.key] };
        status.stats.forEach(stat => {
          let key = "";
          switch (true) {
            case stat.key < 1:
              key = "Next 24 hours";
              break;
            case stat.key < 7:
              key = "Next 7 Days";
              break;
            case stat.key < 30:
              key = "Next 30 Days";
              break;
            case stat.key < 90:
              key = "1-3 Months";
              break;
            default:
              key = "3+ Months";
              break;
          }

          if (expiryData[status.key][key]) {
            expiryData[status.key][key] += stat.value;
          } else {
            expiryData[status.key][key] = stat.value;
          }
        });
      });

      var expiryData2 = Object.entries(expiryData).map(([key, value]) => value);

      components.push(
        <div className="stat-group" key="appExpiryBar">
          <div className="stat-header">
            <h3>Applications by Time to Expiry</h3>
          </div>
          <div className="stat-body  stat-body--x2">
            <BarChart
              width={500}
              height={300}
              data={expiryData2}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" type="category" />
              <YAxis type="number" allowDecimals={false} />
              <Tooltip />
              <Legend />
              <Bar dataKey="Next 24 hours" stroke="#777" fill="#ff2b2b" />
              <Bar dataKey="Next 7 Days" stroke="#777" fill="#fb4444" />
              <Bar dataKey="Next 30 Days" stroke="#777" fill="#fd6d6d" />
              <Bar dataKey="1-3 Months" stroke="#777" fill="#ff9090" />
              <Bar dataKey="3+ Months" stroke="#777" fill="#ffb1b1" />
            </BarChart>
          </div>
        </div>
      );
    }

    if (
      data.statusDurations &&
      data.statusDurations.length > 0 &&
      questionTopic === ""
    ) {
      var statusDurationsData = {};

      data.statusDurations.forEach(status => {
        statusDurationsData[status.key] = { name: statuses[status.key] };
        status.stats.forEach(stat => {
          let key = "";
          switch (true) {
            case stat.key < 1:
              key = "1 Day";
              break;
            case stat.key < 7:
              key = "1-7 Days";
              break;
            case stat.key < 30:
              key = "1-30 Days";
              break;
            case stat.key < 90:
              key = "1-3 Months";
              break;
            default:
              key = "3+ Months";
              break;
          }

          if (statusDurationsData[status.key][key]) {
            statusDurationsData[status.key][key] += stat.value;
          } else {
            statusDurationsData[status.key][key] = stat.value;
          }
        });
      });

      var statusDurationsData2 = Object.entries(statusDurationsData).map(
        ([key, value]) => value
      );

      components.push(
        <div className="stat-group" key="appStatusBar">
          <div className="stat-header">
            <h3>Applications by Time in Status</h3>
          </div>
          <div className="stat-body  stat-body--x2">
            <BarChart
              width={500}
              height={300}
              data={statusDurationsData2}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" type="category" />
              <YAxis type="number" allowDecimals={false} />
              <Tooltip />
              <Legend />

              <Bar dataKey="1 Day" stroke="#777" fill="#ffb1b1" />
              <Bar dataKey="1-7 Days" stroke="#777" fill="#ff9090" />
              <Bar dataKey="7-30 Days" stroke="#777" fill="#fd6d6d" />
              <Bar dataKey="1-3 Months" stroke="#777" fill="#fb4444" />
              <Bar dataKey="3+ Months" stroke="#777" fill="#ff2b2b" />
            </BarChart>
          </div>
        </div>
      );
    }

    return components;
  }

  onSliderChange = data => {
    const { fromDate, toDate, now } = this.state;

    if (fromDate.getTime() !== data[0] || toDate.getTime() !== data[1]) {
      let newFromDate = new Date(now);
      newFromDate.setMonth(newFromDate.getMonth() + data[0]);

      let newToDate = new Date(now);
      newToDate.setMonth(newToDate.getMonth() + data[1]);

      this.setState({
        fromDate: newFromDate,
        toDate: newToDate
      });

      this.handleFetchData({
        ...this.state,
        fromDate: newFromDate,
        toDate: newToDate
      });

      this.updateCurrentUrl({
        ...this.state,
        fromDate: newFromDate,
        toDate: newToDate
      });
    }
  };

  renderSlider() {
    const { fromDate, toDate, now } = this.state;

    let oneYrAgo = -12;

    const nowTime = 0;

    const dateTicks = new Array(12).forEach((itm, idx) => idx - 12);

    const formatTicks = ms => {
      var date = new Date(now);
      date.setMonth(date.getMonth() + ms);

      var month = date.getMonth();
      var year = date.getFullYear();
      if (month === 0) {
        return year.toString();
      } else {
        return Months[month];
      }
    };

    let from =
      fromDate.getFullYear() * 12 +
      fromDate.getMonth() -
      (now.getFullYear() * 12 + now.getMonth());
    let to =
      toDate.getFullYear() * 12 +
      toDate.getMonth() -
      (now.getFullYear() * 12 + now.getMonth());

    return (
      <Slider
        mode={3}
        step={1}
        domain={[oneYrAgo, nowTime]}
        rootStyle={{
          position: "relative",
          width: "100%",
          height: "39px"
        }}
        onChange={this.onSliderChange}
        values={[from, to]}
      >
        <Rail>
          {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div>
              {handles.map(handle => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  domain={[oneYrAgo, nowTime]}
                  getHandleProps={getHandleProps}
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks right={false} left={false}>
          {({ tracks, getTrackProps }) => (
            <div>
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                />
              ))}
            </div>
          )}
        </Tracks>
        <Ticks values={dateTicks}>
          {({ ticks }) => (
            <div>
              {ticks.map(tick => (
                <Tick
                  key={tick.id}
                  tick={tick}
                  count={ticks.length}
                  format={formatTicks}
                />
              ))}
            </div>
          )}
        </Ticks>
      </Slider>
    );
  }

  render() {
    const {
      companyId,
      formatId,
      countyId,
      statusId,
      fromDate,
      toDate
    } = this.state;
    const {
      companies,
      formats,
      counties,
      statuses,
      apiKey,
      data,
      isLoading
    } = this.props;
    const {
      handleFilterChange,
      getVolumeMetrics,
      getDurationMetrics,
      renderSlider
    } = this;
    return (
      <div className="site-id-page page dashboard">
        <div className="floating-panel">
          <div className="floating-header">
            <div className="header-title">
              <div className={`icon icon-speedo`}></div>
              <div>
                <h2>Retailer Reporting</h2>
                <p>
                  Review and analyse data about how the My Orderly portal is
                  being utilised by customers and which functionality they are
                  using.
                </p>
              </div>
            </div>
            <div className="vertical-controls">
              <div>
                <div className="horizontal-controls">
                  <div>From: {fromDate.toLocaleDateString()}</div>
                  <div>To: {toDate.toLocaleDateString()}</div>
                </div>
              </div>
              <div>{renderSlider()}</div>
              {/*<select
                onChange={e => handleQuestionTopic(e)}
                value={questionTopic}
              >
                <option value="">Ask a question...</option>
                <option value="modules">
                  What functionality are users accessing?
                </option>
                <option value="users">
                  How frequently do users access the portal?
                </option>
                <option value="feedback">
                  What feedback are users submitting?
                </option>
                <option value="documents">
                  How are users interacting with documents?
                  </option>
              </select>*/}
            </div>
          </div>
        </div>
        {apiKey && (
          <HeatMapPanel
            apiKey={apiKey}
            data={data.heatmap}
            isLoading={isLoading}
          />
        )}

        <FilterPanel
          companies={companies}
          statuses={statuses}
          counties={counties}
          formats={formats}
          companyId={companyId}
          formatId={formatId}
          countyId={countyId}
          statusId={statusId}
          doFilter={handleFilterChange}
        />

        <div className="stat-container">
          {data && data.formats && getVolumeMetrics()}
          {data && data.formats && getDurationMetrics()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isLoading: state.siteIDReporting.isLoading,
  isLoaded: state.siteIDReporting.isLoaded,

  companies: state.siteIDReporting.referenceData.companies,
  formats: state.siteIDReporting.referenceData.formats,
  counties: state.siteIDReporting.referenceData.counties,
  statuses: state.siteIDReporting.referenceData.statuses,
  apiKey: state.siteIDReporting.referenceData.apiKey,

  data: state.siteIDReporting.reportData
});

const mapDispatchToProps = dispatch => {
  return {
    getReferenceData: () => dispatch(siteIDReportingActions.getReferenceData()),
    getData: (fromDate, toDate, companyId, formatId, countyId, statusId) =>
      dispatch(
        siteIDReportingActions.getData(
          fromDate,
          toDate,
          companyId,
          formatId,
          countyId,
          statusId
        )
      ),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteIDReporting);
