export default {
          RetailerInterest: 1,
        CommercialDiscussions: 2,
        PendingNDA: 3,
        NDASigned: 4,
        PendingContract: 5, 
        ContractSigned: 6, 
        DueDiligenceRequired: 7,
        DueDiligenceCompleted: 8, 
        FinanceChecksRequired: 9,
        FinanceChecksCompleted: 10,
        CatalogueRequired: 11,
        CatalogueConfigured: 12,
        PricingQuoteRequired: 13,
        PricingQuoteCompleted: 14,
        AccessGrantedToRetailer: 15,
        Rejected: 16,
        Expired: 17,
        EngagementEnded: 18
};